import React from 'react';
import PropTypes from 'prop-types';
import styles from './learn-more.scss';
import { withUrlsQualified } from '../hocs';

function setLearnMoreClass(learnMoreColor) {
    const classes = [
        learnMoreColor || 'dark-blue',
        styles.learnMore,
        'has-hover-state',
        'u-hide-in-print',
    ];

    return classes.join('  ');
}

function LearnMore(props) {
    const {
        text,
        target,
        url,
        color,
        background_color,
    } = props;

    if (!url || !text) {
        return null;
    }

    let containerClasses = 'u-fix-inline-whitespace';

    if (background_color) {
        containerClasses += '  ' + background_color;
    }

    return (
        <p className={containerClasses}>
            <a
                className={setLearnMoreClass(color)}
                href={url}
                {...(target ? { target } : {})}
            >
                <span className={`${styles.learnMoreIcon}  icon  icon-arrow-button-right`} />
                <span className={`${styles.learnMoreText}  type-small-caps  u-letter-spacing-1  u-uppercase`}>{text}</span>
            </a>
        </p>
    );
}

LearnMore.propTypes = {
    text: PropTypes.string,
    target: PropTypes.oneOf([
        '',
        '_self',
        '_blank',
        '_parent',
        '_top',
    ]),
    url: PropTypes.string,
    color: PropTypes.string,
    background_color: PropTypes.string,
};

LearnMore.defaultProps = {
    text: '',
    target: '',
    url: '',
    color: '',
    background_color: '',
};

export default withUrlsQualified(LearnMore, ['url']);
