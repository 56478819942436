import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import Form from './Form.jsx';
import Input from './Input.jsx';
import RawMarkup from '../RawMarkup.jsx';
import { valueHasData } from '../../../utils/data';
import ConditionalRender from '../ConditionalRender';
import styles from './gated-contact-form.scss';

class GatedContentForm extends React.Component {
    render() {
        const {
            errors,
            onSubmit,
            recaptchaRef,
            recaptchaKey,
            success,
            successMessage,
        } = this.props;

        const hasSuccess = valueHasData(success);

        if (hasSuccess) {
            return (
                <div className="rte u-padding-bottom-med">
                    <RawMarkup>
                        {successMessage}
                    </RawMarkup>
                </div>
            );
        }

        return (

            <React.Fragment>
                <Form
                    onSubmit={(submitEvt) => {
                        submitEvt.preventDefault();
                        const recaptchaValue = recaptchaRef.current.getValue();
                        onSubmit(recaptchaValue);
                    }}
                    id="gated_content_form">
                    <div className="u-margin-bottom-med">
                        <Input
                            name="first_name"
                            placeholder="First Name"
                            hasErrors={Boolean(_.get(errors, 'first_name'))}
                            additionalClasses={['type-form-input']}
                        />
                        <ConditionalRender condition={Boolean(_.get(errors, 'first_name'))}>
                            <div className="u-padding-top-5">
                                <div className={`${styles.errorMessage}`}>{_.get(errors, 'first_name')}</div>
                            </div>
                        </ConditionalRender>
                    </div>
                    <div className="u-margin-bottom-med">
                        <Input
                            name="last_name"
                            placeholder="Last Name"
                            hasErrors={Boolean(_.get(errors, 'last_name'))}
                            additionalClasses={['type-form-input']}
                        />
                        <ConditionalRender condition={Boolean(_.get(errors, 'last_name'))}>
                            <div className="u-padding-top-5">
                                <div className={`${styles.errorMessage}`}>{_.get(errors, 'last_name')}</div>
                            </div>
                        </ConditionalRender>
                    </div>
                    <div className="u-margin-bottom-med">
                        <Input
                            name="email"
                            placeholder="Email"
                            hasErrors={Boolean(_.get(errors, 'email'))}
                            additionalClasses={['type-form-input']}
                        />
                        <ConditionalRender condition={Boolean(_.get(errors, 'email'))}>
                            <div className="u-padding-top-5">
                                <div className={`${styles.errorMessage}`}>{_.get(errors, 'email')}</div>
                            </div>
                        </ConditionalRender>
                    </div>
                    <div className="u-margin-bottom-med">
                        <Input
                            name="company"
                            placeholder="Company"
                            hasErrors={Boolean(_.get(errors, 'company'))}
                            additionalClasses={['type-form-input']}
                        />
                        <ConditionalRender condition={Boolean(_.get(errors, 'company'))}>
                            <div className="u-padding-top-5">
                                <div className={`${styles.errorMessage}`}>{_.get(errors, 'company')}</div>
                            </div>
                        </ConditionalRender>
                    </div>
                    <div className="u-margin-bottom-med">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={recaptchaKey}
                        />
                        <ConditionalRender condition={Boolean(_.get(errors, 'recaptcha'))}>
                            <div className="u-padding-top-5">
                                <div className={`${styles.errorMessage}`}>{_.get(errors, 'recaptcha')}</div>
                            </div>
                        </ConditionalRender>
                    </div>
                    <div className="u-margin-bottom-med">
                        <button
                            className={`${styles.gatedFormButton} white u-hide-in-print`}>
                                Submit
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

GatedContentForm.propTypes = {
    successMessage: PropTypes.string,
    recaptchaKey: PropTypes.string,
    recaptchaRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    success: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onSubmit: PropTypes.func.isRequired,
};

GatedContentForm.defaultProps = {
    successMessage: 'Thank you!',
    recaptchaKey: '',
    success: {},
    errors: {},
};

export default GatedContentForm;
