import React from 'react';
import PropTypes from 'prop-types';
import styles from './breadcrumbs.scss';
import MaybeLink from '../MaybeLink.jsx';

function getLinkClassNameForLink({ isActive, url }) {
    const classes = [
        styles.breadcrumb,
        'type-breadcrumbs',
        'u-uppercase',
    ];
    if (isActive) {
        classes.push(styles.active);
    }
    if (url) {
        classes.push(styles.breadcrumbLink);
    }

    return classes.join(' ');
}

function Breadcrumbs({ links }) {
    if (!links.length) {
        return null;
    }

    return (
        <ol className="bg-white u-padding-top-med u-padding-bottom-med">
            {links.map((link, idx) => (
                <li className={getLinkClassNameForLink(link)} key={idx}>
                    <MaybeLink href={link.url}>{link.name}</MaybeLink>
                </li>
            ))}
        </ol>
    );
}

Breadcrumbs.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        isActive: PropTypes.bool,
    })),
};

Breadcrumbs.defaultProps = {
    links: [],
};

export default Breadcrumbs;
