import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';
import styles from './footer.scss';

function getIconClassForLink({ iconClass }) {
    const classes = [
        styles.socialIcon,
        'icon',
    ];

    if (iconClass) {
        classes.push(iconClass);
    }

    return classes.join(' ');
}

function SocialLink({ link }) {
    return (
        <WindowContext.Consumer>
            {window => (
                <li className={styles.socialLink}>
                    <a href={qualifyUrlInWindow(link.link, window)} aria-label={link.text}>
                        <div className={getIconClassForLink(link)} />
                    </a>
                </li>
            )}
        </WindowContext.Consumer>
    );
}

SocialLink.propTypes = {
    link: PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,

    }),
};

SocialLink.defaultProps = {
    link: {},
};

function SocialLinks({ links }) {
    if (!links.length) {
        return null;
    }

    return (
        <ul className={[styles.social, 'light-gray', 'u-hide-in-print'].join(' ')}>
            {links.map((link, idx) => (
                <SocialLink link={link} key={idx} />
            ))}
        </ul>
    );
}

SocialLinks.propTypes = {
    links: PropTypes.arrayOf(SocialLink.propTypes.link),
};

SocialLinks.defaultProps = {
    links: [],
};

export default SocialLinks;
