import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import CallToAction from "../../../elements/CallToAction";

import styles from "./styles.scss";

function Content(props) {
  //prettier-ignore
  const {
    backgroundGradientUrl,
    content,
    eyebrow,
    headingPartOne,
    headingPartTwo,
    cta,
  } = props;

  return (
    <div
      className={classNames(styles.contentContainer, "white")}
      style={{ backgroundImage: `url(${backgroundGradientUrl})` }}
    >
      <p className={classNames("m-type__eyebrow", styles.eyebrow)}>{eyebrow}</p>
      <h2>
        <span className={styles['m-type__popupDisplay']}>{headingPartOne}</span>
        <span className={styles['m-type__popupDisplay-bold']}>{headingPartTwo}</span>
      </h2>
      <p className="m-type__body-bold">{content}</p>
      <CallToAction
        className={styles.cta}
        icon="chevron-filled-right"
        label={cta.text}
        ariaLabel={cta.text}
        url={cta.url}
      />
    </div>
  );
}

Content.propTypes = {
  backgroundGradientUrl: PropTypes.string,
  content: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  eyebrow: PropTypes.string,
  headingPartOne: PropTypes.string,
  headingPartTwo: PropTypes.string,
};

export default Content;
