import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';
import styles from './footer.scss';

function getContainerClassName() {
    const classes = [
        styles.nav,
        'type-footer-links',
        'dark-blue',
        'u-letter-spacing-1',
        'u-uppercase',
        'u-hide-in-print',
    ];

    return classes.join(' ');
}

function PrimaryLinks({ links }) {
    if (links.length === 0) {
        return null;
    }

    return (
        <div className="u-text-center">
            <ul className={getContainerClassName()}>
                {links.map((link, idx) => (
                    <WindowContext.Consumer key={idx}>
                        {window => (
                            <li className={styles.navItem}>
                                <a href={qualifyUrlInWindow(link.link, window)}>{link.text}</a>
                            </li>
                        )}
                    </WindowContext.Consumer>
                ))}
            </ul>
        </div>
    );
}

PrimaryLinks.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
    })),
};

PrimaryLinks.defaultProps = {
    links: [],
};

export default PrimaryLinks;
