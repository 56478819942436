import {
    LAYOUT_MOBILE,
    LAYOUT_TABLET,
    LAYOUT_SMALL_DESKTOP,
    LAYOUT_MEDIUM_DESKTOP,
    LAYOUT_LARGE_DESKTOP,
} from './constants';
import {
    ACTION_HAS_ENTERED_LAYOUT_MOBILE,
    ACTION_HAS_ENTERED_LAYOUT_TABLET,
    ACTION_HAS_ENTERED_LAYOUT_SMALL_DESKTOP,
    ACTION_HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP,
    ACTION_HAS_ENTERED_LAYOUT_LARGE_DESKTOP,
} from './globalActions';
import { getLayoutForWindowWidth } from './utils/document';

function layout(state = LAYOUT_MEDIUM_DESKTOP, action) {
    switch (action.type) {
        case ACTION_HAS_ENTERED_LAYOUT_MOBILE:
            return LAYOUT_MOBILE;
        case ACTION_HAS_ENTERED_LAYOUT_TABLET:
            return LAYOUT_TABLET;
        case ACTION_HAS_ENTERED_LAYOUT_SMALL_DESKTOP:
            return LAYOUT_SMALL_DESKTOP;
        case ACTION_HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP:
            return LAYOUT_MEDIUM_DESKTOP;
        case ACTION_HAS_ENTERED_LAYOUT_LARGE_DESKTOP:
            return LAYOUT_LARGE_DESKTOP;
        default:
            return state;
    }
}

function getInitialGlobalState(window) {
    return {
        layout: LAYOUT_SMALL_DESKTOP,
    };
}

export {
    layout,
    getInitialGlobalState,
};
