import React from 'react';
import PropTypes from 'prop-types';
import styles from './forms.scss';
import Button from './Button.jsx';

function getAllExtraClasses(extraClasses) {
    extraClasses.push(styles.fullWidthButton);

    return extraClasses;
}

function FullWidthButton({ extraClasses, ...additionalProps }) {
    return (
        <Button extraClasses={getAllExtraClasses(extraClasses)} {...additionalProps} />
    );
}

FullWidthButton.propTypes = {
    color: PropTypes.string,
    href: PropTypes.string,
    text: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
};

FullWidthButton.defaultProps = {
    color: '',
    href: '',
    text: '',
    extraClasses: [],
};

export default FullWidthButton;
