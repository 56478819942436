import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';

function getShareUrl_fromLinkFormat_andWindow(urlFormat, window) {
    const currentUrl = encodeURIComponent(window.location.href);

    const url = urlFormat.replace(/%s/, currentUrl);

    return qualifyUrlInWindow(url, window);
}

function getShareOptionData_byKeyword(keyword) {
    const shareOptionMap = {
        facebook: {
            name: 'Facebook',
            iconClass: 'icon-facebook',
            target: '_blank',
        },
        linkedin: {
            name: 'LinkedIn',
            iconClass: 'icon-linkedin',
            target: '_blank',
        },
        twitter: {
            name: 'Twitter',
            iconClass: 'icon-twitter2',
            target: '_blank',
        },
        email: {
            name: 'Email',
            iconClass: 'icon-mail-hollow',
            target: '_self',
        },
    };

    return shareOptionMap[keyword];
}

function ShareOption({ keyword, linkFormat }) {
    const shareOptionData = getShareOptionData_byKeyword(keyword);
    if (!shareOptionData) {
        return null;
    }

    return (
        <WindowContext.Consumer>
            {window => (
                <a
                    href={getShareUrl_fromLinkFormat_andWindow(linkFormat, window)}
                    target={shareOptionData.target}
                    aria-label={shareOptionData.name}>
                    <span className={shareOptionData.iconClass} />
                </a>
            )}
        </WindowContext.Consumer>
    );
}

ShareOption.propTypes = {
    keyword: PropTypes.string.isRequired,
    linkFormat: PropTypes.string,
};

ShareOption.defaultProps = {
    linkFormat: '',
};

export default ShareOption;
