import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { catchPendingFormUpdate } from '@rubyapps-mvc/redux-search/actions';
import UnconnectedAutocomplete from '../Forms/CommonAutocomplete.jsx';

const mapStateToProps = (state, ownProps) => ({
    value: state.search.formState[ownProps.name],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange(ev, { newValue }) {
        dispatch(catchPendingFormUpdate(ownProps.name, newValue));
    },
});

const ConnectedAutocomplete = connect(mapStateToProps, mapDispatchToProps)((props) => {
    const wantedProps = _.pick(props, Object.keys(UnconnectedAutocomplete.propTypes));

    return (
        <UnconnectedAutocomplete {...wantedProps} />
    );
});

ConnectedAutocomplete.propTypes = UnconnectedAutocomplete.propTypes;

ConnectedAutocomplete.defaultProps = UnconnectedAutocomplete.defaultProps;

export default ConnectedAutocomplete;
