import _ from 'lodash';
import { combineReducers } from 'redux';
import {
    OPEN_TAKEOVER,
    CLOSE_TAKEOVER,
    statsTakeover_reduxKey,
} from './constants';

function isOpen(state = true, action) {
    switch (action.type) {
        case OPEN_TAKEOVER:
            return true;
        case CLOSE_TAKEOVER:
            return false;
        default:
            return state;
    }
}

function contentHeight(state = 0, action) {
    const newState = _.get(action, 'payload');
    return _.isNil(newState) ? state : newState;
}

export const reducer = combineReducers({
    contentHeight,
    isOpen,
});

export function getInitialState(props) {
    return {
        contentHeight: 0,
        isOpen: false,
    };
}

export function getReduxKey() {
    return statsTakeover_reduxKey;
}
