import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ConditionalRender from '../ConditionalRender.jsx';
import TakeoverWrapper from './TakeoverWrapper.jsx';
import Logo from './Logo.jsx';
import NavPage from './NavPage.jsx';
import NavAutocomplete from './NavAutocomplete';
import { openNavTakeover, closeNavTakeover } from './actions';
import takeoverStyles from './nav-takeover.scss';
import mainStyles from './styles/main.scss';
import { PARAM_QUERY } from '../../../constants';
import { removeDuplicateBlogPostContent } from '../../../utils/search';

const mapStateToProps = state => ({
    searchIsOpen: _.get(state, 'nav.navOpenStates.searchIsOpen'),
    menuIsOpen: _.get(state, 'nav.navOpenStates.menuIsOpen'),
});

const mapDispatchToProps = dispatch => ({
    onCloseButtonClick(ev) {
        ev.preventDefault();
        dispatch(closeNavTakeover());
    },
    triggerSearchNavState(ev) {
        ev.preventDefault();
        dispatch(openNavTakeover({
            menuIsOpen: false,
            searchIsOpen: true,
        }));
    },
});

class Takeover extends React.Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.navAutocompleteRef = React.createRef();
    }

    handleSearchClick(ev) {
        ev.preventDefault();

        const {
            searchIsOpen,
        } = this.props;

        if (searchIsOpen) {
            this.formRef.current.submit();
        } else {
            this.props.triggerSearchNavState(ev);

            setTimeout(() => {
                const autocompleteInput = _.get(this, [
                    'navAutocompleteRef',
                    'current',
                    'autocompleteRef',
                    'current',
                    'autosuggestRef',
                    'current',
                    'input',
                ]);
                if (autocompleteInput) {
                    autocompleteInput.focus();
                }
            }, 500);
        }
    }

    handleAutocompleteSuggestionSelected(ev, { suggestion }) {
        ev.preventDefault();

        const { window } = this.props;
        window.location.href = suggestion.url;
    }

    handleEnterKeyPress(ev) {
        ev.preventDefault();
        this.formRef.current.submit();
    }

    render() {
        const {
            menuIsOpen,
            searchIsOpen,
            rootPages,
            onCloseButtonClick,
            searchPageUrl,
        } = this.props;

        const buttonsClassName = [
            mainStyles.navButtons,
            mainStyles.inTakeover,
        ].join(' ');

        return (
            <TakeoverWrapper isOpen={menuIsOpen || searchIsOpen}>
                <Logo variant="white" isInTakeover />
                <div className={buttonsClassName}>
                    <button
                        className={mainStyles.search}
                        aria-label="Search"
                        onClick={ev => this.handleSearchClick(ev)}>
                        <span className={`${mainStyles.searchIcon} icon-search`} />
                    </button>
                    <button
                        aria-label="Close overlay"
                        className={mainStyles.close}
                        onClick={ev => onCloseButtonClick(ev)}>
                        <span className={`${mainStyles.closeIcon} icon-close`} />
                    </button>
                </div>
                <ConditionalRender condition={searchIsOpen}>
                    <div className={takeoverStyles.formBorders}>
                        <div className="l-standard-width">
                            <form
                                ref={this.formRef}
                                action={searchPageUrl}
                                className={takeoverStyles.form}>

                                <NavAutocomplete
                                    placeholder="Search"
                                    id="nav-site-search"
                                    name={PARAM_QUERY}
                                    ref={this.navAutocompleteRef}
                                    inputExtraClasses={[takeoverStyles.input]}
                                    view="site_autocomplete"
                                    onSuggestionSelected={(ev, { suggestion }) => this.handleAutocompleteSuggestionSelected(ev, { suggestion })}
                                    suggestionsPreFilter={suggestions => removeDuplicateBlogPostContent(suggestions)}
                                    onKeypress={ev => this.handleEnterKeyPress(ev)}
                                />
                            </form>
                        </div>
                    </div>
                </ConditionalRender>
                <ConditionalRender condition={menuIsOpen}>
                    <div className={`${takeoverStyles.formBorders} ${takeoverStyles.form}`} />
                    <NavPage childPages={rootPages} />
                </ConditionalRender>
            </TakeoverWrapper>
        );
    }
}

Takeover.propTypes = {
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onCloseButtonClick: PropTypes.func.isRequired,
    triggerSearchNavState: PropTypes.func.isRequired,
    menuIsOpen: PropTypes.bool,
    searchIsOpen: PropTypes.bool,
    rootPages: NavPage.propTypes.childPages, // eslint-disable-line react/no-typos
    searchPageUrl: PropTypes.string,
};

Takeover.defaultProps = {
    menuIsOpen: false,
    searchIsOpen: false,
    rootPages: [],
    searchPageUrl: '',
};

const ConnectedTakeover = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Takeover);
ConnectedTakeover.propTypes = _.omit(Takeover.propTypes, [
    'onCloseButtonClick',
    'triggerSearchNavState',
]);
ConnectedTakeover.defaultProps = Takeover.defaultProps;

export default ConnectedTakeover;
