import React from 'react';
import PropTypes from 'prop-types';
import Resource from './Resource.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function Resources({
    title,
    tag,
    resources,
    extraClasses,
    renderMode,
}) {
    if (!resources.length) {
        return null;
    }

    const layoutClass = resources.length < 3 ? 'l-1m-1t-2d-column-item' : 'l-1m-2t-3d-column-grid-item';

    const Tag = tag;

    return (
        <React.Fragment>
            <ConditionalRender condition={title}>
                <Tag className={extraClasses.join(' ')}>
                    {title}
                </Tag>
            </ConditionalRender>
            <div className={`l-3-columns-grid ${renderMode === PDF_RENDER_MODE ? 'u-margin-top-xsmall u-padding-top-small' : ''}`}>
                {resources.map((resourceProps, idx) => (
                    <Resource {...resourceProps} key={idx} idx={idx} layoutClass={layoutClass} renderMode={renderMode} />
                ))}
            </div>
        </React.Fragment>
    );
}

Resources.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.shape(Resource.propTypes)),
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    renderMode: PropTypes.string,
};

Resources.defaultProps = {
    title: '',
    tag: 'h3',
    resources: [],
    extraClasses: [],
    renderMode: '',
};

export default Resources;
