import {
    NAV_OPEN_TAKEOVER,
    NAV_CLOSE_TAKEOVER,
} from './constants';

export function openNavTakeover({
    menuIsOpen,
    searchIsOpen,
}) {
    return {
        type: NAV_OPEN_TAKEOVER,
        payload: {
            menuIsOpen,
            searchIsOpen,
        },
    };
}

export function closeNavTakeover() {
    return {
        type: NAV_CLOSE_TAKEOVER,
        payload: {
            menuIsOpen: false,
            searchIsOpen: false,
        },
    };
}
