import moment from 'moment';
import { CHAR_CODE_EN_DASH } from '../constants';

export function getFormattedUtcDate(date, dateFormat = 'MM.DD.YY') {
    if (!date) {
        return null;
    }

    const formattedDate = moment(date).utc().format(dateFormat);

    return formattedDate;
}

export function getEventDuration(startDate, endDate) {
    return moment.duration(endDate.diff(startDate));
}

export function getRawEventDuration(rawStartDate, rawEndDate) {
    if (!rawEndDate) {
        return rawStartDate;
    }
    const eventDuration = getEventDuration(moment(rawStartDate), moment(rawEndDate));

    return eventDuration.toISOString();
}

export function getFormattedDaysDuration({
    rawStartDate,
    rawEndDate,
    startTimeZone,
    endTimeZone,
    dateFormat = 'MM.DD.YY',
}) {
    const startDateString = rawStartDate ?
        (startTimeZone ? moment(rawStartDate).format(dateFormat) : moment(rawStartDate).utc().format(dateFormat))
        : '';
    const endDateString = rawEndDate ?
        (endTimeZone ? moment(rawEndDate).format(dateFormat) : moment(rawEndDate).utc().format(dateFormat))
        : '';

    if (!endDateString) {
        return startDateString;
    }

    if (!startDateString) {
        return '';
    }

    const displayDatesDiffer = startDateString !== endDateString;
    if (!displayDatesDiffer) {
        return startDateString;
    }

    return [
        startDateString,
        String.fromCharCode(CHAR_CODE_EN_DASH),
        endDateString,
    ].join(' ');
}

export function getFormattedTimeDuration({
    rawStartTime,
    rawEndTime,
    startTimeZone,
    endTimeZone,
    timeFormat = 'h:mmA',
}) {
    const startTimeString = startTimeZone ? moment(rawStartTime).format(timeFormat) : '';
    const endTimeString = endTimeZone ? moment(rawEndTime).format(timeFormat) : '';

    if (!rawEndTime || !endTimeString) {
        return startTimeString;
    }

    if (!rawStartTime || !startTimeString) {
        return [
            String.fromCharCode(CHAR_CODE_EN_DASH),
            endTimeString,
        ].join(' ');
    }

    return [
        startTimeString,
        String.fromCharCode(CHAR_CODE_EN_DASH),
        endTimeString,
    ].join(' ');
}

export function momentDateFromMaybeStringDate(maybeStringDate) {
    if (maybeStringDate) {
        return moment(maybeStringDate);
    }
    return null;
}

export function stringDateFromMaybeMomentDate(maybeMomentDate, format = 'YYYY-MM-DD') {
    if (maybeMomentDate instanceof moment) {
        return maybeMomentDate.format(format);
    }
    return '';
}
