import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../../elements/ConditionalRender.jsx';
import MaybeLink from '../../elements/MaybeLink.jsx';
import Asset from '../../elements/Asset/index.jsx';
import styles from './standard_interior.scss';

function Highlights(props) {
    const { title, highlights } = props;

    if (!highlights.length) {
        return null;
    }

    return (
        <React.Fragment>
            <ConditionalRender condition={title}>
                <h3 className="type-results-header u-margin-bottom-small dark-gray">
                    {title}
                </h3>
            </ConditionalRender>
            <ul className={`l-2-columns ${styles.highlightsContainer}`}>
                {highlights.map((highlightItem, idx) => (
                    <li className={`l-1m-1t-2d-column-item ${styles.highlightItem}`} key={idx}>
                        <MaybeLink href={highlightItem.url} fallbackTag="div">
                            <div className={styles.highlightAsset}>
                                <Asset asset={highlightItem.asset} />
                            </div>
                        </MaybeLink>
                        <ConditionalRender condition={highlightItem.caption}>
                            <p className="u-margin-top-small">
                                {highlightItem.caption}
                            </p>
                        </ConditionalRender>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}

Highlights.propTypes = {
    title: PropTypes.string,
    highlights: PropTypes.arrayOf(PropTypes.shape({
        asset: Asset.propTypes.asset,
        url: PropTypes.string,
        caption: PropTypes.string,
    })),
};

Highlights.defaultProps = {
    title: '',
    highlights: [],
};

export default Highlights;
