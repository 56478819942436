import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../../hocs';
import RawMarkup from '../RawMarkup.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function ListItem({
    name,
    url,
    status,
    renderMode,
}) {
    if (!name || !url) {
        return null;
    }

    const linkColorClasses = renderMode === PDF_RENDER_MODE ? 'light-blue' : 'dark-blue light-blue-hover';

    return (
        <li className="type-paragraph u-margin-top-xsmall u-page-break-inside-avoid">
            {status === 'active'
                ? <a className={linkColorClasses} href={url}><RawMarkup>{name}</RawMarkup></a>
                : <RawMarkup>{name}</RawMarkup>
            }
        </li>
    );
}

ListItem.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string,
    renderMode: PropTypes.string,
};

ListItem.defaultProps = {
    name: '',
    url: '',
    renderMode: '',
};

export default withUrlsQualified(ListItem, ['url']);
