import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './styles.scss';
import DelayRender from '../../DelayRender.jsx';
import Spinner from './Spinner.jsx';

const mapStateToProps = state => ({
    isLoading: state.search.isLoading,
});

function ResultsArea({ children, isLoading }) {
    return (
        <div className={styles.resultsArea}>
            {isLoading ? (
                <DelayRender wait={500}>
                    <div className={styles.shim}>
                        <Spinner extraClasses={[styles.spinner]} />
                    </div>
                </DelayRender>
            ) : null}
            {children}
        </div>
    );
}

ResultsArea.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

ResultsArea.defaultProps = {
    children: null,
    isLoading: false,
};

export default connect(mapStateToProps)(ResultsArea);
