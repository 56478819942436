import PropTypes from 'prop-types';

function ConditionalRender({ condition, children }) {
    if (!condition) {
        return null;
    }

    return children;
}

ConditionalRender.propTypes = {
    condition: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
};

ConditionalRender.defaultProps = {
    condition: false,
    children: null,
};

export default ConditionalRender;
