import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

export const colorMap = ['dark-blue', 'yellow', 'teal', 'purple', 'light-blue', 'gray'];
export const blockGradientColors = ['purple2', 'green', 'deep-ocean'];
export const resourceColorMap = ['teal', 'purple', 'light-blue', 'yellow', 'gray'];
export const formElementColors = ['Green', 'Purple', 'Blue', ''];

export const KEY_ENTER = 13;
export const KEY_ESCAPE = 27;

export const QUANTITY_ALL_RESULTS = 9999;

export const ACTION_NOTHING = '';

export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_SMALL_DESKTOP = 1024;
export const BREAKPOINT_MEDIUM_DESKTOP = 1280;
export const BREAKPOINT_LARGE_DESKTOP = 1440;

export const LAYOUT_MOBILE = 'mobile';
export const LAYOUT_TABLET = 'tablet';
export const LAYOUT_SMALL_DESKTOP = 'small desktop';
export const LAYOUT_MEDIUM_DESKTOP = 'medium desktop';
export const LAYOUT_LARGE_DESKTOP = 'large desktop';
export const LAYOUT_UNKNOWN = 'unknown';

export const CHAR_CODE_EN_DASH = '8211';
export const CHAR_CODE_EM_DASH = '8212';
export const CHAR_CODE_SINGLE_OPENING_CURLY_QUOTE = 8216;
export const CHAR_CODE_SINGLE_CLOSING_CURLY_QUOTE = 8217;

export const TEMPLATE_ATTORNEY = 'attorney';
export const TEMPLATE_PRACTICE_LANDING = 'practice_landing';
export const TEMPLATE_ATTORNEY_SEARCH = 'attorney_search';
export const TEMPLATE_MATTER_SEARCH = 'matter_search';
export const TEMPLATE_NEWS_SEARCH = 'news_search';
export const TEMPLATE_SITE_SEARCH = 'search_results';
export const TEMPLATE_HOMEPAGE = 'homepage';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_DEFAULT = '';

export const COLOR_BLACK = '#000';
export const COLOR_VERY_LIGHT_GRAY = '#f5f5f5';
export const COLOR_LIGHT_GRAY = '#ddd';
export const COLOR_GRAY = '#97999b';
export const COLOR_DARK_GRAY = '#555';
export const COLOR_GREEN_NORMAL = '#44ab07';
export const COLOR_LIGHT_BLUE = '#0091da';
export const COLOR_WHITE = '#fff';

export const AUTOCOMPLETE_ENDPOINT = '/_site/search';

export const PARAM_QUERY = 'q';
export const PARAM_SCHOOL = 'sc';
export const PARAM_LAST_NAME_LETTER = 'l';

export const DATE_FORMAT_FULL_MONTH_YEAR = 'MMMM DD, YYYY';

export const DATEPICKER_COMMON_PROP_TYPES = {
    name: PropTypes.string,
    value: PropTypes.instanceOf(moment),
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
};
export const DATEPICKER_COMMON_DEFAULT_PROPS = {
    name: '',
    value: null,
    placeholder: '',
    handleChange: _.noop,
};

export const PDF_RENDER_MODE = 'pdf';

export const COOKIE_SESSION_DURATION = 30;
