import React from 'react';
import PropTypes from 'prop-types';
import StatefulAccordion from '../StatefulAccordion.jsx';
import RawMarkup from '../RawMarkup.jsx';
import ViewMore from '../ViewMore.jsx';
import ConditionalRender from '../ConditionalRender.jsx';

function AccordionModule({ title, content, view_more }) {
    if (!title || !content) {
        return null;
    }

    return (
        <StatefulAccordion
            title={title}
        >
            <div className="rte">
                <RawMarkup>{content}</RawMarkup>
            </div>
            <ConditionalRender condition={content && view_more}>
                <ViewMore
                    openText="View More"
                    closeText="View Less"
                    additionalButtonClassNames={['u-margin-top-small']}
                >
                    <div className="rte">
                        <RawMarkup>{view_more}</RawMarkup>
                    </div>
                </ViewMore>
            </ConditionalRender>
        </StatefulAccordion>
    );
}

AccordionModule.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    view_more: PropTypes.string,
};

AccordionModule.defaultProps = {
    title: '',
    content: '',
    view_more: '',
};
export default AccordionModule;
