import React from 'react';
import PropTypes from 'prop-types';
import styles from './forms.scss';
import QualifiedLink from '../QualifiedLink.jsx';

function setButtonClass(color, extraClasses) {
    const classes = [
        styles.formButton,
        styles['formColor' + color],
        'type-callout-subtitle',
        'white',
    ];

    if (extraClasses) {
        classes.push(...extraClasses);
    }

    return classes.join('  ');
}

function Button(props) {
    const {
        href,
        text,
        color,
        extraClasses,
        nativeProps,
    } = props;

    if (href) {
        return (<QualifiedLink {...nativeProps} href={href} className={setButtonClass(color, extraClasses)}>{text}</QualifiedLink>);
    }

    return (<button type="submit" className={setButtonClass(color, extraClasses)}>{text}</button>);
}

Button.propTypes = {
    color: PropTypes.string,
    href: PropTypes.string,
    text: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    nativeProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Button.defaultProps = {
    color: '',
    href: '',
    text: '',
    extraClasses: [],
    nativeProps: {},
};

export default Button;
