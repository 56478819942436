import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Autocomplete from '../Forms/Autocomplete';
import styles from './nav-takeover.scss';

class NavAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.autocompleteRef = React.createRef();
    }

    render() {
        const {
            placeholder,
            id,
            name,
            view,
            suggestionExtraClasses,
            onSuggestionSelected,
            suggestionsPreFilter,
        } = this.props;

        const inputClasses = [
            styles.input,
        ];

        const theme = _.pick(styles, [
            'container',
            'suggestionsContainer',
            'suggestionsContainerOpen',
            'suggestion',
        ]);

        return (
            <div className={styles.inputContainer}>
                <Autocomplete
                    ref={this.autocompleteRef}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    view={view}
                    theme={theme}
                    inputClasses={inputClasses}
                    suggestionExtraClasses={suggestionExtraClasses}
                    suggestionsPreFilter={suggestionsPreFilter}
                    onSuggestionSelected={onSuggestionSelected}
                />
            </div>
        );
    }
}

NavAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    suggestionExtraClasses: PropTypes.arrayOf(PropTypes.string),
    view: PropTypes.string.isRequired,
    onSuggestionSelected: PropTypes.func,
    suggestionsPreFilter: PropTypes.func,
};

NavAutocomplete.defaultProps = {
    placeholder: '',
    id: '',
    suggestionExtraClasses: [],
    onSuggestionSelected: _.noop,
    suggestionsPreFilter: null,
};

export default NavAutocomplete;
