import { combineReducers } from 'redux';
import {
    NAV_OPEN_TAKEOVER,
    NAV_CLOSE_TAKEOVER,
} from './constants';

function navOpenStates(state = { menuIsOpen: false, searchIsOpen: false }, action) {
    switch (action.type) {
        case NAV_OPEN_TAKEOVER:
            return {
                ...state,
                menuIsOpen: action.payload.menuIsOpen,
                searchIsOpen: action.payload.searchIsOpen,
            };
        case NAV_CLOSE_TAKEOVER:
            return {
                ...state,
                menuIsOpen: false,
                searchIsOpen: false,
            };
        default:
            return state;
    }
}

export default combineReducers({
    navOpenStates,
});
