import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

function Statistics({ stats, extraContainerClass }) {
    return (
        <ul className={`${styles.container} ${extraContainerClass} bg-dark-blue`}>
            {stats.map((stat, idx) => (
                <li className={styles.stat} key={idx}>
                    <div className={`${styles.number}  light-blue`}>{stat.stat}</div>
                    <div className={`${styles.text}  white`}>{stat.text}</div>
                </li>
            ))}
        </ul>
    );
}

Statistics.propTypes = {
    stats: PropTypes.arrayOf(PropTypes.shape({
        stat: PropTypes.string,
        text: PropTypes.string,
    })),
    extraContainerClass: PropTypes.bool,
};

Statistics.defaultProps = {
    stats: [],
    extraContainerClass: '',
};

export default Statistics;
