import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from './ConditionalRender';
import CareersGrid from './CareersGrid';
import LearnMore from './LearnMore';
import styles from '../templates/career_landing/career-landing.scss';

function ProfessionalsGrid(props) {
    const {
        gridHeader,
        gridContent,
        learnMoreLink,
        extraContainerClass,
        hasSidebar,
    } = props;

    return (
        <div className={extraContainerClass}>
            <ConditionalRender condition={gridHeader}>
                <div className="u-margin-top-large">
                    <h3 className={`${styles.gridHeader}  dark-blue  u-text-center`}>{gridHeader}</h3>
                </div>
            </ConditionalRender>

            <ConditionalRender condition={gridContent}>
                <div className="u-margin-top-med">
                    <CareersGrid gridItems={gridContent} hasSidebar={hasSidebar} />
                </div>
            </ConditionalRender>

            <ConditionalRender condition={learnMoreLink}>
                <div className="u-margin-top-med">
                    <LearnMore
                        text="Learn More"
                        url={learnMoreLink}
                        color="light-blue"
                    />
                </div>
            </ConditionalRender>
        </div>
    );
}

ProfessionalsGrid.propTypes = {
    gridHeader: PropTypes.string,
    extraContainerClass: PropTypes.string,
    learnMoreLink: PropTypes.string,
    gridContent: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

ProfessionalsGrid.defaultProps = {
    extraContainerClass: '',
};

export default ProfessionalsGrid;
