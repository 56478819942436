import React from 'react';
import PropTypes from 'prop-types';
import styles from './news.scss';
import RawMarkup from '../../elements/RawMarkup.jsx';
import ConditionalRender from '../../elements/ConditionalRender.jsx';
import { valueHasData } from '../../../utils/data';
import EventDuration from './EventDuration.jsx';
import FullWidthButton from '../../elements/Forms/FullWidthButton.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function EventInformation({
    isEvent,
    locationName_html,
    additionalLocationLines,
    datetimeInformation,
    registrationLink,
    registrationText,
    renderMode,
}) {
    const {
        rawStartTime,
        displayDate,
    } = datetimeInformation;

    const hasLocation = locationName_html || valueHasData(additionalLocationLines);
    const hasDate = rawStartTime || displayDate;
    const hasRegistration = valueHasData(registrationLink);

    const blockCount = [hasLocation, hasDate, hasRegistration].filter(item => item).length;
    const layoutClass = blockCount < 3 ? 'l-1m-1t-2d-column-item' : 'l-1m-2t-3d-column-grid-item';

    const columnClasses = (renderMode === PDF_RENDER_MODE ? [] : [
        styles.eventInfo,
        layoutClass,
        'white',
        'bg-dark-blue',
    ]).join(' ');

    const locationNameClasses = (renderMode === PDF_RENDER_MODE ? [
        'rte',
        'type-large-paragraph',
        'type-large-paragraph--bold',
        'dark-blue',
    ] : [
        'rte',
        'type-professional-search-result',
        'u-line-height-medium',
    ]).join(' ');

    return (
        <div className="l-3-columns">
            <ConditionalRender condition={hasLocation}>
                <div className={`${columnClasses} ${styles.location}`}>
                    <span className={`${styles.locationIcon} icon icon-map-pin u-hide-in-print`}>
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                    </span>
                    {/* This wrapping div is required so that flexbox does not reposition the lines of content. */}
                    <div>
                        <div className={locationNameClasses}>
                            <RawMarkup>{locationName_html}</RawMarkup>
                        </div>
                        <ConditionalRender condition={valueHasData(additionalLocationLines)}>
                            {additionalLocationLines.map((addressLine, idx) => (
                                <div className="type-small-paragraph" key={idx}>
                                    {addressLine}
                                </div>
                            ))}
                        </ConditionalRender>
                    </div>
                </div>
            </ConditionalRender>
            <ConditionalRender condition={hasDate}>
                <div className={`${columnClasses} ${styles.date} ${renderMode === PDF_RENDER_MODE && 'u-pdf-heading-underline'}`}>
                    <span className={`${styles.dateIcon} icon-clock u-hide-in-print`}>
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                    </span>
                    <EventDuration
                        {...datetimeInformation}
                        renderMode={renderMode}
                    />
                </div>
            </ConditionalRender>
            <ConditionalRender condition={hasRegistration}>
                <div className={`${columnClasses} ${styles.register} u-hide-in-print`}>
                    <span className={`${styles.penIcon} icon-pen`}>
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                    </span>
                    <FullWidthButton
                        color="LightBlue"
                        href={registrationLink}
                        text={registrationText || 'Register'}
                    />
                </div>
            </ConditionalRender>
        </div>
    );
}

EventInformation.propTypes = {
    isEvent: PropTypes.bool,
    locationName_html: PropTypes.string,
    additionalLocationLines: PropTypes.arrayOf(PropTypes.string),
    datetimeInformation: PropTypes.shape(EventDuration.propTypes),
    registrationText: PropTypes.string,
    registrationLink: PropTypes.string,
    renderMode: PropTypes.string,
};

EventInformation.defaultProps = {
    isEvent: false,
    locationName_html: '',
    additionalLocationLines: [],
    datetimeInformation: {},
    registrationText: 'Register',
    registrationLink: '',
    renderMode: '',
};


export default EventInformation;
