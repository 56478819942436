import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import WidgetDatePicker from './WidgetDatePicker';
import NativeDatePicker from './NativeDatePicker';
import { momentDateFromMaybeStringDate } from '../../../utils/date';
import { isiOS } from '../../../utils/document';

class DateRange extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const nextState = {};

        if (props.startValue !== state.startValue) {
            nextState.startValue = momentDateFromMaybeStringDate(props.startValue);
        }
        if (props.endValue !== state.endValue) {
            nextState.endValue = momentDateFromMaybeStringDate(props.endValue);
        }

        return nextState;
    }

    constructor(props) {
        super(props);

        this.state = {
            startValue: momentDateFromMaybeStringDate(this.props.startValue),
            endValue: momentDateFromMaybeStringDate(this.props.endValue),
            useWidgetDatePicker: false,
        };
    }

    componentDidMount() {
        if (!isiOS()) {
            this.setState({ useWidgetDatePicker: true });
        }
    }

    handleStartValueChange(date) {
        this.setState({ startValue: date });
        if (typeof this.props.onStartValueChange === 'function') {
            this.props.onStartValueChange(date);
        }
    }

    handleEndValueChange(date) {
        this.setState({ endValue: date });
        if (typeof this.props.onEndValueChange === 'function') {
            this.props.onEndValueChange(date);
        }
    }

    render() {
        const DatePickerComponent = this.state.useWidgetDatePicker ? WidgetDatePicker : NativeDatePicker;

        return (
            <React.Fragment>
                <DatePickerComponent
                    placeholder={this.props.startPlaceholder}
                    name={this.props.startName}
                    value={this.state.startValue}
                    selectsStart
                    startDate={this.state.startValue}
                    endDate={this.state.endValue}
                    handleChange={(date) => { this.handleStartValueChange(date); }} />
                <DatePickerComponent
                    placeholder={this.props.endPlaceholder}
                    name={this.props.endName}
                    value={this.state.endValue}
                    selectsEnd
                    startDate={this.state.startValue}
                    endDate={this.state.endValue}
                    handleChange={(date) => { this.handleEndValueChange(date); }} />
            </React.Fragment>
        );
    }
}

DateRange.propTypes = {
    startPlaceholder: PropTypes.string,
    endPlaceholder: PropTypes.string,
    startName: PropTypes.string,
    endName: PropTypes.string,
    startValue: PropTypes.string,
    endValue: PropTypes.string,
    onStartValueChange: PropTypes.func,
    onEndValueChange: PropTypes.func,
};

DateRange.defaultProps = {
    startPlaceholder: 'Start Date',
    endPlaceholder: 'End Date',
    startName: '',
    endName: '',
    startValue: '',
    endValue: '',
    onStartValueChange: _.noop,
    onEndValueChange: _.noop,
};

export default DateRange;
