import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VelocityComponent } from 'velocity-react';
import { KEY_ENTER } from '../../constants';
import styles from './view-more.scss';

class ViewMore extends React.Component {
    constructor(props) {
        super(props);

        this.animatorRef = React.createRef();
        this.state = {
            isOpen: this.props.beginOpen,
        };
    }

    getButtonClassNames() {
        const baseClassNames = [
            styles.toggle,
            'type-small-caps',
            'light-blue',
            'u-uppercase',
            'u-line-height-medium',
            'u-letter-spacing-1',
            'has-hover-state',
        ];

        const buttonClassNames = baseClassNames.concat(this.props.additionalButtonClassNames);

        return buttonClassNames.join(' ');
    }

    toggle(ev) {
        ev.preventDefault();
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    // Remove the height inline style because it can interfere
    // with animations after a resize or when an animation is
    // ended prematurely.
    clearHeightStyle() {
        this.animatorRef.current.style.height = null;
    }

    handleKeyDown(ev) {
        if (ev.keyCode === KEY_ENTER) {
            this.toggle(ev);
        }
    }

    render() {
        const {
            openText,
            closeText,
            beginOpen,
            children,
        } = this.props;

        if (!openText) {
            return null;
        }

        return (
            <React.Fragment>
                <VelocityComponent
                    animation={this.state.isOpen ? 'slideDown' : 'slideUp'}
                    complete={() => this.clearHeightStyle()}
                >
                    <div
                        className={classNames('js-view-more', styles.viewMoreContentWrapper)}
                        ref={this.animatorRef}
                        style={{ display: beginOpen ? 'block' : 'none' }}
                    >
                        <div className="u-padding-top-med">
                            {children}
                        </div>
                    </div>
                </VelocityComponent>
                <button
                    tabIndex="0"
                    onKeyDown={ev => this.handleKeyDown(ev)}
                    onClick={ev => this.toggle(ev)}
                    className={this.getButtonClassNames()}
                >
                    <span>{this.state.isOpen ? closeText : openText}</span>
                </button>
            </React.Fragment>
        );
    }
}

ViewMore.propTypes = {
    openText: PropTypes.string,
    closeText: PropTypes.string,
    beginOpen: PropTypes.bool,
    additionalButtonClassNames: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
};

ViewMore.defaultProps = {
    openText: 'View More',
    closeText: 'View Less',
    beginOpen: false,
    additionalButtonClassNames: [],
    children: null,
};

export default ViewMore;
