import React from 'react';
import PropTypes from 'prop-types';
import GridItem from './GridItem.jsx';

function getGridColorProps(index) {
    const gridProps = ['bg-light-blue', 'bg-teal', 'bg-purple'];

    return gridProps[index % 3];
}

function CareersGrid({
    gridItems, hasSidebar,
}) {
    return (
        <div className="l-3-columns-grid">
            {gridItems.map((gridProps, idx) => (
                <GridItem
                    {...gridProps}
                    hasSidebar={hasSidebar}
                    key={idx}
                    idx={idx}
                    gridColor={getGridColorProps(idx)}
                />
            ))}
        </div>
    );
}

CareersGrid.propTypes = {
    gridItems: PropTypes.arrayOf(PropTypes.shape(GridItem.propTypes)),
};

CareersGrid.defaultProps = {
    gridItems: [],
};

export default CareersGrid;
