import React from 'react';
import PropTypes from 'prop-types';
import Listing from './Listing.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import QualifiedLink from '../QualifiedLink.jsx';
import styles from './listings.scss';
import { PDF_RENDER_MODE } from '../../../constants';

function getTitleClasses_fromExtraClasses(extraClasses, renderMode) {
    const defaultClasses = [
    ];

    if (renderMode !== PDF_RENDER_MODE) {
        defaultClasses.push(...[
            'u-margin-bottom-med',
            'type-results-header',
            'dark-gray',
        ]);
    } else {
        defaultClasses.push(...[
            'type-large-paragraph',
            'type-large-paragraph--bold',
            'dark-blue',
            'u-pdf-heading-underline',
        ]);
    }

    return defaultClasses.concat(extraClasses);
}

function Listings({
    title,
    tag,
    listings,
    titleExtraClasses,
    viewAllText,
    url,
    renderMode,
}) {
    if (listings.length === 0) {
        return null;
    }

    const Tag = tag;

    const listingWrapperClasses = renderMode === PDF_RENDER_MODE ? 'u-padding-top-small l-3-columns-grid' : 'l-3-columns-grid';

    return (
        <React.Fragment>
            <ConditionalRender condition={title}>
                <Tag className={getTitleClasses_fromExtraClasses(titleExtraClasses, renderMode).join(' ')}>
                    {title}
                </Tag>
            </ConditionalRender>
            <div className={listingWrapperClasses}>
                {listings.map((listingProps, idx) => (
                    <Listing
                        {...listingProps}
                        key={idx}
                        idx={idx}
                        renderMode={renderMode}
                        layoutClass="l-1m-2t-3d-column-grid-item"
                    />
                ))}
            </div>
            <ConditionalRender condition={url}>
                <p className={`${styles.viewAll} type-small-caps  u-letter-spacing-1  light-blue  has-hover-state  u-uppercase  u-hide-in-print`}>
                    <QualifiedLink href={url}>
                        {viewAllText}
                    </QualifiedLink>
                </p>
            </ConditionalRender>
        </React.Fragment>
    );
}

Listings.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    listings: PropTypes.arrayOf(PropTypes.shape(Listing.propTypes)),
    titleExtraClasses: PropTypes.arrayOf(PropTypes.string),
    viewAllText: PropTypes.string,
    url: PropTypes.string,
    renderMode: PropTypes.string,
};

Listings.defaultProps = {
    title: '',
    tag: 'h3',
    listings: [],
    titleExtraClasses: [],
    viewAllText: 'View All Insights',
    url: '',
    renderMode: '',
};

export default Listings;
