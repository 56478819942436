import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from './ConditionalRender.jsx';
import QualifiedLink from './QualifiedLink.jsx';
import styles from './sidebar-links.scss';

function SidebarLinks({
    title,
    links,
    border,
    firstItemBorder,
}) {
    if (links.length === 0) {
        return null;
    }

    const sidebarBorderColor = border ? border + 'Border' : 'grayBorder';
    const firstItemBorderClass = firstItemBorder ? 'has-first-item-border' : '';

    return (
        <React.Fragment>
            <ConditionalRender condition={title}>
                <h4 className="type-h4 dark-blue u-margin-bottom-small">{title}</h4>
            </ConditionalRender>
            <ul>
                {links.map((link, idx) => (
                    <li className={`${styles.sidebarLink} dark-gray ${styles[sidebarBorderColor]} ${styles[firstItemBorderClass]}`} key={idx}>
                        <QualifiedLink className="light-blue-hover" href={link.url}>
                            {link.title}
                        </QualifiedLink>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}

SidebarLinks.propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
    })),
    border: PropTypes.string,
    firstItemBorder: PropTypes.number,
};

SidebarLinks.defaultProps = {
    title: '',
    links: [],
    border: '',
    firstItemBorder: 0,
};

export default SidebarLinks;
