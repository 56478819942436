import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import RawMarkup from '../RawMarkup.jsx';
import { KEY_ESCAPE } from '../../../constants';

import styles from './search-relevance-notes.scss';

// Simplified port of inflection.humanize
// https://github.com/dreamerslab/node.inflection/blob/master/lib/inflection.js#L827
function formatFieldLabel(field) {
    field = field.toLowerCase();
    field = field.replace(/(_ids|_id)$/g, '');
    field = field.replace(/_/g, ' ');

    field = field.substring(0, 1).toUpperCase() + field.substring(1);

    return field;
}

function highlightFieldLabelFromFieldKey(key) {
    const referencedMatches = key.match(/^content_data.in--([\w-]+)--of-([\w]+).name$/);
    if (referencedMatches) {
        const field = formatFieldLabel(referencedMatches[1]);
        const template = formatFieldLabel(referencedMatches[2]);
        return `Referenced in ${field} field of ${template} pages`;
    }

    const referencingMatches = key.match(/^content_data.([\w-]+).name$/);
    if (referencingMatches) {
        return formatFieldLabel(referencingMatches[1]);
    }

    return formatFieldLabel(key);
}

function highlightInfoFromHighlight(highlight, key) {
    const highlightSnippets = highlight[key];

    return highlightSnippets.map((snippet, index) => (
        <p key={index}>
            <RawMarkup>{snippet}</RawMarkup>
        </p>
    ));
}

class SearchNotes extends React.Component {
    constructor(props) {
        super(props);

        this.searchInfoRef = React.createRef();
        this.closeSearchInfoOnBodyClick = this.closeSearchInfoOnBodyClick.bind(this);
        this.closeSearchInfoOnEscapeKey = this.closeSearchInfoOnEscapeKey.bind(this);
    }

    componentDidMount() {
        if (!window) {
            return;
        }

        const { document } = window;

        document.body.addEventListener('click', this.closeSearchInfoOnBodyClick);
        window.addEventListener('keydown', this.closeSearchInfoOnEscapeKey);
    }

    componentWillUnmount() {
        if (!window) {
            return;
        }

        const { document } = window;

        document.body.removeEventListener('click', this.closeSearchInfoOnBodyClick);
        window.removeEventListener('keydown', this.closeSearchInfoOnEscapeKey);
    }

    closeSearchInfoOnBodyClick(ev) {
        const searchInfoElement = this.searchInfoRef.current;
        if (ev.target !== searchInfoElement && !searchInfoElement.contains(ev.target)) {
            this.props.closeSearchInfo(ev);
        }
    }

    closeSearchInfoOnEscapeKey(ev) {
        if (ev.keyCode === KEY_ESCAPE) {
            this.props.closeSearchInfo(ev);
        }
    }

    render() {
        const {
            name,
            score,
            highlight,
        } = this.props;

        return (
            <div
                ref={this.searchInfoRef}
                className={styles.highlight}
            >
                <dl>
                    <dt><strong>{name}</strong></dt>
                    <dd>{score}</dd>
                    <button
                        tabIndex="0"
                        className={styles.closeSearchInfo}
                        onKeyDown={ev => this.props.handleKeyDown(ev)}
                        onClick={ev => this.props.closeSearchInfo(ev)}
                        aria-label="Close Search Relevance Information"
                    />
                </dl>
                {Object.keys(highlight).map((fieldKey, fieldKeyIndex) => (
                    <dl key={fieldKeyIndex}>
                        <dt>
                            <strong>{highlightFieldLabelFromFieldKey(fieldKey)}</strong>
                        </dt>
                        <dd>
                            {highlightInfoFromHighlight(highlight, fieldKey)}
                        </dd>
                    </dl>
                ))}
            </div>
        );
    }
}


SearchNotes.propTypes = {
    name: PropTypes.string,
    score: PropTypes.number,
    highlight: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    handleKeyDown: PropTypes.func,
    closeSearchInfo: PropTypes.func,
};

SearchNotes.defaultProps = {
    name: '',
    score: null,
    highlight: {},
    handleKeyDown: _.noop,
    closeSearchInfo: _.noop,
};

export default SearchNotes;
