import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Asset from './Asset/index.jsx';
import ConditionalRender from './ConditionalRender.jsx';
import SearchRelevanceNotes from './SearchRelevanceNotes/index.jsx';
import { withUrlsQualified } from '../hocs';
import styles from './person-result.scss';

function PersonResult({
    asset,
    name,
    position,
    offices,
    phone,
    email,
    vCardUrl,
    url,
    extraClasses,
    displaySearchHighlights,
    id,
}) {
    if (!name) {
        return null;
    }

    const containerClasses = [
        ...extraClasses.filter(extraClass => extraClass),
        styles.container,
        'clearfix',
        'u-page-break-inside-avoid',
    ];

    const containerClassName = containerClasses.join(' ');
    const officesWithNames = _.filter(offices, office => office.name);
    const hasOffices = Array.isArray(officesWithNames) && officesWithNames.length > 0;

    return (
        <div className={containerClassName}>
            <div className={styles.asset}>
                <a href={url}>
                    <Asset asset={asset} />
                </a>
            </div>
            <div className={styles.nameAndPosition}>
                <p>
                    <a href={url} className={`${styles.name} dark-blue light-blue-hover`}>{name}</a>
                </p>
                <ConditionalRender condition={displaySearchHighlights}>
                    <SearchRelevanceNotes id={id} />
                </ConditionalRender>
                <p className="type-search-result  gray">{position}</p>
            </div>
            <div className={styles.contactInfo}>
                <ConditionalRender condition={hasOffices}>
                    <p className={`${styles.contactInfoItem} clearfix`}>
                        <span className={`${styles.resultIcon}  icon-map-pin-hollow  light-blue  font-size-44`} />
                        <span className={styles.resultContent}>
                            {officesWithNames.map((office, idx) => (
                                <span key={idx}>
                                    <a href={office.url} className="type-search-result gray light-blue-hover">{office.name}</a>
                                    <br />
                                </span>
                            ))}
                        </span>
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={phone}>
                    <p className={`${styles.contactInfoItem} clearfix`}>
                        <span className={`${styles.resultIcon}  icon-phone  light-blue  font-size-44`}>
                            <span className="path1  light-blue" />
                            <span className="path2  white" />
                        </span>
                        <span className={styles.resultContent}>
                            <a href={`tel:${phone}`} className="type-search-result  gray  light-blue-hover">{phone}</a>
                        </span>
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={email}>
                    <p className={`${styles.contactInfoItem} clearfix`}>
                        <span className={`${styles.resultIcon}  icon-mail-hollow  light-blue  font-size-44`} />
                        <span className={styles.resultContent}>
                            <a href={`mailto:${email}`} className="type-search-result  gray  light-blue-hover">Email</a>
                        </span>
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={vCardUrl}>
                    <p className={`${styles.contactInfoItem} clearfix`}>
                        <span className={`${styles.resultIcon}  icon-vcard-button  light-blue  font-size-44`} />
                        <span className={styles.resultContent}>
                            <a href={vCardUrl} className="type-search-result  gray  light-blue-hover">vCard</a>
                        </span>
                    </p>
                </ConditionalRender>
            </div>
        </div>
    );
}

PersonResult.propTypes = {
    asset: Asset.propTypes.asset,
    name: PropTypes.string,
    position: PropTypes.string,
    offices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
    })),
    phone: PropTypes.string,
    email: PropTypes.string,
    url: PropTypes.string,
    vCardUrl: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    displaySearchHighlights: PropTypes.bool,
    id: PropTypes.string,
};

PersonResult.defaultProps = {
    asset: {},
    name: '',
    position: '',
    offices: [],
    phone: '',
    email: '',
    url: '',
    vCardUrl: '',
    extraClasses: [],
    displaySearchHighlights: false,
    id: null,
};

export default withUrlsQualified(PersonResult, ['url']);

