import classNames from 'classnames';
import cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import ConditionalRender from './ConditionalRender.jsx';
import RawMarkup from './RawMarkup';

import styles from './headermessage.scss';

class HeaderMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideBanner: cookie.get('hideBanner'),
        };
    }

    componentDidMount() {
        if (this.props.headerMessage) {
            cookie.set('hideBanner', true, { expires: 30 });
        }
    }

    onHeaderButtonClick(ev) {
        cookie.set('hideBanner', true, { expires: 30 });

        this.setState({ hideBanner: true });
    }

    render() {
        const {
            headerMessage,
        } = this.props;

        const hideBanner = this.state.hideBanner;

        return (
            <ConditionalRender condition={headerMessage}>
                <VelocityTransitionGroup enter={{ animation: 'fadeIn', delay: 1500 }} leave={{ animation: 'fadeOut' }} runOnMount>
                    { !hideBanner ?
                        <div className={classNames(styles.headerMessageContainer, 'bg-dark-blue')} onClick={(ev) => { this.onHeaderButtonClick(ev); }} onKeyPress={this.onHeaderButtonClick} role="button" tabIndex={0}>
                            <p className={classNames(styles.headerMessage, 'white')}>
                                <RawMarkup>
                                    {headerMessage}
                                </RawMarkup>
                            </p>

                            <div className={classNames(styles.headerCloseIcon, 'icon', 'icon-close', 'white')} />
                        </div> : null }
                </VelocityTransitionGroup>
            </ConditionalRender>
        );
    }
}


HeaderMessage.propTypes = {
    headerMessage: PropTypes.string,
};

HeaderMessage.defaultProps = {
    headerMessage: '',
};

export default HeaderMessage;
