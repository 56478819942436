import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { catchFormUpdates } from '@rubyapps-mvc/redux-search/actions';
import PlainDropdown from '../Forms/PlainDropdown.jsx';
import withFilteredDropdownOptions from './FilteredDropdownOptions.jsx';

function getDropdownValueFromOptions(value, options) {
    if (!value || !(typeof value === 'string' || typeof value === 'number')) {
        return options[0];
    }

    const matches = options.filter(option => option.value.toString() === value.toString());
    return matches[0] || options[0];
}

const mapStateToProps = (state, ownProps) => ({
    value: state.search.formState[ownProps.name],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange(selected, actionType) {
        dispatch(catchFormUpdates({ [ownProps.name]: selected.value }));
    },
});

const Dropdown = connect(mapStateToProps, mapDispatchToProps)(({
    name,
    options,
    value,
    onChange,
    isParentChildSortedDropdown,
}) => {
    const DropdownComponent = isParentChildSortedDropdown ? withFilteredDropdownOptions(PlainDropdown) : PlainDropdown;

    return (
        <DropdownComponent
            name={name}
            options={options}
            value={getDropdownValueFromOptions(value, options)}
            onChange={onChange}
        />
    );
});

Dropdown.propTypes = {
    ...PlainDropdown.propTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isParentChildSortedDropdown: PropTypes.bool,
};

Dropdown.defaultProps = {
    ...PlainDropdown.defaultProps,
    value: '',
    isParentChildSortedDropdown: false,
};

export default Dropdown;
