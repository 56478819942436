import PropTypes from 'prop-types';

const GenericVideoAssetSpecification = {
    typeCategory: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default GenericVideoAssetSpecification;
