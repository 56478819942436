import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { qualifyUrlInWindow } from '../../utils/url';

function KnowledgeGraph({
    window,
    ...knowledgeGraphProps
}) {
    const qualifiedProps = _.assign({}, knowledgeGraphProps, {
        sameAs: knowledgeGraphProps.sameAs.map(link => qualifyUrlInWindow(link, window)),
    });

    const data = {
        ...qualifiedProps,
        '@context': 'http://schema.org',
        '@type': 'Organization',
    };
    const dataAsJSON = JSON.stringify(data);

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: dataAsJSON }} />;
}

/* eslint-disable react/no-unused-prop-types */
KnowledgeGraph.propTypes = {
    url: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    sameAs: PropTypes.arrayOf(PropTypes.string),
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

KnowledgeGraph.defaultProps = {
    url: '',
    logo: '',
    name: '',
    sameAs: [],
};

export default KnowledgeGraph;
