import { combineReducers } from 'redux';
import { augmentAccordionWithId } from './utils';
import {
    COLLAPSE_ALL,
    EXPAND_ALL,
    TOGGLE_ACCORDION,
} from './constants';

function accordionOpenStatesById(state = [], action) {
    switch (action.type) {
        case COLLAPSE_ALL:
            return Object.keys(state).reduce((acc, currId) => {
                acc[currId] = false;
                return acc;
            }, {});
        case EXPAND_ALL:
            return Object.keys(state).reduce((acc, currId) => {
                acc[currId] = true;
                return acc;
            }, {});
        case TOGGLE_ACCORDION:
            return {
                ...state,
                [action.payload.id]: !state[action.payload.id],
            };
        default:
            return state;
    }
}

export function getInitialState(props) {
    const { accordions } = props;
    const accordionsWithIds = accordions.map(augmentAccordionWithId);

    return {
        accordionOpenStatesById: accordionsWithIds.reduce((acc, currAccordion) => {
            // Attorney accordions all start closed
            acc[currAccordion.id] = false;
            return acc;
        }, {}),
    };
}

export const reducer = combineReducers({
    accordionOpenStatesById,
});
