import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../../hocs';
import { VIDEO } from './constants';

function VideoSource({
    typeCategory,
    type,
    url,
    extraProps,
}) {
    if (typeCategory !== VIDEO) {
        return null;
    }

    const typeAttr = type ? `video/${type}` : '';

    return (
        <source
            {...extraProps}
            type={typeAttr}
            src={url} />
    );
}

VideoSource.propTypes = {
    typeCategory: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

VideoSource.defaultProps = {
    typeCategory: '',
    type: '',
    url: '',
    extraProps: {},
};

export default withUrlsQualified(VideoSource, ['url']);
