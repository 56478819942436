import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Img from './Img.jsx';
import Picture from './Picture.jsx';
import Audio from './Audio.jsx';
import Video from './Video.jsx';
import VideoIframe from './VideoIframe.jsx';
import Plyr from './Plyr.jsx';
import GenericAssetSpecification from './GenericAssetSpecification';
import {
    IMAGE,
    AUDIO,
    VIDEO,
    TYPE_VIMEO,
    TYPE_YOUTUBE,
} from './constants';

function existsSecondaryAssets(secondaryAssets) {
    return Array.isArray(secondaryAssets) && secondaryAssets.length > 0;
}

function primarySecondaryAssetsFromOriginalAsset(asset, useQuerylessRenderAsDefaultImage) {
    const primaryAsset = _.get(asset, 'primaryAsset', null);
    const secondaryAssets = _.get(asset, 'secondaryAssets') || _.get(asset, 'secondaryAsset') || [];

    if (
        useQuerylessRenderAsDefaultImage &&
        primaryAsset &&
        primaryAsset.typeCategory === 'image'
    ) {
        const queryfulRenders = secondaryAssets.filter(secondaryAsset => secondaryAsset.media);
        const querylessRenders = secondaryAssets.filter(secondaryAsset => !secondaryAsset.media);
        if (querylessRenders.length > 0) {
            return {
                primaryAsset: querylessRenders[0],
                secondaryAssets: queryfulRenders,
            };
        }
    }

    return { primaryAsset, secondaryAssets };
}

function getAssetWithMergedExtraProps(asset, extraProps) {
    const mergedAsset = _.assign({}, asset, {
        extraProps: {
            ..._.get(asset, 'extraProps', {}),
            ...extraProps,
        },
    });

    return mergedAsset;
}

class Asset extends React.Component {
    constructor(props) {
        super(props);
        this.mediaRef = React.createRef();
    }

    render() {
        const {
            asset,
            extraProps,
            inlineWrapperClassName,
            plyrOptions,
            plyrEvents,
            plyrCustomOptions,
            useQuerylessRenderAsDefaultImage,
            useRawMedia,
            showControls,
        } = this.props;
        const { primaryAsset, secondaryAssets } =
            primarySecondaryAssetsFromOriginalAsset(asset, useQuerylessRenderAsDefaultImage);
        if (!primaryAsset) {
            return null;
        }

        const {
            typeCategory,
            type,
        } = primaryAsset;

        const primaryAssetWithMergedExtraProps = getAssetWithMergedExtraProps(primaryAsset, extraProps);

        if (typeCategory === IMAGE) {
            if (existsSecondaryAssets(secondaryAssets)) {
                const secondaryAssetsWithMergedExtraProps = secondaryAssets.map(secondaryAsset => getAssetWithMergedExtraProps(secondaryAsset, extraProps));
                return (
                    <Picture
                        primaryAsset={primaryAssetWithMergedExtraProps}
                        secondaryAssets={secondaryAssetsWithMergedExtraProps}
                        inlineWrapperClassName={inlineWrapperClassName} />
                );
            }

            const shouldWrapImage = _.get(extraProps, 'data-object-fit');
            const ObjectFitPolyfillWrapper = shouldWrapImage ? 'span' : React.Fragment;
            const objectFitPolyfillWrapperClasses = shouldWrapImage ? { className: inlineWrapperClassName } : {};

            return (
                <ObjectFitPolyfillWrapper {...objectFitPolyfillWrapperClasses}>
                    <Img {...primaryAssetWithMergedExtraProps} />
                </ObjectFitPolyfillWrapper>
            );
        }
        if (typeCategory === AUDIO || typeCategory === VIDEO) {
            if (!useRawMedia) {
                return (
                    <Plyr
                        {...plyrCustomOptions}
                        ref={this.mediaRef}
                        plyrOptions={plyrOptions}
                        plyrEvents={plyrEvents}
                        primaryAsset={primaryAssetWithMergedExtraProps}
                        secondaryAssets={secondaryAssets} />
                );
            }
            if (typeCategory === AUDIO) {
                return (
                    <Audio
                        ref={this.mediaRef}
                        primaryAsset={primaryAssetWithMergedExtraProps}
                        secondaryAssets={secondaryAssets} />
                );
            }
            if (typeCategory === VIDEO) {
                if (type === TYPE_VIMEO || type === TYPE_YOUTUBE) {
                    return (
                        <VideoIframe
                            ref={this.mediaRef}
                            primaryAsset={primaryAssetWithMergedExtraProps}
                            secondaryAssets={secondaryAssets} />
                    );
                }

                return (
                    <Video
                        ref={this.mediaRef}
                        primaryAsset={primaryAsset}
                        secondaryAssets={secondaryAssets}
                        extraProps={extraProps}
                        showControls={showControls} />
                );
            }
        }
        return null;
    }
}

Asset.propTypes = {
    asset: PropTypes.shape({
        primaryAsset: PropTypes.shape(GenericAssetSpecification),
        secondaryAsset: PropTypes.arrayOf(PropTypes.shape(GenericAssetSpecification)),
        secondaryAssets: PropTypes.arrayOf(PropTypes.shape(GenericAssetSpecification)),
    }),

    // "Extra props" are arbitrary props you can pass to the final rendered component.
    // Use with caution and consult the particular component's code for more info.
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    inlineWrapperClassName: PropTypes.string,

    // Refer to plyr docs for available options:
    // https://github.com/sampotts/plyr#options
    plyrOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    // Options specific to how we are consuming plyr
    plyrCustomOptions: PropTypes.shape({
        collectAnalytics: PropTypes.bool,
    }),

    // Callback functions to be run when certain plyr events are emitted.
    // Refer to Plyr component for supported events and their syntax.
    plyrEvents: Plyr.propTypes.plyrEvents,

    // By default, for <picture> elements, the primaryAsset is used as the <img> tag
    // and the secondaryAssets are used as the <source> tags. However, there are
    // scenarios in which the primaryAsset should be ignored and only the secondaryAssets
    // should be used for rendering. For example, we polyfill <picture> in IE, so the
    // <img> will always load initially, which is undesirable if the image represents
    // a search result and the primaryAsset is a default high res photo asset.
    useQuerylessRenderAsDefaultImage: PropTypes.bool,

    // Whether to use native <video> or <audio> instead of plyr
    useRawMedia: PropTypes.bool,

    // Whether or not to show controls for native <video> element
    showControls: PropTypes.bool,
};

Asset.defaultProps = {
    asset: null,
    extraProps: {},
    inlineWrapperClassName: '',
    plyrOptions: {},
    plyrCustomOptions: {},
    plyrEvents: {},
    useQuerylessRenderAsDefaultImage: false,
    useRawMedia: false,
    showControls: true,
};

export default Asset;
