import React from 'react';
import PropTypes from 'prop-types';
import Button from './Forms/Button.jsx';
import styles from './signup-button.scss';

function getContainerClassName(containerClasses) {
    const containerClassNames = containerClasses.concat([
        styles.signupButton,
        'u-hide-in-print',
    ]);

    return containerClassNames.join(' ');
}

function PlainSignupButton(props) {
    const {
        color,
        url,
        text,
        extraClasses,
        containerClasses,
        nativeProps,
    } = props;

    if (!text || !url) {
        return null;
    }

    return (
        <div className={getContainerClassName(containerClasses)}>
            <Button nativeProps={nativeProps} color={color} href={url} text={text} extraClasses={extraClasses} />
        </div>
    );
}

PlainSignupButton.propTypes = {
    color: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    containerClasses: PropTypes.arrayOf(PropTypes.string),
    nativeProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PlainSignupButton.defaultProps = {
    color: 'Purple',
    url: '',
    text: '',
    extraClasses: [],
    containerClasses: [],
    nativeProps: {},
};

export default PlainSignupButton;
