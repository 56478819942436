import React from 'react';
import PropTypes from 'prop-types';
import PlainSignupButton from './PlainSignupButton.jsx';

function SignupButton(props) {
    const nativeProps = {
        target: '_blank',
    };

    return (
        <PlainSignupButton
            containerClasses={[
                'l-standard-width',
                'bg-off-white',
                'u-padding-med',
                props.extraContainerClass,
            ]}
            nativeProps={nativeProps}
            {...props}
        />
    );
}

SignupButton.propTypes = {
    extraContainerClass: PropTypes.string,
};

SignupButton.defaultProps = {
    extraContainerClass: '',
};

export default SignupButton;
