import React from 'react';
import PropTypes from 'prop-types';
import RawMarkup from '../../elements/RawMarkup.jsx';
import GatedContentFormSubmission from '../../elements/Forms/GatedContentFormSubmission';
import styles from './news.scss';

function GatedContentWrapper({
    gated_content_title,
    gated_content_header,
    gated_content_main,
    gated_content_success_message,
    recaptchaKey,
    pageId,
}) {
    return (
        <React.Fragment>
            <div className={`${styles.gatedContentContainer} u-margin-bottom-med`}>
                <h3 className="dark-blue">{gated_content_title}</h3>
                <div className="rte u-padding-bottom-med">
                    <RawMarkup>
                        {gated_content_header}
                    </RawMarkup>
                </div>
                <GatedContentFormSubmission
                    pageId={pageId}
                    recaptchaKey={recaptchaKey}
                    successMessage={gated_content_success_message}
                    gatedContentMain={gated_content_main}
                />
            </div>
        </React.Fragment>
    );
}

GatedContentWrapper.propTypes = {
    gated_content_title: PropTypes.string,
    gated_content_header: PropTypes.string,
    gated_content_main: PropTypes.string,
    gated_content_success_message: PropTypes.string,
    recaptchaKey: PropTypes.string,
    pageId: PropTypes.number.isRequired,
};

GatedContentWrapper.defaultProps = {
    gated_content_title: '',
    gated_content_header: '',
    gated_content_main: '',
    gated_content_success_message: '',
    recaptchaKey: '',
};

export default GatedContentWrapper;
