import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './alphabet-lister.scss';

function lettersEqual(firstLetter = '', secondLetter = '') {
    if (!firstLetter && !secondLetter) {
        return false;
    }
    return firstLetter.toLowerCase() === secondLetter.toLowerCase();
}

function range(start, stop) {
    const result = [];

    // https://stackoverflow.com/questions/12376870/create-an-array-of-characters-from-specified-range
    for (let idx = start.charCodeAt(0), end = stop.charCodeAt(0); idx <= end; idx += 1) {
        result.push(String.fromCharCode(idx));
    }

    return result;
}

function getLetterClassName(letter, activeLetter, isDisabled) {
    const classes = [
        styles.alpha,
    ];

    if (lettersEqual(letter, activeLetter) && !isDisabled) {
        classes.push(styles.isActive);
    }

    return classes.join('  ');
}

function AlphabetLister({ activeLetter, lettersWithResults, onClick }) {
    const alphabet = range('A', 'Z');

    return (
        <div className={styles.container}>
            {alphabet.map((letter, idx) => {
                const isDisabled = !(letter.toLowerCase() in lettersWithResults);
                return (
                    <button
                        key={letter}
                        disabled={isDisabled}
                        aria-label={`Search results for ${letter}`}
                        className={getLetterClassName(letter, activeLetter, isDisabled)}
                        onClick={ev => onClick(ev, { letter, activeLetter, isDisabled })}>
                        {letter}
                    </button>
                );
            })}
        </div>
    );
}

AlphabetLister.propTypes = {
    activeLetter: PropTypes.string,
    lettersWithResults: PropTypes.objectOf(PropTypes.number),
    onClick: PropTypes.func,
};

AlphabetLister.defaultProps = {
    activeLetter: '',
    lettersWithResults: {},
    onClick: _.noop,
};

AlphabetLister.lettersEqual = lettersEqual;

export default AlphabetLister;
