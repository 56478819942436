import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Asset from '../Asset/index.jsx';
import HomepageTab from './HomepageTab.jsx';
import TabContent from './TabContent.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import styles from './homepage-tabs.scss';
import { LAYOUT_MOBILE } from '../../../constants';

const TAB_MAX = 3;

function getAllClosedTabStateArray() {
    return _.fill(Array(TAB_MAX), false);
}

const mapStateToProps = state => ({
    layout: state.layout,
});

class HomepageTabs extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.layout !== prevState.lastEnteredLayout) {
            const nextState = {
                lastEnteredLayout: nextProps.layout,
            };
            if (prevState.lastEnteredLayout === LAYOUT_MOBILE) {
                // Ensure only the first open tab is open
                const openTabsByIndex = getAllClosedTabStateArray();
                openTabsByIndex[prevState.openTabsByIndex.indexOf(true)] = true;
                nextState.openTabsByIndex = openTabsByIndex;
            }
            return nextState;
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            lastEnteredLayout: this.props.layout, // eslint-disable-line react/no-unused-state
            openTabsByIndex: [true, false, false],
        };
    }

    getDesktopActiveTabIndex() {
        const desktopActiveTabIndex = this.state.openTabsByIndex.indexOf(true);
        if (desktopActiveTabIndex === -1) {
            this.setState({ openTabsByIndex: [true, false, false] });
            return 0;
        }
        return desktopActiveTabIndex;
    }

    getTabsToRender() {
        return this.props.tab_content.slice(0, TAB_MAX);
    }

    handleTabClick(idx) {
        const { layout } = this.props;
        const { openTabsByIndex: prevOpenTabsByIndex } = this.state;
        if (layout === LAYOUT_MOBILE) {
            // In mobile, just toggle that one tab
            const openTabsByIndex = [...prevOpenTabsByIndex];
            openTabsByIndex[idx] = !prevOpenTabsByIndex[idx];
            this.setState({
                openTabsByIndex,
            });
        } else {
            // In other layouts, only show the tab that was clicked
            const openTabsByIndex = getAllClosedTabStateArray();
            openTabsByIndex[idx] = true;
            this.setState({
                openTabsByIndex,
            });
        }
    }

    renderDesktopActiveTab() {
        if (this.props.layout === LAYOUT_MOBILE) {
            return null;
        }
        const tabsToRender = this.getTabsToRender();
        const desktopActiveTabIndex = this.getDesktopActiveTabIndex();
        const desktopActiveTab = tabsToRender[desktopActiveTabIndex];

        return (
            <React.Fragment>
                <TabContent extraClassName={styles.desktopContentPadding}>
                    {desktopActiveTab.content_html}
                </TabContent>
                <ConditionalRender condition={desktopActiveTab.tab_link}>
                    <div className="u-margin-top-small">
                        <a href={desktopActiveTab.tab_link} className={`${styles.tabCta} type-callout-subtitle`}>
                            {desktopActiveTab.tab_read_more_override || 'Learn More'}
                        </a>
                    </div>
                </ConditionalRender>
            </React.Fragment>
        );
    }

    render() {
        const {
            tabContentBackground,
            title,
        } = this.props;

        const tabsToRender = this.getTabsToRender();
        if (tabsToRender.length === 0) {
            return null;
        }

        const backgroundUrl = _.get(tabContentBackground, 'url');

        const { openTabsByIndex } = this.state;

        const baseTabHeaderClasses = [
            styles.tabsHeader,
            'type-h3',
            'white',
            'u-padding-top-med',
        ];

        if (!title) {
            baseTabHeaderClasses.push(styles.tabsHeaderNoTitle);
        }

        const tabHeaderClasses = baseTabHeaderClasses.join('  ');

        return (
            <div className={`${styles.tabsOuterContainer} u-overflow-x-hidden`}>
                <div className={`${styles.tabsBackground} l-full-width-mobile`} style={{ backgroundImage: `url(${backgroundUrl})` }}>
                    <div className={styles.tabsContent}>
                        <div className={styles.tabsContentSkew}>
                            <h2 className={tabHeaderClasses}>
                                <ConditionalRender condition={title}>
                                    {title}
                                </ConditionalRender>
                            </h2>
                            <div className={styles.tabsContainer}>
                                {tabsToRender.map((tab, idx) => (
                                    <HomepageTab
                                        key={idx}
                                        index={idx}
                                        isOpen={openTabsByIndex[idx]}
                                        onClick={clickedIdx => this.handleTabClick(clickedIdx)}
                                        {...tab} />
                                ))}
                            </div>
                            {this.renderDesktopActiveTab()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HomepageTabs.propTypes = {
    layout: PropTypes.string.isRequired,
    title: PropTypes.string,
    tab_content: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content_html: PropTypes.string,
        tab_link: PropTypes.string,
    })),
    tabContentBackground: Asset.propTypes.asset,
};

HomepageTabs.defaultProps = {
    title: '',
    tab_content: [],
};

export default connect(mapStateToProps)(HomepageTabs);
