import React from 'react';
import {
    stringDateFromMaybeMomentDate,
    momentDateFromMaybeStringDate,
} from '../../../utils/date';
import {
    DATEPICKER_COMMON_PROP_TYPES,
    DATEPICKER_COMMON_DEFAULT_PROPS,
} from '../../../constants';
import './datepicker.scss';

const FORMAT = 'YYYY-MM-DD';

class NativeDatePicker extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const value = stringDateFromMaybeMomentDate(props.value, FORMAT);
        if (value !== state.value) {
            return { value };
        }
        return null;
    }

    // https://github.com/facebook/react/issues/8938#issuecomment-360573204
    static handleiOSClearButton(ev) {
        const target = ev.nativeEvent.target;
        const iOSClearDefault = () => { target.defaultValue = ''; };
        setTimeout(iOSClearDefault, 0);
    }

    constructor(props) {
        super(props);
        this.state = {
            value: stringDateFromMaybeMomentDate(props.value, FORMAT),
            isVisible: false,
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ isVisible: true });
    }

    // Make the 'Clear' button on iOS actually work when clicked
    // https://github.com/facebook/react/issues/8938
    componentDidUpdate(prevProps) {
        this.inputRef.current.defaultValue = '';
        if (!this.props.value) {
            this.inputRef.current.blur();
        }
    }

    handleChange(date) {
        if (this.state.value !== date) {
            this.setState({
                value: date,
            });
            if (typeof this.props.handleChange === 'function') {
                this.props.handleChange(momentDateFromMaybeStringDate(date));
            }
        }
    }

    render() {
        const style = { visibility: (this.state.isVisible ? '' : 'hidden') };

        return (
            <div className="native-datepicker" style={style}>
                <div className="native-datepicker__input-container">
                    <input
                        ref={this.inputRef}
                        type="date"
                        name={this.props.name}
                        value={this.state.value}
                        onInput={ev => NativeDatePicker.handleiOSClearButton(ev)}
                        onChange={ev => this.handleChange(ev.target.value)} />
                    <span className="native-datepicker__input-icon icon" />
                    {this.props.value ? null : <span className="native-datepicker__placeholder">{this.props.placeholder}</span>}
                </div>
            </div>
        );
    }
}

NativeDatePicker.propTypes = DATEPICKER_COMMON_PROP_TYPES;

NativeDatePicker.defaultProps = DATEPICKER_COMMON_DEFAULT_PROPS;

export default NativeDatePicker;
