import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import QualifiedLink from './QualifiedLink.jsx';

function MaybeLink(props) {
    const { href, fallbackTag, children } = props;
    const passthroughProps = _.omit(props, ['href', 'fallbackTag', 'children']);
    const Tag = href ? QualifiedLink : fallbackTag;
    const linkProps = href ? { href } : {};

    return (
        <Tag {...linkProps} {...passthroughProps}>
            {children}
        </Tag>
    );
}

MaybeLink.propTypes = {
    href: PropTypes.string,
    fallbackTag: PropTypes.string,
    children: PropTypes.node,
};

MaybeLink.defaultProps = {
    href: '',
    fallbackTag: 'span',
    children: null,
};

export default MaybeLink;
