/* eslint-disable react/no-unknown-property */
import React from 'react';

import PropTypes from 'prop-types';
import URI from 'urijs';
import GenericAssetSpecification from './GenericAssetSpecification';
import { asClassComponent } from '../../hocs';
import { TYPE_VIMEO, TYPE_YOUTUBE } from './constants';

function getEmbedUrlForType(url, type) {
    if (type === TYPE_VIMEO) {
        const uri = new URI(url);
        uri.search({
            loop: 'false',
            byline: 'false',
            portrait: 'false',
            title: 'false',
            speed: 'true',
            transparent: '0',
            gesture: 'media',
        });
        return uri.toString();
    }
    if (type === TYPE_YOUTUBE) {
        const uri = new URI(url);
        uri.search({
            iv_load_policy: '3',
            modestbranding: '1',
            playsinline: '1',
            showinfo: '0',
            rel: '0',
            enablejsapi: '1',
        });
        return uri.toString();
    }
    return url;
}

function PlyrIframe({ primaryAsset }) {
    const { url = '', type } = primaryAsset;

    const embedUrl = getEmbedUrlForType(url, type);

    return (
        <div className="plyr__video-embed">
            <iframe src={embedUrl} allowFullScreen />
        </div>
    );
}

PlyrIframe.propTypes = {
    primaryAsset: PropTypes.shape(GenericAssetSpecification),
};

PlyrIframe.defaultProps = {
    primaryAsset: {},
};

export default asClassComponent(PlyrIframe);
