import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';

function SitelinksSearch({
    searchUrl,
    siteUrl,
    queryKey,
    addAnalytics,
}) {
    // Only render this component if we have a search url.
    // Otherwise, it's useless.
    if (!searchUrl) {
        return null;
    }

    const query = {
        [queryKey]: '{search_term_string}',
    };
    if (addAnalytics) {
        query.utm_source = 'google';
        query.utm_medium = 'organic';
        query.utm_campaign = 'site-link-search';
    }
    const queryURI = new URI();
    queryURI.query(query);
    // Deliberately decode this query so curly braces are preserved.
    // Search engines need curly braces and will replace with the
    // appropriate value.
    const queryString = URI.decode(queryURI.toString());
    const target = searchUrl + queryString;

    const data = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: siteUrl,
        potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name="search_term_string',
            target,
        },
    };
    const dataAsJSON = JSON.stringify(data);

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: dataAsJSON }} />;
}

SitelinksSearch.propTypes = {
    searchUrl: PropTypes.string,
    siteUrl: PropTypes.string,
    queryKey: PropTypes.string,
    addAnalytics: PropTypes.bool,
};

SitelinksSearch.defaultProps = {
    searchUrl: '',
    siteUrl: '',
    queryKey: 'q',
    addAnalytics: true,
};

export default SitelinksSearch;
