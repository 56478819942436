import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Autocomplete from './Autocomplete';
import styles from './autosuggest.scss';
import formStyles from './forms.scss';

function CommonAutocomplete(props) {
    const {
        placeholder,
        id,
        name,
        value,
        view,
        inputExtraClasses,
        suggestionExtraClasses,
        useLargeVariant,
        onSuggestionSelected,
        onChange,
        suggestionsFilter,
        suggestionsPreFilter,
        variant,
    } = props;

    const wrapperClasses = [styles.autosuggest];
    if (useLargeVariant) {
        wrapperClasses.push(styles.largeVariant);
    }

    if (variant === 'homepage') {
        wrapperClasses.push(styles.homepageVariant);
    }

    const wrapperClassName = wrapperClasses.join(' ');

    const inputClasses = [
        styles.input,
        'dark-gray',
        'u-border-1',
        ...inputExtraClasses,
    ];

    const buttonClassName = [
        'icon',
        'icon-search',
        'white',
        formStyles.formButton,
        formStyles.formColorPurple,
        styles.button,
    ].join(' ');

    const theme = _.pick(styles, [
        'container',
        'suggestionsContainer',
        'suggestionsContainerOpen',
        'suggestion',
    ]);

    return (
        <div className={wrapperClassName}>
            <Autocomplete
                placeholder={placeholder}
                id={id}
                name={name}
                value={value}
                view={view}
                theme={theme}
                useLargeVariant={useLargeVariant}
                inputClasses={inputClasses}
                suggestionExtraClasses={suggestionExtraClasses}
                onSuggestionSelected={onSuggestionSelected}
                onChange={onChange}
                suggestionsFilter={suggestionsFilter}
                suggestionsPreFilter={suggestionsPreFilter}
            />

            <button type="submit" className={buttonClassName} aria-label="Submit">Submit</button>
        </div>
    );
}

CommonAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    inputExtraClasses: PropTypes.arrayOf(PropTypes.string),
    suggestionExtraClasses: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    useLargeVariant: PropTypes.bool,
    view: PropTypes.string.isRequired,
    onSuggestionSelected: PropTypes.func,
    suggestionsPreFilter: PropTypes.func,
    suggestionsFilter: PropTypes.func,
    variant: PropTypes.string,
};

CommonAutocomplete.defaultProps = {
    placeholder: '',
    id: '',
    value: '',
    inputExtraClasses: [],
    suggestionExtraClasses: [],
    useLargeVariant: true,
    onSuggestionSelected: _.noop,
    onChange: _.noop,
    suggestionsFilter: Autocomplete.defaultProps.suggestionsFilter,
    suggestionsPreFilter: null,
};

export default CommonAutocomplete;
