import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../hocs';

function QualifiedLink(props) {
    const { children, href, ...restProps } = props;
    return <a href={href} {...restProps}>{children}</a>;
}

QualifiedLink.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
};

QualifiedLink.defaultProps = {
    children: null,
    href: '',
};

export default withUrlsQualified(QualifiedLink, ['href']);
