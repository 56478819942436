import React from 'react';
import PropTypes from 'prop-types';
import { getQueryString_fromSearchState_andOptions } from '@rubyapps-mvc/redux-search/utils';
import { connect } from 'react-redux';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';

const mapStateToProps = state => ({
    currFormState: state.search.formState,
    currSearchParams: state.search.searchParams,
    resultsPerPage: state.search.searchParams.s,
});

function MoreResultsLink({
    currFormState,
    currSearchParams,
    wantsFormState,
    wantsSearchParams,
    ignores,
    resultsPerPage,
    linkProps,
    overridePathname,
    children,
}) {
    const formState = { ...currFormState, ...wantsFormState };
    const searchParams = { ...currSearchParams, ...wantsSearchParams };
    const searchState = { formState, searchParams };

    return (
        <WindowContext.Consumer>
            {(window) => {
                const queryString = getQueryString_fromSearchState_andOptions(searchState, {
                    resultsPerPage,
                    ignores,
                    windowOverride: window,
                });
                const pathname = overridePathname || window.location.pathname;
                const href = qualifyUrlInWindow(`${pathname}${queryString}`, window);

                return (
                    <a {...linkProps} href={href}>{children}</a>
                );
            }}
        </WindowContext.Consumer>
    );
}

const searchStateSpec = PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]));

MoreResultsLink.propTypes = {
    currFormState: searchStateSpec,
    currSearchParams: searchStateSpec,
    wantsFormState: searchStateSpec,
    wantsSearchParams: searchStateSpec,
    ignores: PropTypes.objectOf(PropTypes.string),
    resultsPerPage: PropTypes.number,
    linkProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    overridePathname: PropTypes.string,
    children: PropTypes.node,
};

MoreResultsLink.defaultProps = {
    currFormState: {},
    currSearchParams: {},
    wantsFormState: {},
    wantsSearchParams: {},
    ignores: {},
    resultsPerPage: 10,
    linkProps: {},
    overridePathname: '',
    children: null,
};

export default connect(mapStateToProps)(MoreResultsLink);
