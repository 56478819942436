import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

export const COLOR_NAME_BLACK = "black";
export const COLOR_NAME_PURPLE = "purple";
export const COLOR_NAME_WHITE = "white";

export const BUTTON_STYLE_THIN = "thin";
export const BUTTON_STYLE_DEFAULT = "default";

function CallToAction(props) {
  const {
    borderColor = COLOR_NAME_WHITE,
    buttonStyle = BUTTON_STYLE_DEFAULT,
    className,
    icon,
    iconBackgroundColor = COLOR_NAME_PURPLE,
    label,
    textColor = COLOR_NAME_BLACK,
    url,
  } = props;

  const Component = url ? Link : Button;
  return (
    <Component
      {...props}
      className={classNames(
        styles.ctaContainer,
        styles[`border__${borderColor}`],
        buttonStyle === BUTTON_STYLE_THIN && styles.thin,
        !icon && styles.noIcon,
        className,
      )}
    >
      <span className={classNames(styles.type__cta, styles[`text__${textColor}`])}>{label}</span>
      {icon && (
        <span
          className={classNames(
            styles.icon,
            styles[`iconBackgroundColor__${iconBackgroundColor}`],
          )}
        >
          <i className={classNames("icon", `icon-${icon}`)} />
        </span>
      )}
    </Component>
  );
}

CallToAction.propTypes = {
  borderColor: PropTypes.oneOf([COLOR_NAME_PURPLE, COLOR_NAME_WHITE]),
  buttonStyle: PropTypes.oneOf([BUTTON_STYLE_THIN, BUTTON_STYLE_DEFAULT]),
  icon: PropTypes.string,
  iconBackgroundColor: PropTypes.oneOf([COLOR_NAME_PURPLE, COLOR_NAME_WHITE]),
  onClick: PropTypes.func,
  textColor: PropTypes.oneOf([COLOR_NAME_BLACK, COLOR_NAME_WHITE]),
  label: PropTypes.string,
  url: PropTypes.string,
};

function Button(props) {
  //prettier-ignore
  const {
    ariaLabel,
    className,
    children,
    disabled,
    onClick = _.noop
  } = props;

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      disabled={Boolean(disabled)}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

function Link(props) {
  //prettier-ignore
  const {
    ariaLabel,
    children,
    className,
    onClick,
    target,
    url
  } = props;

  return (
    <a
      className={className}
      href={url}
      aria-label={ariaLabel}
      target={target}
      {...(_.isFunction(onClick) ? { onClick } : {})}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  url: PropTypes.string,
};

export default CallToAction;
