import React from 'react';
import PropTypes from 'prop-types';
import styles from './plain-header.scss';
import RawMarkup from '../RawMarkup.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function getHeaderClassName(extraClasses, renderMode) {
    const classes = [
        styles.header,
        'l-standard-width',
        'u-remove-pdf-padding',
        renderMode === PDF_RENDER_MODE ? styles.pdfHeader : '',
        ...extraClasses,
    ];

    return classes.join(' ');
}

function getTitleClassNames(renderMode) {
    const classes = [
        styles.title,
        'type-h1',
        ...(renderMode === PDF_RENDER_MODE ?
            ['type-h1--bold', 'light-blue'] :
            ['white']
        ),
    ];

    return classes.join(' ');
}

function PlainHeader({ header_classes, title_html, renderMode }) {
    return (
        <div className={getHeaderClassName(header_classes, renderMode)}>
            <h1 className={getTitleClassNames(renderMode)}>
                <span className="rte">
                    <RawMarkup>
                        {title_html}
                    </RawMarkup>
                </span>
            </h1>
        </div>
    );
}

PlainHeader.propTypes = {
    header_classes: PropTypes.arrayOf(PropTypes.string),
    title_html: PropTypes.string,
    renderMode: PropTypes.string,
};

PlainHeader.defaultProps = {
    header_classes: [],
    title_html: '',
    renderMode: '',
};

export default PlainHeader;
