import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import { asClassComponent } from '../../hocs';
import GenericVideoAssetSpecification from './GenericVideoAssetSpecification';
import { VIDEO, TYPE_VIMEO, TYPE_YOUTUBE } from './constants';

function getEmbedUrlForType(url, type) {
    if (type === TYPE_VIMEO) {
        const uri = new URI(url);
        uri.search({
            title: '0',
            byline: '0',
            portrait: '0',
        });
        return uri.toString();
    }
    if (type === TYPE_YOUTUBE) {
        const uri = new URI(url);
        uri.search({
            wmode: 'transparent',
        });
        return uri.toString();
    }
    return url;
}

function VideoIframe({
    primaryAsset,
    secondaryAssets,
}) {
    if (!primaryAsset) {
        return null;
    }

    const {
        typeCategory,
        type,
        url,
        width,
        height,
        extraProps = {},
    } = primaryAsset;

    if (typeCategory !== VIDEO) {
        return null;
    }

    if (type === TYPE_VIMEO || type === TYPE_YOUTUBE) {
        const embedUrl = getEmbedUrlForType(url, type);
        return (
            <iframe
                allowFullScreen
                {...extraProps}
                src={embedUrl}
                width={width}
                height={height} />
        );
    }
}

VideoIframe.propTypes = {
    primaryAsset: PropTypes.shape(GenericVideoAssetSpecification),
    secondaryAssets: PropTypes.arrayOf(PropTypes.shape(GenericVideoAssetSpecification)),
};

VideoIframe.defaultProps = {
    primaryAsset: null,
    secondaryAssets: [],
};

export default asClassComponent(VideoIframe);
