import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import ShareNav from './ShareNav.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import QualifiedLink from '../QualifiedLink.jsx';
import { valueHasData } from '../../../utils/data';
import { withWindowProp } from '../../hocs';
import { KEY_ESCAPE } from '../../../constants';
import styles from './utility-nav.scss';

function getClassNames_forNavItem(navItem) {
    const classes = [
        styles[navItem + 'Button'],
        'icon',
        `icon-${navItem}`,
    ];

    return classes.join(' ');
}

class UtilityNav extends React.Component {
    constructor(props) {
        super(props);

        this.shareNavButtonRef = React.createRef();
        this.shareNavRef = React.createRef();

        this.state = {
            shareNavIsOpen: false,
        };
    }

    componentDidMount() {
        const { window } = this.props;
        const { document } = window;
        this.closeShareNavOnBodyClick = (ev) => {
            const shareNavEl = get(this, 'shareNavRef.current.wrapperRef.current', null);
            if (!shareNavEl) {
                return;
            }
            if (ev.target !== this.shareNavButtonRef.current &&
                    !shareNavEl.contains(ev.target)) {
                this.setState({ shareNavIsOpen: false });
            }
        };

        this.closeShareNavOnEscapeKey = (ev) => {
            if (ev.keyCode === KEY_ESCAPE) {
                this.setState({ shareNavIsOpen: false });
            }
        };

        document.body.addEventListener('click', this.closeShareNavOnBodyClick);
        window.addEventListener('keydown', this.closeShareNavOnEscapeKey);
    }

    componentWillUnmount() {
        const { window } = this.props;
        const { document } = window;

        document.body.removeEventListener('click', this.closeShareNavOnBodyClick);
        window.removeEventListener('keydown', this.closeShareNavOnEscapeKey);
    }

    toggleShare(ev) {
        ev.preventDefault();
        this.setState({
            shareNavIsOpen: !this.state.shareNavIsOpen,
        });
    }

    render() {
        const {
            window,
            pdfUrl,
            hasVerticalPadding,
            hidePrint,
            hidePDF,
            leftAlignPrint,
        } = this.props;
        const navClasses = classNames(
            styles.nav,
            { 'u-padding-top-med': hasVerticalPadding },
            { 'u-padding-bottom-med': hasVerticalPadding },
        );

        const navItemClasses = classNames({
            [styles.navItems]: true,
            [styles.navSpaceBetween]: (hidePrint || hidePDF) && !leftAlignPrint,
        });

        return (
            <div className={navClasses}>
                <ul className={navItemClasses}>
                    <ConditionalRender condition={!hidePrint}>
                        <li className={styles.navItem}>
                            <button
                                className={getClassNames_forNavItem('print')}
                                aria-label="Print"
                                onClick={() => window.print()} />
                        </li>
                    </ConditionalRender>
                    <ConditionalRender condition={valueHasData(this.props.shareOptions)}>
                        <li className={styles.navItem}>
                            <button
                                ref={this.shareNavButtonRef}
                                className={getClassNames_forNavItem('share')}
                                aria-label="Share"
                                onClick={ev => this.toggleShare(ev)} />
                        </li>
                    </ConditionalRender>
                    <ConditionalRender condition={this.props.pdfUrl && !hidePDF}>
                        <li className={styles.navItem}>
                            <QualifiedLink
                                href={pdfUrl}
                                className={getClassNames_forNavItem('pdf')}
                                aria-label="PDF" />
                        </li>
                    </ConditionalRender>
                </ul>
                <ConditionalRender condition={valueHasData(this.props.shareOptions)}>
                    <ShareNav
                        ref={this.shareNavRef}
                        shareOptions={this.props.shareOptions}
                        isOpen={this.state.shareNavIsOpen}
                        leftAlignShareTooltip={hidePrint}
                    />
                </ConditionalRender>
            </div>
        );
    }
}

UtilityNav.propTypes = {
    shareOptions: ShareNav.propTypes.shareOptions, // eslint-disable-line react/no-typos
    pdfUrl: PropTypes.string,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hasVerticalPadding: PropTypes.bool,
    hidePrint: PropTypes.bool,
    hidePDF: PropTypes.bool,
    leftAlignPrint: PropTypes.bool,
};

UtilityNav.defaultProps = {
    shareOptions: ShareNav.defaultProps.shareOptions,
    pdfUrl: '',
    hasVerticalPadding: true,
    hidePrint: false,
    hidePDF: false,
    leftAlignPrint: false,
};

export default withWindowProp(UtilityNav);
