import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';
import { withWindowProp } from '../hocs';
import styles from './map.scss';

class Map extends React.Component {
    static getMapOptions() {
        return {
            streetViewControl: false,
            mapTypeControl: false,
            scaleControl: false,
            scrollwheel: false,
            rotateControl: false,
        };
    }

    componentDidMount() {
        const debounceDelay = 10;
        this.resizeHandler = _.debounce(() => this.setMapCenter(), debounceDelay);
        this.props.window.addEventListener('resize', this.resizeHandler);
    }

    componentWillUnmount() {
        this.props.window.removeEventListener('resize', this.resizeHandler);
    }

    setMapCenter() {
        const {
            latitude,
            longitude,
        } = this.props;

        const googleMap = this.mapRef;
        googleMap.panTo({
            lat: latitude,
            lng: longitude,
        });
    }

    render() {
        const {
            api_key,
            isMarkerShown,
            zoomLevel,
            latitude,
            longitude,
        } = this.props;

        if (!latitude || !longitude) {
            return null;
        }

        if (!api_key) {
            console.error('No Google Maps API key provided as a prop (api_key)');
            return null;
        }

        const LoadedGoogleMap = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                defaultZoom={zoomLevel}
                defaultCenter={{
                    lat: latitude,
                    lng: longitude,
                }}
                center={{
                    lat: latitude,
                    lng: longitude,
                }}
                options={Map.getMapOptions()}
                ref={(googleMap) => {
                    if (!googleMap) {
                        return;
                    }
                    this.mapRef = googleMap;
                }}
            >
                {props.isMarkerShown ? <Marker position={{ lat: latitude, lng: longitude }} /> : null}
            </GoogleMap>
        )));

        return (
            <div className={styles.container}>
                <div className={styles.ratioLock} />
                <div className={styles.map}>
                    <LoadedGoogleMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${api_key}&v=3&libraries=geometry,drawing,places`}
                        isMarkerShown
                        loadingElement={
                            <div style={{ height: '100%' }} />
                        }
                        containerElement={
                            <div style={{ height: '100%', width: '100%' }} />
                        }
                        mapElement={
                            <div style={{ height: '100%' }} />
                        }
                    />
                </div>
            </div>
        );
    }
}

Map.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    api_key: PropTypes.string.isRequired,
    isMarkerShown: PropTypes.bool,
    zoomLevel: PropTypes.number,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Map.defaultProps = {
    latitude: 0,
    longitude: 0,
    isMarkerShown: true,
    zoomLevel: 16,
};

export default withWindowProp(Map);
