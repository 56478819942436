import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import request from 'superagent';

import ConditionalRender from '../ConditionalRender.jsx';
import GatedContentForm from './GatedContentForm.jsx';
import RawMarkup from '../RawMarkup.jsx';
import { valueHasData } from '../../../utils/data';

function withSubmit(FormComponent) {
    class FormWithSubmission extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                success: false,
            };

            this.handleSubmit = this.handleSubmit.bind(this);
            this.recaptchaRef = React.createRef();
        }

        onSuccess() {
            this.setState({ success: true });
        }

        onError(errors) {
            this.setState({ errors });

            if (_.isFunction(this.recaptchaRef, 'current.reset')) {
                this.recaptchaRef.current.reset();
            }
        }

        handleSubmit(recaptchaValue) {
            const { pageId } = this.props;

            if (recaptchaValue === '') {
                this.setState({
                    errors: {
                        recaptcha: 'Please click on the ReCaptcha check before submitting the form',
                    },
                });
                return;
            }

            const data = new FormData(document.getElementById('gated_content_form'));

            data.append('page_id', pageId);

            request.post('/_forms').send(data).then((response) => {
                const status = _.get(response, 'body.status');
                const errors = _.get(response, 'body.errors');

                if (status === 'success') {
                    this.onSuccess();
                } else {
                    this.onError(errors);
                }
            });
        }

        render() {
            const { errors, success } = this.state;

            const {
                gatedContentMain,
                recaptchaKey,
                successMessage,
            } = this.props;

            const hasErrors = valueHasData(errors);

            if (success) {
                return (
                    <div className="rte u-padding-bottom-med">
                        <RawMarkup>{successMessage || 'Thank you for your submission.'}</RawMarkup>
                    </div>
                );
            }
            if (hasErrors) {
                this.recaptchaRef.current.reset();
            }

            return (
                <React.Fragment>
                    <FormComponent
                        successMessage={successMessage}
                        recaptchaKey={recaptchaKey}
                        recaptchaRef={this.recaptchaRef}
                        success={success}
                        errors={errors}
                        onSubmit={this.handleSubmit}
                    />

                    <ConditionalRender condition={!success}>
                        <div className="rte u-padding-bottom-med">
                            <RawMarkup>
                                {gatedContentMain}
                            </RawMarkup>
                        </div>
                    </ConditionalRender>
                </React.Fragment>
            );
        }
    }

    FormWithSubmission.propTypes = {
        gatedContentMain: PropTypes.string,
        successMessage: PropTypes.string,
        pageId: PropTypes.number.isRequired,
        recaptchaKey: PropTypes.string,
    };

    FormWithSubmission.defaultProps = {
        gatedContentMain: '',
        successMessage: 'Thank you!',
        recaptchaKey: '',
    };

    return FormWithSubmission;
}

export default withSubmit(GatedContentForm);
