import React from 'react';
import PropTypes from 'prop-types';
import MaybeLink from '../MaybeLink.jsx';
import RawMarkup from '../RawMarkup.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import SearchRelevanceNotes from '../SearchRelevanceNotes/index.jsx';
import { withUrlsQualified } from '../../hocs';
import styles from './search-results-with-date.scss';

function HorizontalTextResult({
    date,
    category,
    title_html,
    intro,
    url,
    extraClasses,
    displaySearchHighlights,
    id,
}) {
    const containerClassName = [
        'clearfix',
        'u-page-break-inside-avoid',
        ...extraClasses,
    ].join(' ');

    return (
        <div className={containerClassName}>
            <div className={styles.sideContent}>
                <ConditionalRender condition={date}>
                    <p className={`${styles.date} type-small-paragraph  dark-blue  u-uppercase`}>{date}</p>
                </ConditionalRender>
                <ConditionalRender condition={category}>
                    <p className={`${styles.blogCategory}  dark-blue  u-letter-spacing-1  u-uppercase`}>{category}</p>
                </ConditionalRender>
            </div>
            <div className={styles.mainContent}>
                <p className="type-professional-search-result">
                    <MaybeLink href={url} className={`${styles.introText}  dark-blue  u-standard-link-underline`}>
                        <RawMarkup>{title_html}</RawMarkup>
                    </MaybeLink>
                </p>
                <ConditionalRender condition={displaySearchHighlights}>
                    <SearchRelevanceNotes id={id} />
                </ConditionalRender>
                <ConditionalRender condition={intro}>
                    <p className={`${styles.intro} type-search-result gray`}>{intro}</p>
                </ConditionalRender>
            </div>
        </div>
    );
}

HorizontalTextResult.propTypes = {
    category: PropTypes.string,
    date: PropTypes.string,
    title_html: PropTypes.string,
    intro: PropTypes.string,
    url: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    displaySearchHighlights: PropTypes.bool,
    id: PropTypes.string,
};

HorizontalTextResult.defaultProps = {
    category: '',
    date: '',
    title_html: '',
    intro: '',
    url: '',
    extraClasses: [],
    displaySearchHighlights: false,
    id: null,
};

export default withUrlsQualified(HorizontalTextResult, ['url']);
