import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './logo.scss';
import QualifiedLink from '../QualifiedLink.jsx';
import { withUrlsQualified } from '../../hocs';

function Logo({ variant, isInTakeover, isHomepage, isMerger }) {
    const ImgWithSrcQualified = withUrlsQualified('img', ['src']);

    const imgUrl = `/images/logo-${variant}.svg`;

    const logoContainerClassName = classnames(
        styles.logoContainer,
        { [styles.inTakeover]: isInTakeover },
        { [styles.isHomepage]: isHomepage },
    );

    const logoClassName = classnames(
        styles.logo,
        'white',
        { [styles.inTakeover]: isInTakeover },
        { [styles.isHomepage]: isHomepage },
        { [styles.isMerger]: isMerger },
    );

    return (
        <div className={logoContainerClassName}>
            <div className={logoClassName}>
                <QualifiedLink href="/">
                    <ImgWithSrcQualified
                        src={imgUrl}
                        alt="Troutman Pepper" />
                </QualifiedLink>
            </div>
        </div>
    );
}

Logo.propTypes = {
    isHomepage: PropTypes.bool,
    variant: PropTypes.oneOf(['normal', 'white']),
    isInTakeover: PropTypes.bool,
    isMerger: PropTypes.bool,
};

Logo.defaultProps = {
    variant: 'normal',
    isInTakeover: false,
    isHomepage: false,
    isMerger: false,
};

export default Logo;
