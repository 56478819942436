import React from 'react';
import ReactDOM from 'react-dom';

function clientBootstrapWithTemplateComponent(TemplateComponent) {
    if (typeof document !== 'undefined') {
        const initialProps = JSON.parse(document.getElementById('initial-props').innerHTML);

        const templateElement = React.createElement(TemplateComponent, {
            ...initialProps,
            window,
        });
        ReactDOM.hydrate(templateElement, document);
    }
}

export default clientBootstrapWithTemplateComponent;
