import React from 'react';
import { connect } from 'react-redux';
import { catchFormUpdates } from '@rubyapps-mvc/redux-search/actions';
import DateRange from '../Forms/DateRange.jsx';
import { stringDateFromMaybeMomentDate } from '../../../utils/date';

const FROMDATE = 'fromdate';
const TODATE = 'todate';

const mapStateToProps = state => ({
    startValue: state.search.formState[FROMDATE] || '',
    endValue: state.search.formState[TODATE] || '',
});

const mapDispatchToProps = dispatch => ({
    onStartValueChange(date) {
        const stringDate = stringDateFromMaybeMomentDate(date);
        dispatch(catchFormUpdates({ [FROMDATE]: stringDate }));
    },
    onEndValueChange(date) {
        const stringDate = stringDateFromMaybeMomentDate(date);
        dispatch(catchFormUpdates({ [TODATE]: stringDate }));
    },
});

const ConnectedDateRange = connect(mapStateToProps, mapDispatchToProps)(({
    startValue,
    endValue,
    onStartValueChange,
    onEndValueChange,
    startPlaceholder,
    endPlaceholder,
}) => (
    <DateRange
        startName={FROMDATE}
        endName={TODATE}
        startValue={startValue}
        endValue={endValue}
        onStartValueChange={onStartValueChange}
        onEndValueChange={onEndValueChange}
        startPlaceholder={startPlaceholder}
        endPlaceholder={endPlaceholder}
    />
));

export default ConnectedDateRange;
