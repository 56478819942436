import React from 'react';
import PropTypes from 'prop-types';
import {
    CHAR_CODE_SINGLE_OPENING_CURLY_QUOTE,
    CHAR_CODE_SINGLE_CLOSING_CURLY_QUOTE,
} from '../../constants';

const openingSingleCurlyQuote = String.fromCharCode(CHAR_CODE_SINGLE_OPENING_CURLY_QUOTE);
const closingSingleCurlyQuote = String.fromCharCode(CHAR_CODE_SINGLE_CLOSING_CURLY_QUOTE);

function CommaSeparatedSearchTerms({ terms }) {
    return terms.map((term, index) => (
        <React.Fragment key={index}>
            {openingSingleCurlyQuote}<strong>{term}</strong>{closingSingleCurlyQuote}
            {index === terms.length - 1 ? null : ', '}
        </React.Fragment>
    ));
}

CommaSeparatedSearchTerms.propTypes = {
    terms: PropTypes.arrayOf(PropTypes.string),
};

CommaSeparatedSearchTerms.defaultProps = {
    terms: [],
};

export default CommaSeparatedSearchTerms;
