import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import request from 'superagent';
import URI from 'urijs';

import { getQueryString_fromSearchState_andOptions } from '@rubyapps-mvc/redux-search/utils';
import { connect } from 'react-redux';
import { KEY_ENTER } from '../../../constants';

import SearchNotes from './Notes.jsx';
import styles from './search-relevance-notes.scss';

const mapStateToProps = state => ({
    query: state.search.formState.q,
});

class SearchRelevanceInfo extends React.Component {
    constructor(props) {
        super(props);

        this.closeSearchInfo = this.closeSearchInfo.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    closeSearchInfo(ev) {
        this.setState({ isOpen: false });
    }

    fetchSearchHighlights(ev) {
        ev.preventDefault();

        if (!window) {
            return;
        }

        const {
            query,
            id,
        } = this.props;

        const requestInitTime = Date.now();
        this.setState({ lastRequestInitTime: requestInitTime });

        const uri = new URI('/_site/search');
        uri.search({
            q: query,
            v: 'highlight',
            highlight: true,
            id,
        });
        const highlightsUrl = uri.toString();

        request(highlightsUrl).then((res) => {
            if (requestInitTime !== this.state.lastRequestInitTime) {
                return;
            }

            const pageResult = _.get(res, 'body.hits.ALL.hits.0');

            this.setState({
                isOpen: true,
                notesProps: {
                    name: pageResult.name,
                    score: pageResult._score,
                    highlight: pageResult.highlight,
                },
            });
        });
    }

    handleKeyDown(ev) {
        if (ev.keyCode === KEY_ENTER) {
            this.fetchSearchHighlights(ev);
        }
    }

    render() {
        const {
            isOpen,
            notesProps,
        } = this.state;

        return (
            <React.Fragment>
                <button
                    tabIndex="0"
                    className={styles.openSearchInfo}
                    onKeyDown={ev => this.handleKeyDown(ev)}
                    onClick={ev => this.fetchSearchHighlights(ev)}
                    aria-label="Open Search Relevance Information"
                />
                {isOpen ? <SearchNotes
                    closeSearchInfo={this.closeSearchInfo}
                    handleKeyDown={this.handleKeyDown}
                    {...notesProps}
                /> : null}
            </React.Fragment>
        );
    }
}

SearchRelevanceInfo.propTypes = {
    id: PropTypes.string,
    query: PropTypes.string,
};

SearchRelevanceInfo.defaultProps = {
    id: null,
    query: '',
};

const ConnectedSearchRelevanceInfo = connect(mapStateToProps)(SearchRelevanceInfo);
export default ConnectedSearchRelevanceInfo;
