import React from 'react';
import PropTypes from 'prop-types';
import { asClassComponent, withUrlsQualified } from '../../hocs';
import AudioSource from './AudioSource.jsx';
import { AUDIO } from './constants';

function Audio({
    primaryAsset,
    secondaryAssets,
}) {
    if (!primaryAsset) {
        return null;
    }

    const {
        typeCategory,
        type,
        url,
        extraProps = {},
    } = primaryAsset;

    if (typeCategory !== AUDIO) {
        return null;
    }

    return (
        <audio
            controls
            {...extraProps}
            src={url}>
            <AudioSource
                typeCategory={typeCategory}
                type={type}
                url={url} />
        </audio>
    );
}

const GenericAudioAssetSpec = {
    typeCategory: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Audio.propTypes = {
    primaryAsset: PropTypes.shape(GenericAudioAssetSpec),
    secondaryAssets: PropTypes.arrayOf(PropTypes.shape(GenericAudioAssetSpec)),
};

Audio.defaultProps = {
    primaryAsset: null,
    secondaryAssets: [],
};

export default asClassComponent(withUrlsQualified(Audio, ['url']));
