import React from 'react';
import PropTypes from 'prop-types';
import styles from './resource.scss';
import MaybeLink from '../MaybeLink.jsx';
import RawMarkup from '../RawMarkup.jsx';
import { resourceColorMap, PDF_RENDER_MODE } from '../../../constants';

function setContainerClass(idx, layoutClass) {
    const resourceColor = resourceColorMap[parseInt(idx, 10) % 5];

    const classes = [
        styles[resourceColor],
        styles.resource,
        layoutClass,
        'u-padding-med',
        'bg-white',
        'u-page-break-inside-avoid',
    ];

    return classes.join('  ');
}

function Resource(props) {
    const {
        title_html,
        url,
        idx,
        layoutClass,
        renderMode,
    } = props;

    if (!title_html) {
        return null;
    }

    return (
        <MaybeLink href={url} className={setContainerClass(idx, layoutClass, renderMode)} fallbackTag="div">
            <div className={styles.resourceTitleContainer}>
                <p className={`type-module-title ${styles.resourceTitle} ${renderMode === PDF_RENDER_MODE && 'light-blue'}`}>
                    <RawMarkup>{title_html}</RawMarkup>
                </p>
            </div>
        </MaybeLink>
    );
}

Resource.propTypes = {
    title_html: PropTypes.string,
    url: PropTypes.string,
    layoutClass: PropTypes.string,
    idx: PropTypes.number,
    renderMode: PropTypes.string,
};

Resource.defaultProps = {
    title_html: '',
    url: '',
    layoutClass: '',
    idx: '',
    renderMode: '',
};

export default Resource;
