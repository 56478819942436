import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import Select from 'react-select/dist/react-select';
import { css } from 'emotion';
import {
    COLOR_VERY_LIGHT_GRAY,
    COLOR_LIGHT_GRAY,
    COLOR_DARK_GRAY,
    COLOR_LIGHT_BLUE,
    COLOR_WHITE,
} from '../../../constants';
import plainDropdownStyles from './plain-dropdown.scss';
import ConditionalRender from '../ConditionalRender';

const { components } = Select;

/* eslint-disable react/prop-types */
const DropdownIndicator = (props) => {
    const iconClassName = classnames('icon', 'dark-gray', {
        'icon-minus': props.isFocused,
        'icon-plus': !props.isFocused,
    });

    return (
        <components.DropdownIndicator {...props}>
            <span className={iconClassName} />
        </components.DropdownIndicator>
    );
};

const DropdownChildIndicator = (props) => {
    const iconClassName = classnames('icon', 'dark-gray', plainDropdownStyles.childIndicatorIcon, {
        'icon-minus': props.data.hasVisibleChildren,
        'icon-plus': !props.data.hasVisibleChildren,
    });

    const value = props.data.sitemap_id;

    return (
        <button
            aria-label="Toggle additional options visibility"
            className={plainDropdownStyles.childIndicator}
            onClick={ev => props.selectProps.onNestedMenuIndicatorClick(ev, { parentId: value })}
        >
            <span className={iconClassName} />
        </button>
    );
};

const Option = (props) => {
    if (props.data.isHidden) {
        return null;
    }

    const isIndented = props.data.isIndented;
    const showChildIndicator = props.data.showChildIndicator;

    return (
        <components.Option
            {...props}
            className={props.cx(
                css(props.getStyles('option', props)),
                {
                    option: true,
                    'option--is-hidden': false,
                    'option--is-disabled': props.isDisabled,
                    'option--is-focused': props.isFocused,
                    'option--is-selected': props.isSelected,
                    'option--is-indented': isIndented,
                },
            )}
        >
            {props.children}
            <ConditionalRender condition={showChildIndicator}>
                <DropdownChildIndicator {...props} />
            </ConditionalRender>
        </components.Option>
    );
};
/* esline-enable react/prop-types */

class PlainDropdown extends React.Component {
    static getDerivedStateFromProps(props, state) {
        return {
            value: props.value || state.value,
        };
    }

    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.state = {
            value: props.value || props.options[0],
        };
    }

    handleChange(selected, actionType) {
        const newValue = selected === this.state.value ? this.props.options[0] : selected;
        this.setState({
            value: newValue,
        });
        this.props.onChange(newValue, actionType);
        this.selectRef.current.select.blur();
    }

    render() {
        const {
            name,
            options,
        } = this.props;

        const { value } = this.state;

        const customStyles = {
            container: (base, state) => ({
                ...base,

                '& + &': {
                    marginTop: '4px',
                },
            }),
            control: (base, state) => ({
                ...base,
                backgroundColor: COLOR_WHITE,
                borderRadius: '0',
                borderWidth: '0 0 1px',
                borderColor: COLOR_DARK_GRAY,
                boxShadow: 'none',
                cursor: 'pointer',
                // overflow hidden is a hack to stop a cursor
                // from blinking off to the side in IE11
                overflow: 'hidden',

                '&:hover': {
                    borderColor: COLOR_DARK_GRAY,
                    boxShadow: 'none',
                },
            }),
            valueContainer: (base, state) => ({
                ...base,
                padding: '2px 8px 2px 0',
            }),
            singleValue: (base, state) => ({
                ...base,
                marginLeft: '0',
                color: state.getValue()[0].value ? COLOR_LIGHT_BLUE : COLOR_DARK_GRAY,
            }),
            indicatorSeparator: (base, state) => ({
                display: 'none',
            }),
            dropdownIndicator: (base, state) => ({
                ...base,
                padding: '8px 0 8px 8px',
            }),
            menu: (base, state) => ({
                ...base,
                marginTop: '0',
                marginBottom: '0',
                borderRadius: 'none',
                boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
                zIndex: '',
            }),
            menuList: (base, state) => ({
                ...base,
                padding: '0',
                borderWidth: '0',
                backgroundColor: COLOR_VERY_LIGHT_GRAY,
            }),
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? COLOR_LIGHT_BLUE : '',
                cursor: 'pointer',
                position: 'relative',
                paddingRight: '40px',

                '&.plain-dropdown__option--is-focused': {
                    backgroundColor: state.isSelected ? COLOR_LIGHT_BLUE : COLOR_LIGHT_GRAY,
                },

                '&:hover': {
                    backgroundColor: state.isSelected ? COLOR_LIGHT_BLUE : COLOR_LIGHT_GRAY,
                },

                '& + &': {
                    borderTop: `1px solid ${COLOR_LIGHT_GRAY}`,
                },
            }),
        };

        return (
            <Select
                ref={this.selectRef}
                name={name}
                value={value}
                isSearchable={false}
                components={{ DropdownIndicator, Option }}
                classNamePrefix="plain-dropdown"
                styles={customStyles}
                onNestedMenuIndicatorClick={this.props.onNestedMenuIndicatorClick}
                onChange={(selected, actionType) => this.handleChange(selected, actionType)}
                options={options} />
        );
    }
}

const optionShape = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
});

PlainDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(optionShape),
    onChange: PropTypes.func,
    onNestedMenuIndicatorClick: PropTypes.func,
    value: optionShape,
};

PlainDropdown.defaultProps = {
    options: [],
    onChange: _.noop,
    onNestedMenuIndicatorClick: _.noop,
    value: null,
};

export default PlainDropdown;
