import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { VelocityComponent } from 'velocity-react';
import TabContent from './TabContent.jsx';
import styles from './homepage-tabs.scss';
import ConditionalRender from '../ConditionalRender.jsx';
import { KEY_ENTER } from '../../../constants';

class HomepageTab extends React.Component {
    constructor(props) {
        super(props);
        this.beginOpen = this.props.isOpen;
        this.animatorRef = React.createRef();
    }

    // Remove the height inline style because it can interfere
    // with animations after a resize or when an animation is
    // ended prematurely.
    clearHeightStyle() {
        this.animatorRef.current.style.height = null;
    }

    handleClick(ev) {
        ev.preventDefault();
        this.props.onClick(this.props.index);
    }

    handleKeyDown(ev) {
        ev.preventDefault();
        if (ev.keyCode === KEY_ENTER) {
            this.props.onClick(this.props.index);
        }
    }

    render() {
        const {
            isOpen,
        } = this.props;

        const titleClassNames = _.compact([
            styles.title,
            isOpen && 'light-gray  has-hover-state',
            'type-category-header',
            'u-uppercase',
            'u-letter-spacing-1',
        ]);


        const tabClassNames = [
            styles.tab,
        ];

        const titleContainerClassNames = [
            styles.titleContainer,
        ];

        const tabIconClassNames = [
            styles.tabIcon,
            'icon',
            'white',
        ];

        const activeClass = this.props.isOpen ? styles.active : '';

        const iconClass = this.props.isOpen ? 'icon-minus' : 'icon-plus';

        tabIconClassNames.push(iconClass);

        tabClassNames.push(activeClass);
        titleClassNames.push(activeClass);
        titleContainerClassNames.push(activeClass);

        return (
            <div className={tabClassNames.join(' ')}>
                <div className={titleContainerClassNames.join(' ')}>
                    {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
                    <h3
                        className={titleClassNames.join(' ')}
                        tabIndex={0}
                        onClick={ev => this.handleClick(ev)}
                        onKeyDown={ev => this.handleKeyDown(ev)}>
                        {this.props.title}

                        <span className={tabIconClassNames.join(' ')} />
                    </h3>
                    {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
                </div>
                <div className={styles.hideInNotMobile}>
                    <VelocityComponent
                        animation={this.props.isOpen ? 'slideDown' : 'slideUp'}
                        complete={() => this.clearHeightStyle()}>
                        <div
                            ref={this.animatorRef}
                            style={{ display: this.beginOpen ? 'block' : 'none' }}>
                            <TabContent extraClassName={styles.mobileContentPadding}>
                                {this.props.content_html}
                            </TabContent>

                            <ConditionalRender condition={this.props.tab_link}>
                                <div className={`${styles.mobileContentPadding}`}>
                                    <a href={this.props.tab_link} className={`${styles.tabCta} type-callout-subtitle`}>
                                        {this.props.tab_read_more_override || 'Learn More'}
                                    </a>
                                </div>
                            </ConditionalRender>
                        </div>
                    </VelocityComponent>
                </div>
            </div>
        );
    }
}

HomepageTab.propTypes = {
    index: PropTypes.number,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
    content_html: PropTypes.string,
    tab_link: PropTypes.string,
    tab_read_more_override: PropTypes.string,
};

HomepageTab.defaultProps = {
    index: 0,
    isOpen: false,
    onClick: _.noop,
    title: '',
    content_html: '',
    tab_link: '',
};

export default HomepageTab;
