import React from 'react';
import PropTypes from 'prop-types';

function BreadcrumbList({ breadcrumbs }) {
    const items = breadcrumbs.map((breadcrumb, idx) => ({
        '@type': 'ListItem',
        position: idx + 1,
        item: {
            '@id': breadcrumb.link,
            name: breadcrumb.title,
        },
    }));
    const data = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items,
    };
    const dataAsJSON = JSON.stringify(data);

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: dataAsJSON }} />;
}

BreadcrumbList.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
    })),
};

BreadcrumbList.defaultProps = {
    breadcrumbs: [],
};

export default BreadcrumbList;
