import React from 'react';
import PropTypes from 'prop-types';
import MaybeLink from '../MaybeLink.jsx';
import RawMarkup from '../RawMarkup.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import SearchRelevanceNotes from '../SearchRelevanceNotes/index.jsx';
import { withUrlsQualified } from '../../hocs';
import styles from './vertical-search.scss';

function VerticalTextResult({
    eyebrow,
    title_html,
    intro,
    url,
    extraClasses,
    displaySearchHighlights,
    id,
}) {
    const containerClassName = [
        'u-page-break-inside-avoid',
        ...extraClasses,
    ].join(' ');

    return (
        <div className={containerClassName}>
            <p className={`${styles.eyebrow} type-paragraph-small  type-paragraph-small--bold  gray  u-letter-spacing-1  u-uppercase`}>{eyebrow}</p>
            <p className={`${styles.category} type-professional-search-result`}>
                <MaybeLink href={url} className="dark-blue  u-standard-link-underline">
                    <RawMarkup>{title_html}</RawMarkup>
                </MaybeLink>
            </p>
            <ConditionalRender condition={displaySearchHighlights}>
                <SearchRelevanceNotes id={id} />
            </ConditionalRender>
            <p className="type-search-result gray">{intro}</p>
        </div>
    );
}

VerticalTextResult.propTypes = {
    eyebrow: PropTypes.string,
    title_html: PropTypes.string,
    intro: PropTypes.string,
    url: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    displaySearchHighlights: PropTypes.bool,
    id: PropTypes.string,
};

VerticalTextResult.defaultProps = {
    eyebrow: '',
    title_html: '',
    intro: '',
    url: '',
    extraClasses: [],
    displaySearchHighlights: false,
    id: null,
};

export default withUrlsQualified(VerticalTextResult, ['url']);
