import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './spinkit.scss';

function getClassNameForCircleNumber(circleNumber) {
    return [
        styles[`sk-circle${circleNumber}`],
        styles['sk-child'],
    ].join(' ');
}

function Spinner({ extraClasses }) {
    const count = 12;
    const offset = 1;

    const wrapperClassName = [styles['sk-circle'], ...extraClasses].join(' ');

    return (
        <div className={wrapperClassName}>
            {_.range(offset, count + offset).map(circleNumber => (
                <div className={getClassNameForCircleNumber(circleNumber)} key={circleNumber} />
            ))}
        </div>
    );
}

Spinner.propTypes = {
    extraClasses: PropTypes.arrayOf(PropTypes.string),
};

Spinner.defaultProps = {
    extraClasses: [],
};

export default Spinner;
