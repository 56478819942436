import _ from 'lodash';

export function sortKeysBy(obj, comparator) {
    const keys = _.sortBy(_.keys(obj), key => (
        comparator ? comparator(obj[key], key) : key
    ));

    return _.zipObject(keys, _.map(keys, key => (
        obj[key]
    )));
}

export function valueHasData(value) {
    if (_.isArray(value)) {
        return value.length > 0;
    }
    if (_.isPlainObject(value)) {
        return Object.keys(value).length > 0;
    }

    return Boolean(value);
}
