import React from 'react';
import PropTypes from 'prop-types';

import styles from './news.scss';
import ConditionalRender from '../../elements/ConditionalRender.jsx';
import { getRawEventDuration, getFormattedDaysDuration, getFormattedTimeDuration } from '../../../utils/date';
import {
    DATE_FORMAT_FULL_MONTH_YEAR,
    PDF_RENDER_MODE,
} from '../../../constants';

function EventDuration({
    displayDate,
    rawStartTime,
    rawEndTime,
    startTimeZone,
    endTimeZone,
    renderMode,
}) {
    const timeDateTimeString = getRawEventDuration(rawStartTime, rawEndTime);

    const dateString = displayDate || getFormattedDaysDuration({
        rawStartDate: rawStartTime,
        rawEndDate: rawEndTime,
        startTimeZone,
        endTimeZone,
        dateFormat: DATE_FORMAT_FULL_MONTH_YEAR,
    });

    const timeString = getFormattedTimeDuration({
        rawStartTime,
        rawEndTime,
        startTimeZone,
        endTimeZone,
        timeFormat: renderMode === PDF_RENDER_MODE ? 'h:mm A' : 'h:mmA',
    });

    const hideTime = !(startTimeZone || endTimeZone) || displayDate;

    if (!dateString) {
        return null;
    }

    const dateClasses = (renderMode === PDF_RENDER_MODE ? [
        'type-large-paragraph',
        'type-large-paragraph--bold',
        'dark-blue',
    ] : [
        styles.timeDisplayLine,
        'type-professional-search-result',
        'u-line-height-medium',
    ]).join(' ');

    const timeClasses = (renderMode === PDF_RENDER_MODE ? [
        styles.pdfTime,
        'type-large-paragraph',
        'type-large-paragraph--bold',
        'dark-blue',
    ] : [
        styles.timeDisplayLine,
        'type-small-paragraph',
    ]).join(' ');

    return (
        <React.Fragment>
            <time dateTime={timeDateTimeString}>
                <span className={dateClasses}>
                    <strong>{dateString}</strong>
                </span>
                {/* Ensure a space is always present, since the markup is minified */}
                {' '}
                <ConditionalRender condition={!hideTime}>
                    <span className={timeClasses}>
                        {timeString}
                    </span>
                </ConditionalRender>
            </time>
        </React.Fragment>
    );
}

EventDuration.propTypes = {
    rawStartTime: PropTypes.string,
    rawEndTime: PropTypes.string,
    startTimeZone: PropTypes.string,
    endTimeZone: PropTypes.string,
    displayDate: PropTypes.string,
    renderMode: PropTypes.string,
};

EventDuration.defaultProps = {
    rawStartTime: '',
    rawEndTime: '',
    startTimeZone: '',
    endTimeZone: '',
    displayDate: '',
    renderMode: '',
};

export default EventDuration;
