export function getNestedIndex(prevNestedIndex, index) {
    if (!prevNestedIndex) {
        return `${index}`;
    }
    return [prevNestedIndex, index].join('.');
}

export function getServiceAccordionIds(previousNestedIndex, service, idx) {
    if (service.services === undefined) {
        return [];
    }

    const accordionId = getNestedIndex(previousNestedIndex, idx);

    const childServiceAccordionIds = service.services.reduce((acc, childService, childIndex) => (
        [...acc, ...getServiceAccordionIds(accordionId, childService, childIndex)]
    ), []);

    return [accordionId, ...childServiceAccordionIds];
}
