import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { VelocityComponent } from 'velocity-react';
import styles from './featured-news.scss';
import { blockGradientColors } from '../../../constants.js';
import QualifiedLink from '../QualifiedLink.jsx';
import LearnMore from '../LearnMore.jsx';
import ConditionalRender from '../ConditionalRender.jsx';

const colorMap = blockGradientColors;

const ONE_THIRD = 1 / 3;

function imageAreaClass(idx) {
    const classes = [styles.imageArea];

    if (idx % 2 === 0) {
        classes.push(styles.animateFromBottom);
    } else {
        classes.push(styles.animateFromTop);
    }

    return classes.join(' ');
}

function containerClass(idx) {
    const colorMapClass = `u-gradient-${colorMap[idx % 3]}`;

    const classes = [
        styles.container,
        'clearfix',
        colorMapClass,
        'l-full-width-mobile-tablet',
    ];

    return classes.join('  ');
}

function slideContainerClass(idx) {
    const classes = [
        styles.slideContainer,
    ];

    if (idx % 2 === 0) {
        classes.push(styles.right);
    } else {
        classes.push(styles.left);
    }

    return classes.join(' ');
}

function contentContainerClass(idx) {
    const colorMapClass = `u-gradient-${colorMap[idx % 3]}`;

    const classes = [
        styles.contentContainer,
        colorMapClass,
    ];

    if (idx % 2 === 0) {
        classes.push(styles.right);
    } else {
        classes.push(styles.left);
    }

    return classes.join('  ');
}

function getImageSlideUpAnimation(fromEdge, isInView) {
    if (fromEdge === 'top') {
        if (isInView) {
            return { translateY: [`${100 * ONE_THIRD}%`, 0] };
        }
        return { translateY: 0 };
    }
    if (fromEdge === 'bottom') {
        if (isInView) {
            return { translateY: [`-${100 * ONE_THIRD}%`, 0] };
        }
        return { translateY: 0 };
    }
    return null;
}

function getSlideAnimation(fromEdge, isInView) {
    if (fromEdge === 'left') {
        if (isInView) {
            return { translateX: ['20%', '100%'] };
        }
        return { translateX: '100%' };
    }
    if (fromEdge === 'right') {
        if (isInView) {
            return { translateX: ['-20%', '-100%'] };
        }
        return { translateX: '-100%' };
    }
    return null;
}

function getFadeInAnimation(isInView) {
    if (isInView) {
        return { opacity: 1 };
    }
    return { opacity: 0 };
}

class FeaturedNews extends React.Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.state = {
            isInView: false,
        };
    }

    componentDidMount() {
        const throttleInterval = 50;
        this.performAnimationIfComponentIsInView = _.throttle(() => {
            const windowTop = this.props.window.pageYOffset;
            const windowHeight = this.props.window.innerHeight;
            const offsetTop = this.containerRef.current.getBoundingClientRect().top;
            const clientHeight = this.containerRef.current.clientHeight;
            const tolerance = clientHeight / 2;

            if (offsetTop + tolerance < windowTop + windowHeight) {
                this.setState({ isInView: true });
                this.props.window.removeEventListener('scroll', this.performAnimationIfComponentIsInView);
            }
        }, throttleInterval);

        this.props.window.addEventListener('scroll', this.performAnimationIfComponentIsInView);

        this.performAnimationIfComponentIsInView();
    }

    componentWillUnmount() {
        this.props.window.removeEventListener('scroll', this.performAnimationIfComponentIsInView);
    }

    render() {
        const {
            idx,
            searchUrl,
            url,
            category,
            title,
            intro_content,
            asset: backgroundImage,
        } = this.props;

        const {
            isInView,
        } = this.state;

        const fromXEdge = idx % 2 === 0 ? 'left' : 'right';
        const fromYEdge = idx % 2 === 0 ? 'bottom' : 'top';
        const imageSlideUpAnimation = getImageSlideUpAnimation(fromYEdge, isInView);
        const slideAnimation = getSlideAnimation(fromXEdge, isInView);
        const fadeInAnimation = getFadeInAnimation(isInView);

        const imageStyles = {
            backgroundImage: `url(${backgroundImage})`,
        };

        return (
            <div className={containerClass(idx)} ref={this.containerRef}>
                <VelocityComponent animation={imageSlideUpAnimation} duration={3000}>
                    <div className={imageAreaClass(idx)} style={imageStyles} />
                </VelocityComponent>
                <VelocityComponent animation={slideAnimation} duration={3000}>
                    <div className={slideContainerClass(idx)}>
                        <div className={contentContainerClass(idx)}>
                            <div className={styles.skewBack}>
                                <VelocityComponent animation={fadeInAnimation} duration={1000} delay={2500}>
                                    <div className={styles.content}>
                                        <p className="type-h3">
                                            <QualifiedLink href={searchUrl} className="white has-hover-state">{category}</QualifiedLink>
                                        </p>
                                        <p className={`${styles.title}  type-large-paragraph  type-large-paragraph--bold`}>
                                            <QualifiedLink href={url} className="white has-hover-state">{title}</QualifiedLink>
                                        </p>
                                        <ConditionalRender condition={intro_content}>
                                            <p className="type-small-paragraph  white  u-margin-top-small">{intro_content}</p>
                                        </ConditionalRender>
                                        <div className="u-margin-top-med">
                                            <LearnMore color="white" text="Learn More" url={url} />
                                        </div>
                                    </div>
                                </VelocityComponent>
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        );
    }
}

FeaturedNews.propTypes = {
    category: PropTypes.string,
    title: PropTypes.string,
    intro_content: PropTypes.string,
    searchUrl: PropTypes.string,
    url: PropTypes.string,
    asset: PropTypes.string,
    idx: PropTypes.number,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

FeaturedNews.defaultProps = {
    category: '',
    title: '',
    intro_content: '',
    searchUrl: '',
    url: '',
    asset: '',
    idx: 0,
};

export default FeaturedNews;
