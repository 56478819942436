import React from 'react';
import PropTypes from 'prop-types';
import Asset from '../Asset/index.jsx';
import RawMarkup from '../RawMarkup.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import styles from './captioned-media.scss';

function CaptionedMedia({ asset, caption_html, extraClasses }) {
    if (!asset) {
        return null;
    }

    return (
        <div className="u-hide-in-print">
            <div className={styles.asset}>
                <Asset asset={asset} />
            </div>
            <ConditionalRender condition={caption_html}>
                <div className={`rte rte--captions u-padding-med ${styles.caption} ${extraClasses.join(' ')}`}>
                    <RawMarkup>
                        {caption_html}
                    </RawMarkup>
                </div>
            </ConditionalRender>
        </div>
    );
}

CaptionedMedia.propTypes = {
    asset: Asset.propTypes.asset,
    caption_html: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
};

CaptionedMedia.defaultProps = {
    asset: Asset.defaultProps.asset,
    caption_html: '',
    extraClasses: [],
};

export default CaptionedMedia;
