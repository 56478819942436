import React from 'react';
import PropTypes from 'prop-types';
import { VelocityTransitionGroup } from 'velocity-react';
import CookieHandlerObject from '@rubyapps-mvc/cookie-handler';

import styles from './styles.scss';
import RawMarkup from '../RawMarkup.jsx';

class CookieHandlerOverlay extends React.Component {
    constructor(props) {
        super(props);
        const { cookieHandler } = props;

        this.updateState = this.updateState.bind(this);
        this.acceptCookies = this.acceptCookies.bind(this);
        this.declineCookies = this.declineCookies.bind(this);

        this.state = {
            isOpen: cookieHandler.isPending(),
        };
    }

    updateState() {
        const { cookieHandler } = this.props;
        this.setState({
            isOpen: cookieHandler.isPending(),
        });
    }

    acceptCookies() {
        const { cookieHandler } = this.props;
        cookieHandler.acceptCookies();
        this.updateState();
    }

    declineCookies() {
        const { cookieHandler } = this.props;
        cookieHandler.declineCookies();
        this.updateState();
    }

    render() {
        const { cookieBannerText } = this.props;
        const { isOpen } = this.state;

        return (
            <VelocityTransitionGroup enter={{ animation: 'fadeIn', delay: 1500 }} leave={{ animation: 'fadeOut' }} runOnMount>
                { isOpen ?
                    <div className={`${styles.container}  u-gradient-green`}>
                        <div className={`${styles.contentContainer} l-standard-width  white`}>
                            <p className={`rte rte--cookie-banner ${styles.text}`}>
                                <RawMarkup>{cookieBannerText}</RawMarkup>
                            </p>
                            <div className={styles.options}>
                                <button
                                    className={`${styles.button}  has-hover-state`}
                                    onClick={this.acceptCookies}
                                >
                                    Agree
                                </button>
                                <button
                                    className={`${styles.button}  has-hover-state`}
                                    onClick={this.declineCookies}
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div> : null }
            </VelocityTransitionGroup>
        );
    }
}

CookieHandlerOverlay.propTypes = {
    cookieBannerText: PropTypes.string,
    cookieHandler: PropTypes.shape(CookieHandlerObject).isRequired,
};

CookieHandlerOverlay.defaultProps = {
    cookieBannerText: '',
};

export default CookieHandlerOverlay;
