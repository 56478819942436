import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CommaSeparatedSearchTerms from '../CommaSeparatedSearchTerms.jsx';
import searchStyles from '../../templates/search-styles.scss';

function ResultsHeader({
    titleTerms,
    count,
    onClearSearchClick,
    extraClasses,
}) {
    if (titleTerms.length === 0) {
        return null;
    }

    const wrapperClassName = [
        searchStyles.resultsHeaderArea,
        ...extraClasses,
    ].join(' ');

    const clearButtonClassName = [
        searchStyles.clearSearchButton,
        'type-small-paragraph',
        'u-hide-in-print',
    ].join(' ');

    return (
        <div className={wrapperClassName}>
            <h2 className="u-font-weight-normal dark-gray">
                {typeof count === 'number' ? `${count} results` : 'Results'}
                {' for '}
                <CommaSeparatedSearchTerms terms={titleTerms} />
            </h2>
            <button
                className={clearButtonClassName}
                onClick={ev => onClearSearchClick(ev)}>
                Clear search
            </button>
        </div>
    );
}

ResultsHeader.propTypes = {
    titleTerms: CommaSeparatedSearchTerms.propTypes.terms,
    count: PropTypes.number,
    onClearSearchClick: PropTypes.func,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
};

ResultsHeader.defaultProps = {
    titleTerms: CommaSeparatedSearchTerms.defaultProps.terms,
    count: null,
    onClearSearchClick: _.noop,
    extraClasses: [],
};

export default ResultsHeader;
