import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../context/WindowContext';
import { qualifyUrlInWindow } from '../../utils/url';

function OpenGraph({ properties }) {
    const propertyNames = Object.keys(properties).sort();
    if (propertyNames.length === 0) {
        return null;
    }

    return (
        <WindowContext.Consumer>
            {window => propertyNames.map((property) => {
                const propertyConfig = properties[property];
                const rawValue = propertyConfig.value;
                const hasRawValue = typeof rawValue !== 'undefined' && `${rawValue}`;
                const value = hasRawValue && propertyConfig.qualify
                    ? qualifyUrlInWindow(rawValue, window)
                    : rawValue;
                return <meta property={property} content={value} key={property} />;
            })}
        </WindowContext.Consumer>
    );
}

OpenGraph.propTypes = {
    properties: PropTypes.objectOf(PropTypes.shape({
        qualify: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        value: PropTypes.string,
    })),
};

OpenGraph.defaultProps = {
    properties: {},
};

export default OpenGraph;
