import React from 'react';
import PropTypes from 'prop-types';

// based on https://github.com/chrisshiplet/react-delay/blob/master/src/Delay.js
class DelayRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: true,
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                waiting: false,
            });
        }, this.props.wait);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        if (!this.state.waiting) {
            return this.props.children;
        }
        return null;
    }
}

DelayRender.propTypes = {
    wait: PropTypes.number,
    children: PropTypes.node,
};

DelayRender.defaultProps = {
    wait: 250,
    children: null,
};

export default DelayRender;
