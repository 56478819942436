import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goToPage, setResultsPerPage } from '@rubyapps-mvc/redux-search/actions';
import { QUANTITY_ALL_RESULTS } from '../../../constants';
import UnconnectedPagination from '../Pagination/index.jsx';

function getTotalPagesFromState(state) {
    const { resultsCount } = state.search.viewState;
    const { s: resultsPerPage } = state.search.searchParams;
    return Math.ceil(resultsCount / resultsPerPage);
}

function getCurrentPageFromState(state) {
    const { f: offset, s: resultsPerPage } = state.search.searchParams;
    return Math.floor(offset / resultsPerPage);
}

const mapStateToProps = state => ({
    totalPages: getTotalPagesFromState(state),
    currentPage: getCurrentPageFromState(state),
    layout: state.layout,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onPrevClick(ev, { currentPage }) {
        ev.preventDefault();
        if (currentPage <= 0) {
            return;
        }
        dispatch(goToPage(currentPage - 1));
    },
    onNextClick(ev, { currentPage, totalPages }) {
        ev.preventDefault();
        if (currentPage >= totalPages - 1) {
            return;
        }
        dispatch(goToPage(currentPage + 1));
    },
    onPageClick(ev, { currentPage, page }) {
        ev.preventDefault();
        if (currentPage === page) {
            return;
        }
        dispatch(goToPage(page));
    },
    onViewAllClick(ev) {
        ev.preventDefault();

        dispatch(setResultsPerPage(QUANTITY_ALL_RESULTS, ownProps.defaultResultsPerPage, 0));
    },
});

const ConnectedPagination = connect(mapStateToProps, mapDispatchToProps)(({
    totalPages,
    currentPage,
    layout,
    onPrevClick,
    onNextClick,
    onPageClick,
    onViewAllClick,
    includeViewAllButton,
}) => (
    <UnconnectedPagination
        totalPages={totalPages}
        currentPage={currentPage}
        layout={layout}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onPageClick={onPageClick}
        onViewAllClick={onViewAllClick}
        includeViewAllButton={includeViewAllButton}
    />
));

ConnectedPagination.propTypes = {
    ...UnconnectedPagination.propTypes,
    defaultResultsPerPage: PropTypes.number.isRequired,
};

ConnectedPagination.defaultProps = UnconnectedPagination.defaultProps;

export default ConnectedPagination;
