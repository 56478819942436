import URI from 'urijs';
import path from 'path';
import _ from 'lodash';

const qualifyUrlInWindow = function _qualifyUrlInWindow(url, customWindowObj) {
    const windowObj = typeof window !== 'undefined'
        ? (customWindowObj || window)
        : customWindowObj;
    if (typeof url !== 'string') {
        console.warn('Non-string provided to qualifyUrlInWindow');
        return '';
    }
    if (!windowObj) {
        return url;
    }


    const contextURI = new URI(_.get(windowObj, 'location.href'));
    const targetURI = new URI(url);
    if (!targetURI.is('relative')) {
        return targetURI.toString();
    }

    if (!(targetURI.toString().indexOf('/') === 0)) {
        targetURI.protocol(contextURI.protocol());
        return targetURI.toString();
    }

    targetURI
        .protocol(contextURI.protocol())
        .domain(contextURI.domain())
        .port(contextURI.port())

    //# NOTE: for localhost, there wouldn't be any subdomains
    const targetSubdomain = contextURI.subdomain().replace('ruby', 'www');
    targetSubdomain && targetURI.subdomain(targetSubdomain);

    return targetURI.toString();
}


const createUrlIdentifierFromString = function _createUrlIdentifierFromString(str) {
    // Always replace space with "-"
    str = str.replace(/ /gi, '-');

    // Collapse "-" sequences
    str = str.replace(/--/gi, '-');

    // Remove "-" from the start and end of the string
    str = str.replace(/^-/, '');
    str = str.replace(/-$/, '');

    return str.toLowerCase();
}

const qualifyStaticUrl_withOptions = function _qualifyStaticUrl_withOptions(url, options) {
    const { version_number } = options;
    return version_number ? path.join('/cached', version_number.toString(), url) : url;
}


export {
    createUrlIdentifierFromString,
    qualifyUrlInWindow,
    qualifyStaticUrl_withOptions,
};
