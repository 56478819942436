import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../../hocs';
import { IMAGE } from './constants';

class Img extends React.Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        // objectFitPolyfill will only check for the existence of the `data-object-fit` attribute
        // when called globally (e.g. window.objectFitPolyfill()) so this calling code must check.
        const hasObjectFit = this.imageRef.current.hasAttribute('data-object-fit');
        if (hasObjectFit) {
            // objectFitPolyfill does not export anything. It attaches itself to the window.
            import('objectFitPolyfill').then(() => {
                window.objectFitPolyfill(this.imageRef.current);
            });
        }
    }

    render() {
        const {
            typeCategory,
            url,
            width,
            height,
            alt,
            extraProps,
        } = this.props;

        if (typeCategory !== IMAGE) {
            return null;
        }

        return (
            <img
                {...extraProps}
                ref={this.imageRef}
                src={url}
                width={width}
                height={height}
                alt={alt} />
        );
    }
}

Img.propTypes = {
    typeCategory: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Img.defaultProps = {
    typeCategory: '',
    url: '',
    width: 0,
    height: 0,
    alt: '',
    extraProps: {},
};

export default withUrlsQualified(Img, ['url']);
