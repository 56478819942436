import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from './Breadcrumbs/index.jsx';
import UtilityNav from './UtilityNav/index.jsx';
import ConditionalRender from './ConditionalRender.jsx';
import { valueHasData } from '../../utils/data';
import styles from './page-eyebrow.scss';

function PageEyebrow(props) {
    const additionalClassNameString = props.additionalClasses.join(' ');
    return (
        <div className={`${styles.eyebrow} l-standard-width ${additionalClassNameString}`}>
            <div className={styles.breadcrumb}>
                <Breadcrumbs links={props.breadcrumbLinks} />
            </div>
            <ConditionalRender condition={valueHasData(props.utilityNavProps)}>
                <div className={`${styles.utilityNav} u-hide-in-print`}>
                    <UtilityNav {...props.utilityNavProps} hidePrint={props.hidePrint} hidePDF={props.hidePDF} />
                </div>
            </ConditionalRender>
        </div>
    );
}

PageEyebrow.propTypes = {
    breadcrumbLinks: Breadcrumbs.propTypes.links, // eslint-disable-line react/no-typos
    utilityNavProps: PropTypes.shape(UtilityNav.propTypes),
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
    hidePrint: PropTypes.bool,
    hidePDF: PropTypes.bool,
};

PageEyebrow.defaultProps = {
    breadcrumbLinks: Breadcrumbs.defaultProps.links,
    utilityNavProps: UtilityNav.defaultProps,
    additionalClasses: [],
    hidePrint: false,
    hidePDF: false,

};

export default PageEyebrow;
