import { combineReducers } from 'redux';
import { getServiceAccordionIds } from './utils';
import {
    COLLAPSE_ALL,
    EXPAND_ALL,
    TOGGLE_ACCORDION,
} from './constants';

function accordionOpenStatesById(state = {}, action) {
    switch (action.type) {
        case COLLAPSE_ALL:
            return Object.keys(state).reduce((acc, currId) => {
                acc[currId] = false;

                return acc;
            }, {});
        case EXPAND_ALL:
            return Object.keys(state).reduce((acc, currId) => {
                acc[currId] = true;

                return acc;
            }, {});
        case TOGGLE_ACCORDION:
            return {
                ...state,
                [action.payload.id]: !state[action.payload.id],
            };
        default:
            return state;
    }
}

function getAccordionsInitialState(accordions = [], name) {
    const accordionIds = getServiceAccordionIds('', accordions, name);

    return accordionIds.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
    }, {});
}

export function getInitialState(props) {
    return {
        practicesAccordionOpenStatesById: getAccordionsInitialState(props.services[0].services, props.services[0].name),
        industriesAccordionOpenStatesById: getAccordionsInitialState(props.services[1].services, props.services[1].name),
    };
}

export const reducer = combineReducers({
    practicesAccordionOpenStatesById: accordionOpenStatesById,
    industriesAccordionOpenStatesById: accordionOpenStatesById,
});
