import React from 'react';
import PropTypes from 'prop-types';
import Asset from '../../elements/Asset/index.jsx';
import ConditionalRender from '../../elements/ConditionalRender.jsx';
import { valueHasData } from '../../../utils/data';
import { PDF_RENDER_MODE } from '../../../constants';
import styles from './image-header.scss';

function ImageHeader(props) {
    const {
        children,
        asset,
        renderMode,
    } = props;

    const headerClasses = [
        styles.header,
        'l-standard-width',
        renderMode === PDF_RENDER_MODE ? styles.pdfImageHeader : '',
        ...props.header_classes,
    ].join(' ');


    const hasImage = valueHasData(asset);

    const additionalContentClass = hasImage ? '' : styles.noImage;

    const inlineWrapperClassName = styles.inlineWrapper;

    return (
        <header className={headerClasses}>
            <div className={`${styles.content} ${additionalContentClass} white`}>
                {children}
            </div>
            <ConditionalRender condition={hasImage}>
                <div className={styles.photoContainer}>
                    <div className={styles.photo}>
                        <Asset
                            asset={asset}
                            extraProps={{
                                'data-object-fit': 'cover',
                                'data-object-position': 'top',
                            }}
                            inlineWrapperClassName={inlineWrapperClassName}
                        />
                    </div>
                </div>
            </ConditionalRender>
        </header>
    );
}

ImageHeader.propTypes = {
    header_classes: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    asset: PropTypes.shape(Asset.propTypes),
    renderMode: PropTypes.string,
};

ImageHeader.defaultProps = {
    header_classes: [],
    children: null,
    asset: {},
    renderMode: '',
};

export default ImageHeader;
