import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { LAYOUT_MOBILE } from '../../../constants';
import styles from './pagination.scss';

function getButtonClassName(page, currentPage) {
    const classes = [styles.link];
    if (page === currentPage) {
        classes.push(styles.isActive);
    }
    return classes.join(' ');
}

function getButtonText(page) {
    return (page + 1).toString();
}

// target page is next to or equal to current page
// e.g. 1 ... (4) [5] 6 ... 14
function isCase1(page, currentPage) {
    return Math.abs(page - currentPage) <= 1;
}

// target page is an edge page
// e.g. [1] 2 3 ... 12 13 14
function isCase2(page, edgePage) {
    return Math.abs(page - edgePage) === 0;
}

// current page is 3 away from edge page and target page is next to edge page
// e.g. 1 (2) 3 [4] 5 ... 14
function isCase3(page, currentPage, edgePage) {
    return Math.abs(currentPage - edgePage) === 3 && Math.abs(page - edgePage) === 1;
}

// current page is 2 away from one edge page and target page is next to other edge page
// e.g. 1 2 [3] 4 ... [13] 14
function isCase4(page, currentPage, nearEdgePage, farEdgePage) {
    return Math.abs(currentPage - nearEdgePage) === 2 && Math.abs(page - farEdgePage) === 1;
}

// current page is edge or next to one edge and target page is within 2 of other edge
// e.g. 1 [2] 3 ... (12) 13 14
// e.g. [1] 2 3 ... 12 (13) 14
function isCase5(page, currentPage, nearEdgePage, farEdgePage) {
    return Math.abs(currentPage - farEdgePage) <= 1 && Math.abs(page - nearEdgePage) <= 2;
}

// current page is the edge page and target page is two away
// e.g. [1] 2 (3) ... 12 13 14
function isCase6(page, currentPage, edgePage) {
    return Math.abs(currentPage - edgePage) === 0 && Math.abs(page - edgePage) === 2;
}

// check various conditions to determine if page should show in paginator
function shouldRenderPageButton(page, totalPages, currentPage) {
    // Always show all pages if there are 7 or fewer
    if (totalPages <= 7) return true;

    const bottomPage = 0;
    const topPage = totalPages - 1;
    return (
        isCase1(page, currentPage) ||
        isCase2(page, bottomPage) ||
        isCase2(page, topPage) ||
        isCase3(page, currentPage, bottomPage) ||
        isCase3(page, currentPage, topPage) ||
        isCase4(page, currentPage, bottomPage, topPage) ||
        isCase4(page, currentPage, topPage, bottomPage) ||
        isCase5(page, currentPage, bottomPage, topPage) ||
        isCase5(page, currentPage, topPage, bottomPage) ||
        isCase6(page, currentPage, bottomPage) ||
        isCase6(page, currentPage, topPage)
    );
}

function renderOf() {
    return <span className={styles.ellipsis} key="of">of</span>;
}

function renderEllipsis(key) {
    return <span className={styles.ellipsis} key={key}>...</span>;
}

function renderPageButton(currentPage, onPageClick, page, key) {
    return (
        <button
            key={key}
            className={getButtonClassName(page, currentPage)}
            onClick={ev => onPageClick(ev, { page, currentPage })}
        >
            {getButtonText(page)}
        </button>
    );
}

function renderPages(currentPage, onPageClick, totalPages, layout) {
    if (layout === LAYOUT_MOBILE) {
        const buttons = [renderPageButton(currentPage, onPageClick, currentPage, 'first')];
        buttons.push(renderOf());
        buttons.push(renderPageButton(currentPage, onPageClick, totalPages - 1, 'last'));
        return buttons;
    }

    // Get array of booleans representing if a page button should be rendered.
    const renderablePages = _.range(totalPages).map(page => shouldRenderPageButton(page, totalPages, currentPage));

    // Collapse runs of "false" into a single false.
    const collapsedRenderablePages = renderablePages.reduce((renderablePagesSoFar, pageButtonShouldRender, idx) => {
        const page = idx;
        const lastRenderablePage = renderablePagesSoFar[renderablePagesSoFar.length - 1];
        if (pageButtonShouldRender) {
            // Include pages that should render
            renderablePagesSoFar.push(page);
        } else if (typeof lastRenderablePage === 'number') {
            // If the previous page should render but this page should't,
            // render ellipsis. Include page number for React key.
            renderablePagesSoFar.push(`ellipsis${page}`);
        }
        return renderablePagesSoFar;
    }, []);

    return collapsedRenderablePages.map((page, index) => {
        if (page.toString().indexOf('ellipsis') !== -1) {
            return renderEllipsis(index);
        }
        return renderPageButton(currentPage, onPageClick, page, index);
    });
}

function renderPrevButton(currentPage, onPrevClick) {
    const buttonClassName = [
        styles.arrow,
        styles.arrowLeft,
    ].join(' ');
    const iconClassName = [
        styles.arrowIcon,
        'icon',
        'icon-arrow-button-left',
    ].join(' ');
    const disabled = currentPage <= 0;

    return (
        <button
            key="prevButton"
            disabled={disabled}
            className={buttonClassName}
            aria-label="Previous page of results"
            onClick={ev => onPrevClick(ev, { currentPage })}
        >
            <span className={iconClassName} />
        </button>
    );
}

function renderNextButton(currentPage, onNextClick, totalPages) {
    const buttonClassName = [
        styles.arrow,
        styles.arrowRight,
    ].join(' ');
    const iconClassName = [
        styles.arrowIcon,
        'icon',
        'icon-arrow-button-right',
    ].join(' ');
    const disabled = currentPage >= totalPages - 1;

    return (
        <button
            key="nextButton"
            disabled={disabled}
            className={buttonClassName}
            aria-label="Next page of results"
            onClick={ev => onNextClick(ev, { currentPage, totalPages })}
        >
            <span className={iconClassName} />
        </button>
    );
}

function renderViewAllButton(onViewAllClick) {
    return (
        <div className={styles.viewAllWrapper}>
            <button
                onClick={ev => onViewAllClick(ev)}
                className={classNames(
                    'type-small-caps',
                    'light-blue',
                    'u-uppercase',
                    'u-line-height-medium',
                    'u-letter-spacing-1',
                    'has-hover-state',
                )}
            >
                View all
            </button>
        </div>
    );
}

function Pagination({
    totalPages,
    currentPage,
    layout,
    onPrevClick,
    onNextClick,
    onPageClick,
    onViewAllClick,
    includeViewAllButton,
}) {
    if (totalPages < 2) {
        return null;
    }

    return (
        <div className={styles.paginator}>
            {renderPrevButton(currentPage, onPrevClick)}
            {renderPages(currentPage, onPageClick, totalPages, layout)}
            {renderNextButton(currentPage, onNextClick, totalPages)}
            {includeViewAllButton ? renderViewAllButton(onViewAllClick) : null}
        </div>
    );
}

Pagination.propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    layout: PropTypes.string,
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onPageClick: PropTypes.func,
    onViewAllClick: PropTypes.func,
    includeViewAllButton: PropTypes.bool,
};

Pagination.defaultProps = {
    totalPages: 0,
    currentPage: 0,
    layout: '',
    onPrevClick: _.noop,
    onNextClick: _.noop,
    onPageClick: _.noop,
    onViewAllClick: _.noop,
    includeViewAllButton: false,
};

export default Pagination;
