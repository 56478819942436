import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash';
import get from 'lodash/get';

import { withUrlsQualified } from '../../hocs';
import VideoSource from './VideoSource.jsx';
import VideoCaptions from './VideoCaptions.jsx';
import GenericVideoAssetSpecification from './GenericVideoAssetSpecification';
import { IMAGE, VIDEO, GENERIC } from './constants';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        // objectFitPolyfill will only check for the existence of the `data-object-fit` attribute
        // when called globally (e.g. window.objectFitPolyfill()) so this calling code must check.
        const hasObjectFit = this.videoRef.current.hasAttribute('data-object-fit');

        if (hasObjectFit) {
            // objectFitPolyfill does not export anything. It attaches itself to the window.
            import('objectFitPolyfill').then(() => {
                window.objectFitPolyfill(this.videoRef.current);
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_isEqual(this.props, nextProps);
    }

    play() {
        return this.videoRef.current.play();
    }

    stop() {
        this.videoRef.current.pause();
        this.videoRef.current.currentTime = 0;
    }

    readyState() {
        return get(this.videoRef, 'current.readyState');
    }

    render() {
        const {
            primaryAsset,
            secondaryAssets,
            showControls,
            extraProps,
        } = this.props;

        if (!primaryAsset) {
            return null;
        }

        const {
            typeCategory,
            width,
            height,
        } = primaryAsset;

        if (typeCategory !== VIDEO) {
            return null;
        }

        const VideoWithPosterQualified = withUrlsQualified((props) => {
            const { children, ...videoProps } = props;

            return (
                <video {...videoProps} ref={this.videoRef}>
                    {children}
                </video>
            );
        }, ['poster']);

        // Use rendered sources in the order provided,
        // followed by the base asset as the fallback
        const sources = secondaryAssets.filter(sA => sA.typeCategory === VIDEO).concat([primaryAsset]);

        const posterAsset = secondaryAssets.filter(sA => sA.typeCategory === IMAGE)[0];
        const poster = posterAsset ? posterAsset.url : '';

        const captionsAsset = secondaryAssets.filter(sA => sA.typeCategory === GENERIC)[0];
        const captions = captionsAsset ? <VideoCaptions {...captionsAsset} /> : null;

        const maybeControls = {};

        if (showControls) {
            maybeControls.controls = true;
        }

        return (
            <VideoWithPosterQualified
                {...maybeControls}
                preload="none"
                {...extraProps}
                poster={poster}
                width={width}
                height={height}>
                {sources.map((sourceAsset, idx) => (
                    <VideoSource {...sourceAsset} key={idx} />
                ))}
                {captions}
            </VideoWithPosterQualified>
        );
    }
}

Video.propTypes = {
    primaryAsset: PropTypes.shape(GenericVideoAssetSpecification),
    secondaryAssets: PropTypes.arrayOf(PropTypes.shape(GenericVideoAssetSpecification)),
    showControls: PropTypes.bool,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Video.defaultProps = {
    primaryAsset: null,
    secondaryAssets: [],
    showControls: true,
    extraProps: {},
};

export default Video;
