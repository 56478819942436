import React from 'react';
import PropTypes from 'prop-types';
import RawMarkup from '../RawMarkup.jsx';
import styles from './homepage-tabs.scss';

function TabContent({ children, extraClassName }) {
    const classes = ['rte', 'rte--homepage-tab', styles.typeTabContent, 'white', 'u-letter-spacing-1'];
    if (extraClassName) { classes.push(extraClassName); }
    const className = classes.join(' ');

    return (
        <div className={className}>
            <RawMarkup>
                {children}
            </RawMarkup>
        </div>
    );
}

TabContent.propTypes = {
    children: PropTypes.node,
    extraClassName: PropTypes.string,
};

TabContent.defaultProps = {
    children: null,
    extraClassName: '',
};

export default TabContent;
