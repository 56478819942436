import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Img from './Img.jsx';
import PictureSource from './PictureSource.jsx';

class Picture extends React.Component {
    constructor(props) {
        super(props);
        this.pictureRef = React.createRef();
    }

    componentDidMount() {
        // NOTE: picturefill assumes the existence of global window/document objects
        import('picturefill').then((picturefillModule) => {
            const picturefill = picturefillModule.default;
            picturefill(this.pictureRef.current);
        });
    }

    render() {
        const {
            primaryAsset,
            secondaryAssets,
            inlineWrapperClassName,
        } = this.props;

        const sources = secondaryAssets.map((secondaryAsset, idx) => (
            <PictureSource {...secondaryAsset} key={idx} />
        ));

        const fallbackAsset = _get(secondaryAssets, 0) || primaryAsset;

        return (
            <picture ref={this.pictureRef} className={inlineWrapperClassName}>
                {sources}
                <Img {...fallbackAsset} />
            </picture>
        );
    }
}

Picture.propTypes = {
    primaryAsset: PropTypes.shape(Img.propTypes),
    secondaryAssets: PropTypes.arrayOf(PropTypes.shape(PictureSource.propTypes)),
    inlineWrapperClassName: PropTypes.string,
};

Picture.defaultProps = {
    primaryAsset: null,
    secondaryAssets: [],
    inlineWrapperClassName: '',
};

export default Picture;
