import React from 'react';
import PropTypes from 'prop-types';
import CaptionedMedia from './CaptionedMedia/index.jsx';
import Content from './modules/Content.jsx';
import Accordion from './modules/AccordionModule.jsx';
import Carousel from '../elements/Carousel/index.jsx';
import CareersNavigation from '../elements/CareersNavigation.jsx';
import SignupButtonWithText from './SignupButtonWithText.jsx';
import Highlights from '../templates/standard_interior/Highlights.jsx';
import ProfessionalsGrid from './ProfessionalsGrid.jsx';

function getRepeaterComponentsMap() {
    const componentMapping = {
        captioned_media: CaptionedMedia,
        content: Content,
        accordion: Accordion,
        carousel: Carousel,
        repeater_module_top_links: CareersNavigation,
        repeater_module_call_to_action: SignupButtonWithText,
        repeater_module_highlights: Highlights,
        repeater_module_professionals_quote_grid: ProfessionalsGrid,
    };

    return componentMapping;
}

function getRepeaterComponentFromType(type) {
    const componentMapping = getRepeaterComponentsMap();

    return componentMapping[type];
}

function Repeater({ type, props }) {
    if (!type) {
        return null;
    }

    const Component = getRepeaterComponentFromType(type);

    if (!Component) {
        console.error(`${type} is not a valid repeater component.`);
        return null;
    }

    return (
        <Component {...props} />
    );
}

Repeater.propTypes = {
    type: PropTypes.oneOf(Object.keys(getRepeaterComponentsMap())),
    props: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    hasSidebar: PropTypes.bool,
};

Repeater.defaultProps = {
    type: null,
    props: {},
};

export default Repeater;
