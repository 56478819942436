import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { withRenderModeProp } from "../../../hocs";
import { PDF_RENDER_MODE } from "../../../../constants";

import Content from "./components/Content";
import Modal from "../elements/Modal";

import styles from "./styles.scss";

class PopupAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoaded: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.delayedOpenModal = this.delayedOpenModal.bind(this);
    this.getPopupSeen - this.getPopupSeen.bind(this);
    this.setPopupSeen = this.setPopupSeen.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
    this.setPopupSeen(true);
  }

  closeModal() {
    this.setState({ isModalOpen: false });
    const hasPopupBeenSeen = this.getPopupSeen();
    if (!hasPopupBeenSeen) {
      this.setPopupSeen(true);
    }
  }

  getPopupSeen() {
    return localStorage.getItem("popupSeen");
  }

  setPopupSeen(value = true) {
    return localStorage.setItem("popupSeen", value);
  }

  delayedOpenModal(millisecondDelay = 2000) {
    setTimeout(() => {
      this.openModal();
    }, millisecondDelay);
  }

  componentDidMount() {
    const hasPopupBeenSeen = this.getPopupSeen();
    if (this.props.renderMode !== PDF_RENDER_MODE && !hasPopupBeenSeen) {
      this.setState({ isLoaded: true }, this.delayedOpenModal);
    }
  }

  render() {
    const modalStyleProps = {
      content: {
        background: `url(${this.props.backgroundImageUrl})`,
        backgroundSize: `cover`,
        backgroundPositionX: `right`,
        maxHeight: '540px',
      },
    };
    return (
      <Modal
        className={styles.modal}
        handleCloseModal={this.closeModal}
        isModalOpen={this.state.isModalOpen}
        styleProps={modalStyleProps}
      >
        <Content {...this.props} />
      </Modal>
    );
  }
}

PopupAnnouncement.propTypes = {
  ...Content.propTypes,
  backgroundImageUrl: PropTypes.string,
};

export default withRenderModeProp(PopupAnnouncement);
