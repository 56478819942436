import React from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import './datepicker.scss';
import {
    DATE_FORMAT_FULL_MONTH_YEAR,
    DATEPICKER_COMMON_PROP_TYPES,
    DATEPICKER_COMMON_DEFAULT_PROPS,
} from '../../../constants';

// NOTE: Class component instead of pure functional component is
// necessary to allow react-datepicker to apply a ref to the input
// NOTE: When React.Fragment is the wrapper, its first child gets the ref.
/* eslint-disable react/prop-types */
class CustomInput extends React.Component { // eslint-disable-line react/prefer-stateless-function
    render() {
        return (
            <React.Fragment>
                <input
                    type="text"
                    onClick={this.props.onClick}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    className={this.props.className}
                />
                <span className="react-datepicker__custom-input-icon icon" />
            </React.Fragment>
        );
    }
}
/* eslint-enable react/types */

function WidgetDatePicker({
    name,
    value,
    placeholder,
    handleChange,
    startDate,
    endDate,
    selectsStart,
    selectsEnd,
}) {
    return (
        <ReactDatePicker
            dateFormat={DATE_FORMAT_FULL_MONTH_YEAR}
            fixedHeight
            name={name}
            selected={value}
            placeholderText={placeholder}
            startDate={startDate}
            endDate={endDate}
            onFocus={() => this.setState({ isOpen: true })}
            onBlur={() => this.setState({ isOpen: false })}
            selectStart={selectsStart}
            selectsEnd={selectsEnd}
            isClearable
            onChange={date => handleChange(date)}
            customInput={<CustomInput />}
        />
    );
}

WidgetDatePicker.propTypes = {
    ...DATEPICKER_COMMON_PROP_TYPES,
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
};

WidgetDatePicker.defaultProps = {
    ...DATEPICKER_COMMON_DEFAULT_PROPS,
    startDate: null,
    endDate: null,
    selectsStart: false,
    selectsEnd: false,
};

export default WidgetDatePicker;
