import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../ConditionalRender.jsx';
import Asset from '../Asset/index.jsx';
import styles from './people-listing.scss';
import QualifiedLink from '../QualifiedLink.jsx';
import { PDF_RENDER_MODE } from '../../../constants';
import { withRenderModeProp } from '../../hocs';
import { render } from 'react-dom';

function emailCopyFromProps({ renderMode, email }) {
    const emailTitle = renderMode === PDF_RENDER_MODE ? email : 'Email';

    return emailTitle;
}

function PeopleListing({
    link,
    additionalTitle,
    name,
    position,
    email,
    phone,
    asset,
    renderAdditionalTitle,
    renderMode,
}) {
    const hasContent = name && (link || position || phone || email);

    if (!hasContent) {
        return null;
    }

    const linkColorClasses = renderMode === PDF_RENDER_MODE ? 'light-blue' : 'dark-blue light-blue-hover';

    const resultAdditionalTitle = renderAdditionalTitle ? additionalTitle : "";
    const needComma = (position && resultAdditionalTitle) ? ', ' : "";

    return (
        <div className={`${styles.personListing} pdf-display-block clearfix`}>
            <div className={styles.asset}>
                <QualifiedLink href={link}>
                    <Asset asset={asset} />
                </QualifiedLink>
            </div>

            <div className={`${styles.content} u-page-break-inside-avoid`}>
                <ConditionalRender condition={name}>
                    <p className={`${styles.name}  type-category-header`}>
                        <QualifiedLink className={`${linkColorClasses}`} href={link}>{name}</QualifiedLink>
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={position || resultAdditionalTitle}>
                    <p className={`dark-gray  type-small-paragraph personListingTextLine ${styles.personListingTextLine}`}>
                        {position}{needComma}{resultAdditionalTitle}
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={email}>
                    <p className={`type-small-paragraph  ${styles.personListingTextLine}`}>
                        <a className="dark-gray light-blue-hover" href={`mailto:${email}`}>{emailCopyFromProps({ renderMode, email })}</a>
                    </p>
                </ConditionalRender>
                <ConditionalRender condition={phone}>
                    <p className={`type-small-paragraph  ${styles.personListingTextLine}`}>
                        <a className="dark-gray light-blue-hover" href={`tel:${phone}`}>{phone}</a>
                    </p>
                </ConditionalRender>
            </div>
        </div>
    );
}

PeopleListing.propTypes = {
    link: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    additionalTitle: PropTypes.string,
    renderAdditionalTitle: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string,
    asset: PropTypes.shape(Asset.propTypes),
    renderMode: PropTypes.string,
};

PeopleListing.defaultProps = {
    link: '',
    name: '',
    position: '',
    additionalTitle: '',
    renderAdditionalTitle: false,
    email: '',
    phone: '',
    asset: null,
    renderMode: '',
};

const withRenderModePropPeopleListings = withRenderModeProp(PeopleListing);
withRenderModePropPeopleListings.propTypes = PeopleListing.propTypes;
withRenderModePropPeopleListings.defaultProps = PeopleListing.defaultProps;
export default withRenderModePropPeopleListings;
