import React from 'react';
import PropTypes from 'prop-types';
import Accordion from './Accordion.jsx';

class StatefulAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.beginOpen,
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const {
            title,
            headingId,
            children,
        } = this.props;
        const {
            isOpen,
        } = this.state;

        return (
            <Accordion
                title={title}
                headingId={headingId}
                isOpen={isOpen}
                onToggleInteract={ev => this.toggle()}
            >
                {children}
            </Accordion>
        );
    }
}

StatefulAccordion.propTypes = {
    title: PropTypes.string,
    headingId: PropTypes.string,
    beginOpen: PropTypes.bool,
    children: PropTypes.node,
};

StatefulAccordion.defaultProps = {
    title: '',
    headingId: '',
    beginOpen: false,
    children: null,
};

export default StatefulAccordion;
