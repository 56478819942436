import React from 'react';
import PropTypes from 'prop-types';
import styles from './jumplinks.scss';

function getJumpLinkClassName(isCurrent) {
    const classes = [styles.jumpLink];
    if (isCurrent) {
        classes.push(styles.isCurrent);
    }
    return classes.join(' ');
}

function JumpLinks(props) {
    return (
        <React.Fragment>
            <ul className="u-hide-in-print">
                {props.links.map((link, idx) =>
                    <li className={getJumpLinkClassName(link.isCurrent)} key={idx}><a href={`#${link.url}`}>{link.name}</a></li>)}
            </ul>
        </React.Fragment>
    );
}

JumpLinks.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        isCurrent: PropTypes.bool,
    })),
};

JumpLinks.defaultProps = {
    links: [],
};

export default JumpLinks;
