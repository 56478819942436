import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { VelocityComponent } from 'velocity-react';
import QualifiedLink from '../QualifiedLink.jsx';
import styles from './nav-page.scss';
import ConditionalRender from '../ConditionalRender.jsx';

const NO_ACTIVE_CHILD_INDEX = -1;

class NavPage extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isActive !== prevState.isActive) {
            return {
                isActive: nextProps.isActive,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            activeChildIndex: NO_ACTIVE_CHILD_INDEX,
        };
    }

    handleBackButtonClick(ev) {
        ev.preventDefault();
        this.setState({
            isActive: false,
        });
        this.props.onBackButtonClick();
    }

    unsetActiveChildIndex() {
        this.setState({
            activeChildIndex: NO_ACTIVE_CHILD_INDEX,
        });
    }

    renderBackButton() {
        const { parentPage } = this.props;
        if (!parentPage) {
            return null;
        }

        const className = [
            styles.normalFont,
            styles.backButtonWrapper,
        ].join(' ');

        return (
            <p className={className}>
                <button
                    aria-label="Back"
                    className="icon-arrow-left"
                    onClick={ev => this.handleBackButtonClick(ev)} />
            </p>
        );
    }

    renderPageTitle() {
        const { name, url } = this.props;
        if (!name) {
            return null;
        }

        const className = [
            styles.largeFont,
            styles.pageTitle,
        ].join(' ');

        return (
            <p className={className}>
                <QualifiedLink href={url}>
                    {name}
                </QualifiedLink>
            </p>
        );
    }

    renderChildPagesList() {
        const { parentPage, childPages } = this.props;

        if (!childPages.length) {
            return null;
        }

        return (
            <ul>
                {childPages.map((page, idx) => {
                    const hasChildPages = page.childPages.length > 0;
                    const onClick = hasChildPages ? (ev) => {
                        ev.preventDefault();
                        this.setState({
                            activeChildIndex: idx,
                        });
                    } : _.noop;
                    const chevron = hasChildPages
                        ? <span className={`${styles.linkChevron} icon-arrow-right`} />
                        : null;

                    const liClasses = [];
                    if (!parentPage) {
                        liClasses.push(styles.largeFont);
                    } else {
                        liClasses.push(styles.normalFont);
                    }
                    if (page.hasBottomBorder) {
                        liClasses.push(styles.withBottomBorder);
                    }
                    const liClassName = liClasses.join(' ');

                    return (
                        <li key={idx} className={liClassName}>
                            <div className="l-standard-width">
                                <QualifiedLink
                                    className={styles.link}
                                    href={page.url}
                                    onClick={onClick}>
                                    <span>{page.name}</span>
                                    {chevron}
                                </QualifiedLink>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderChildNavPages() {
        const {
            name,
            url,
            childPages,
        } = this.props;
        const { activeChildIndex } = this.state;

        if (!childPages.length) {
            return null;
        }

        const childPagesWithChildPages = childPages
            .map((page, idx) => ({ ...page, originalIdx: idx }))
            .filter(page => _.get(page, 'childPages', []).length > 0);

        const currentPage = {
            name,
            url,
            childPages,
        };

        return (
            <React.Fragment>
                {childPagesWithChildPages.map(page => (
                    <NavPage
                        key={page.originalIdx}
                        isActive={page.originalIdx === activeChildIndex}
                        onBackButtonClick={() => this.unsetActiveChildIndex()}
                        name={page.name}
                        url={page.url}
                        childPages={page.childPages}
                        parentPage={currentPage} />
                ))}
            </React.Fragment>
        );
    }

    render() {
        const { parentPage } = this.props;
        const { isActive, activeChildIndex } = this.state;
        const hasActiveChild = activeChildIndex !== NO_ACTIVE_CHILD_INDEX;
        const translateAnimation = {};
        let fadeAnimation;
        if (hasActiveChild) {
            translateAnimation.translateX = ['-100%', 0];
            fadeAnimation = 'fadeOut';
        } else if (parentPage && !isActive) {
            translateAnimation.translateX = ['100%', 0];
            fadeAnimation = 'fadeOut';
        } else {
            translateAnimation.translateX = 0;
            fadeAnimation = 'fadeIn';
        }

        const pageClasses = [styles.page];
        if (isActive) {
            pageClasses.push(styles.isActive);
        }
        const pageClassName = pageClasses.join(' ');

        return (
            <React.Fragment>
                <VelocityComponent animation={translateAnimation}>
                    <div className={pageClassName}>
                        <VelocityComponent animation={fadeAnimation}>
                            <div>
                                <ConditionalRender condition={parentPage && this.props.name && this.props.url}>
                                    <div className="l-standard-width">
                                        {this.renderBackButton()}
                                        {this.renderPageTitle()}
                                    </div>
                                </ConditionalRender>
                                {this.renderChildPagesList()}
                            </div>
                        </VelocityComponent>
                    </div>
                </VelocityComponent>
                {this.renderChildNavPages()}
            </React.Fragment>
        );
    }
}

const pagePropTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    hasBottomBorder: PropTypes.bool,
    childPages: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

NavPage.propTypes = {
    ...pagePropTypes,
    parentPage: PropTypes.shape(pagePropTypes),
    onBackButtonClick: PropTypes.func,
};

NavPage.defaultProps = {
    name: '', // eslint-disable-line react/default-props-match-prop-types
    url: '', // eslint-disable-line react/default-props-match-prop-types
    hasBottomBorder: false, // eslint-disable-line react/default-props-match-prop-types
    childPages: [], // eslint-disable-line react/default-props-match-prop-types
    parentPage: null,
    onBackButtonClick: _.noop,
};

export default NavPage;
