import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import _ from 'lodash';
import URI from 'urijs';
import request from 'superagent';
import RawMarkup from '../RawMarkup.jsx';
import styles from './autosuggest.scss';
import { AUTOCOMPLETE_ENDPOINT } from '../../../constants';

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function onlySuggestionsWithUrl(suggestion) {
    return suggestion.url;
}

class Autocomplete extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const nextValue = props.value;
        if (props.value !== state.prevPropsValue) {
            return {
                value: nextValue,
                prevPropsValue: nextValue,
            };
        }

        return {};
    }

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            prevPropsValue: props.value, // eslint-disable-line react/no-unused-state
            suggestions: [],
            lastRequestInitTime: NaN,
        };

        this.autosuggestRef = React.createRef();

        this.onSuggestionsFetchRequested = _.debounce(({ value, reason }) => {
            const { view } = this.props;
            const uri = new URI(AUTOCOMPLETE_ENDPOINT);
            uri.search({
                v: view,
                q: value,
            });
            const resultsUrl = uri.toString();

            const requestInitTime = Date.now();
            this.setState({ lastRequestInitTime: requestInitTime });

            request(resultsUrl).then((res) => {
                if (requestInitTime !== this.state.lastRequestInitTime) {
                    return;
                }
                const rawSuggestions = _.get(res, ['body', 'hits', 'ALL', 'hits'], []);

                const prefilteredSuggestions = typeof this.props.suggestionsPreFilter === 'function'
                    ? this.props.suggestionsPreFilter(rawSuggestions)
                    : rawSuggestions;

                const suggestions = typeof this.props.suggestionsFilter === 'function'
                    ? prefilteredSuggestions.filter(this.props.suggestionsFilter)
                    : prefilteredSuggestions;

                this.setState({ suggestions });
            });
        }, 200);
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        });
    }

    handleInputChange(ev, { newValue }) {
        this.setState({
            value: newValue,
        });
        this.props.onChange(ev, { newValue });
    }

    renderSuggestion(suggestion, { query, isHighlighted }) {
        const { suggestionExtraClasses } = this.props;
        const classes = [
            styles.suggestionInner,
            ...suggestionExtraClasses,
        ];
        if (isHighlighted) {
            classes.push(styles.isHighlighted);
        }
        const className = classes.join(' ');

        const suggestionWithMatchHighlighted = _.get(suggestion, 'name', '').replace(new RegExp(_.escapeRegExp(query), 'gi'), `<span class="${styles.highlight}">$&</span>`);

        return (
            <div className={className}>
                <RawMarkup>{suggestionWithMatchHighlighted}</RawMarkup>
            </div>
        );
    }

    render() {
        const {
            placeholder,
            id,
            name,
            inputClasses,
            theme,
        } = this.props;

        const {
            value,
            suggestions,
        } = this.state;

        const inputClassName = inputClasses.join(' ');

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={valueAndReason => this.onSuggestionsFetchRequested(valueAndReason)}
                onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                onSuggestionSelected={this.props.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={(suggestion, { query, isHighlighted }) => this.renderSuggestion(suggestion, { query, isHighlighted })}
                ref={this.autosuggestRef}
                theme={theme}
                inputProps={{
                    value,
                    onChange: (ev, { newValue }) => this.handleInputChange(ev, { newValue }),
                    ...(id ? { id } : {}),
                    name,
                    placeholder,
                    type: 'text',
                    className: inputClassName,
                }}
            />
        );
    }
}

Autocomplete.propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    inputClasses: PropTypes.arrayOf(PropTypes.string),
    suggestionExtraClasses: PropTypes.arrayOf(PropTypes.string),
    suggestionsFilter: PropTypes.func,
    suggestionsPreFilter: PropTypes.func,
    view: PropTypes.string.isRequired,
    onSuggestionSelected: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    theme: PropTypes.objectOf(PropTypes.string),
};

Autocomplete.defaultProps = {
    placeholder: '',
    id: '',
    inputClasses: [],
    suggestionExtraClasses: [],
    suggestionsFilter: onlySuggestionsWithUrl,
    suggestionsPreFilter: null,
    onSuggestionSelected: _.noop,
    onChange: _.noop,
    value: '',
    theme: {},
};

export default Autocomplete;
