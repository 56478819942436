import React from 'react';
import get from 'lodash/get';
import PlainDropdown from '../Forms/PlainDropdown.jsx';

function asStringId(id) {
    if (typeof id === 'string') {
        return id;
    }
    if (typeof id === 'number') {
        return id.toString();
    }
    return '';
}

// Show or hide child practices in submenus based on selected option
function withFilteredDropdownOptions(Component) {
    class ComponentWithFilteredDropdownOptions extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                childVisibilitiesByParentId: {},
            };
        }

        getActiveParentId() {
            const activeValue = get(this.props, 'value', {});

            if (activeValue.isChild) {
                return activeValue.sitemap_parent_id;
            }
            return activeValue.sitemap_id;
        }

        handleNestedMenuIndicatorClick(ev, { parentId }) {
            ev.preventDefault();
            ev.stopPropagation();

            const { childVisibilitiesByParentId } = this.state;

            const parentId_asStringId = asStringId(parentId);

            if (!parentId_asStringId) {
                return;
            }

            this.setState({
                childVisibilitiesByParentId: {
                    ...childVisibilitiesByParentId,
                    [parentId_asStringId]: !childVisibilitiesByParentId[parentId_asStringId],
                },
            });
        }

        render() {
            const activeParentId = asStringId(this.getActiveParentId());
            const allOptions = this.props.options;

            const optionsToShow = allOptions.filter((option) => {
                // Always show the parents
                if (!option.isChild) {
                    return true;
                }

                const parentId = asStringId(option.sitemap_parent_id);
                if (!parentId) {
                    return false;
                }

                if (parentId === activeParentId) {
                    return true;
                }

                const isChildOfParentWithVisibleChildren = Boolean(this.state.childVisibilitiesByParentId[parentId]);

                return isChildOfParentWithVisibleChildren;
            });

            const optionsToShow_withOptionDisplayProps = optionsToShow.map((option) => {
                const isChild = Boolean(option.isChild);
                const id = asStringId(option.sitemap_id);
                const hasVisibleChildren = (id === activeParentId) || this.state.childVisibilitiesByParentId[id];

                return {
                    ...option,
                    isIndented: isChild,
                    showChildIndicator: !(id === activeParentId) && !isChild && option.hasChildren,
                    hasVisibleChildren,
                };
            });

            return (
                <Component
                    {...this.props}
                    options={optionsToShow_withOptionDisplayProps}
                    onNestedMenuIndicatorClick={(ev, params) => this.handleNestedMenuIndicatorClick(ev, params)}
                />
            );
        }
    }

    ComponentWithFilteredDropdownOptions.propTypes = {
        ...PlainDropdown.propTypes,
    };

    ComponentWithFilteredDropdownOptions.defaultProps = {
        ...PlainDropdown.defaultProps,
    };

    return ComponentWithFilteredDropdownOptions;
}

export default withFilteredDropdownOptions;
