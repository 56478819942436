import React from 'react';
import PropTypes from 'prop-types';
import styles from './forms.scss';

function Form(props) {
    const classes = [];
    if (props.className && typeof props.className === 'string') {
        classes.push(props.className);
    }
    classes.push(styles.searchForm);
    const className = classes.join(' ');

    return (
        <form {...props} className={className}>
            {props.children}
        </form>
    );
}

Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

Form.defaultProps = {
    className: '',
    children: null,
};

export default Form;
