import { isiOS } from './utils/document';

function getResultsArea() {
    return document.querySelector('.js-search-results-area');
}

function pageHasActiveDatepicker() {
    const activeElement = document.activeElement;
    if (activeElement && activeElement.tagName === 'INPUT' && activeElement.type === 'date') {
        return true;
    }
    return false;
}

function getScrollTarget() {
    const resultsArea = getResultsArea();
    const activeElement = document.activeElement;
    if (isiOS() && pageHasActiveDatepicker()) {
        const datepickerTop = activeElement.getBoundingClientRect().top;
        const resultsAreaTop = resultsArea.getBoundingClientRect().top;
        if (datepickerTop < resultsAreaTop) {
            return activeElement;
        }
    }
    return resultsArea;
}

const scrollOnReceiveResultsMiddleware = store => next => (action) => {
    if (action.type === '@SEARCH/RECEIVE_RESULTS') {
        import('velocity-animate').then((velocityModule) => {
            const resultsArea = document.querySelector('.js-search-results-area');
            const offset = parseInt(resultsArea.dataset.scrollOffset, 10) || 0;
            const scrollTarget = getScrollTarget();
            const Velocity = velocityModule.default;
            Velocity(scrollTarget, 'scroll', { offset });
        });
    }
    next(action);
};

export default scrollOnReceiveResultsMiddleware;
