import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../../hocs';
import { IMAGE } from './constants';

function PictureSource({
    typeCategory,
    url,
    media,
    extraProps,
}) {
    if (typeCategory !== IMAGE) {
        return null;
    }

    return (
        <source
            {...extraProps}
            srcSet={url}
            media={media} />
    );
}

PictureSource.propTypes = {
    typeCategory: PropTypes.string,
    url: PropTypes.string,
    media: PropTypes.string,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PictureSource.defaultProps = {
    typeCategory: '',
    url: '',
    media: '',
    extraProps: {},
};

export default withUrlsQualified(PictureSource, ['url']);
