import PropTypes from 'prop-types';

const GenericAssetSpecification = {
    typeCategory: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    media: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    extraProps: PropTypes.object,
};

export default GenericAssetSpecification;
