import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './forms.scss';
import { formElementColors } from '../../../constants';

function setInputClass(color) {
    const classes = [
        styles.formInput,
        styles['formColor' + color],
    ];

    return classes.join('  ');
}

function Input({
    color, placeholder, id, additionalClasses, name, hasErrors,
}) {
    const colorClassName = setInputClass(color);
    const additionalClassNameString = additionalClasses.join(' ');
    const errorClass = hasErrors ? styles.hasError : '';
    return (
        <input className={classNames(colorClassName, additionalClassNameString, styles.gatedContentInput, errorClass)} type="text" placeholder={placeholder} id={id} name={name} />
    );
}

Input.propTypes = {
    color: PropTypes.oneOf(formElementColors),
    placeholder: PropTypes.string,
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    name: PropTypes.string,
    hasErrors: PropTypes.bool,
};

Input.defaultProps = {
    color: '',
    placeholder: '',
    id: '',
    name: '',
    additionalClasses: [],
    hasErrors: false,
};

export default Input;
