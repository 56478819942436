import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';
import styles from './footer.scss';

function getContainerClassName() {
    const classes = [
        styles.nav,
        'dark-gray',
        'type-paragraph-small',
    ];

    return classes.join(' ');
}

function SecondaryLinks({ links, current_year, copyright_text }) {
    if (links.length === 0 && !current_year && !copyright_text) {
        return null;
    }

    return (
        <ul className={getContainerClassName()}>
            {links.map((link, idx) => (
                <WindowContext.Consumer key={idx}>
                    {window => (
                        <li className={`${styles.navItem} u-hide-in-print`}>
                            <a href={qualifyUrlInWindow(link.link, window)}>{link.text}</a>
                        </li>
                    )}
                </WindowContext.Consumer>
            ))}
            <li className={`${styles.navItem} pdf-display-none`} key="copyright">
                <span>
                    &copy;{current_year} {copyright_text}
                </span>
            </li>
        </ul>
    );
}

SecondaryLinks.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
    })),
    current_year: PropTypes.number.isRequired,
    copyright_text: PropTypes.string,
};

SecondaryLinks.defaultProps = {
    links: [],
    copyright_text: '',
};

export default SecondaryLinks;
