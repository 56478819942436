import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VelocityComponent } from 'velocity-react';
import ShareOption from './ShareOption.jsx';
import styles from './share-nav.scss';


class ShareNav extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    render() {
        const {
            isOpen,
            shareOptions,
            leftAlignShareTooltip,
        } = this.props;

        const containerClassNames = classnames(styles.shareOptions, 'bg-white', 'u-rounded-border-5', {
            [styles.shareOffset]: leftAlignShareTooltip,
        });

        const headingClassName = [
            styles.header,
            'type-small-caps',
            'dark-blue',
            'u-uppercase',
        ].join(' ');

        return (
            <VelocityComponent animation={isOpen ? 'fadeIn' : 'fadeOut'}>
                {/*
                    This div must be styled inline: https://stackoverflow.com/questions/20356311/internet-explorer-11-ignores-list-stylenone-on-the-first-load
                    Velocity will modify the style as needed after the initial render.
                */}
                <div
                    ref={this.wrapperRef}
                    className={containerClassNames}
                    style={{ display: 'none' }}>
                    <p className={headingClassName}>
                        Share
                    </p>
                    <ul className={styles.share}>
                        {shareOptions.map((shareOption, idx) => (
                            <li className={styles.shareOption} key={idx}>
                                <ShareOption {...shareOption} />
                            </li>
                        ))}
                    </ul>
                </div>
            </VelocityComponent>
        );
    }
}

ShareNav.propTypes = {
    isOpen: PropTypes.bool,
    shareOptions: PropTypes.arrayOf(PropTypes.shape(ShareOption.propTypes)),
    leftAlignShareTooltip: PropTypes.bool,
};

ShareNav.defaultProps = {
    shareOptions: [],
    isOpen: false,
    leftAlignShareTooltip: false,
};

export default ShareNav;
