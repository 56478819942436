import React from 'react';
import PropTypes from 'prop-types';
import PrimaryLinks from './PrimaryLinks.jsx';
import SocialLinks from './SocialLinks.jsx';
import SecondaryLinks from './SecondaryLinks.jsx';
import stickyFooterStyles from '../../../scss/modules/sticky-footer.scss';
import ConditionalRender from '../../elements/ConditionalRender.jsx';
import SignupButton from '../SignupButton.jsx';
import { valueHasData } from '../../../utils/data';

function getSocialLinkItems({
    facebook_link,
    google_plus_link,
    instagram_link,
    rss_link,
    twitter_link,
    linkedin_link,
    youtube_link,
}) {
    const socialLinkItems = [];

    if (linkedin_link) {
        socialLinkItems.push({
            link: linkedin_link,
            text: 'LinkedIn',
            iconClass: 'icon-linkedin',
        });
    }

    if (twitter_link) {
        socialLinkItems.push({
            link: twitter_link,
            text: 'Twitter',
            iconClass: 'icon-twitter2',
        });
    }

    if (facebook_link) {
        socialLinkItems.push({
            link: facebook_link,
            text: 'Facebook',
            iconClass: 'icon-facebook',
        });
    }

    if (youtube_link) {
        socialLinkItems.push({
            link: youtube_link,
            text: 'YouTube',
            iconClass: 'icon-youtube',
        });
    }

    if (instagram_link) {
        socialLinkItems.push({
            link: instagram_link,
            text: 'Instagram',
            iconClass: 'icon-instagram',
        });
    }

    if (google_plus_link) {
        socialLinkItems.push({
            link: google_plus_link,
            text: 'Google Plus',
            iconClass: 'icon-google-plus',
        });
    }

    if (rss_link) {
        socialLinkItems.push({
            link: rss_link,
            text: 'RSS',
            iconClass: 'icon-feed2',
        });
    }

    return socialLinkItems;
}

function Footer(props) {
    const {
        copyright,
        current_year,
        facebook_link,
        google_plus_link,
        instagram_link,
        rss_link,
        twitter_link,
        linkedin_link,
        primary_links,
        secondary_footer_links,
        signup,
        youtube_link,
    } = props.footerProps;

    const socialLinks = {
        facebook_link,
        google_plus_link,
        instagram_link,
        rss_link,
        twitter_link,
        linkedin_link,
        youtube_link,
    };
    const socialLinkItems = getSocialLinkItems(socialLinks);

    const {
        isHomepage,
    } = props;

    const footerClasses = [
        stickyFooterStyles.footer,
        'pdf-display-none',
    ];

    if (isHomepage) {
        footerClasses.push('bg-white');
    }

    return (
        <footer className={footerClasses.join(' ')}>
            <ConditionalRender condition={valueHasData(signup) && !isHomepage}>
                <SignupButton {...signup} />
            </ConditionalRender>

            <div className="l-standard-width u-padding-large bg-white">
                <PrimaryLinks links={primary_links} />
                <SocialLinks links={socialLinkItems} />
                <SecondaryLinks
                    links={secondary_footer_links}
                    copyright_text={copyright}
                    current_year={current_year}
                />
            </div>
        </footer>

    );
}

Footer.propTypes = {
    copyright: PropTypes.string,
    current_year: PropTypes.number.isRequired,
    footerProps: PropTypes.shape({
        copyright: PropTypes.string,
        current_year: PropTypes.string,
        facebook_link: PropTypes.string,
        google_plus_link: PropTypes.string,
        instagram_link: PropTypes.string,
        rss_link: PropTypes.string,
        twitter_link: PropTypes.string,
        linkedin_link: PropTypes.string,
        primary_links: PropTypes.string,
        secondary_footer_links: PropTypes.string,
        signup: PropTypes.string,
        youtube_link: PropTypes.string,
    }),
    primary_links: PrimaryLinks.propTypes.links, // eslint-disable-line react/no-typos
    secondary_footer_links: SecondaryLinks.propTypes.links, // eslint-disable-line react/no-typos
    facebook_link: PropTypes.string,
    google_plus_link: PropTypes.string,
    instagram_link: PropTypes.string,
    isHomepage: PropTypes.bool,
    rss_link: PropTypes.string,
    twitter_link: PropTypes.string,
    linkedin_link: PropTypes.string,
    youtube_link: PropTypes.string,
    signup: PropTypes.shape(SignupButton.propTypes),
};

Footer.defaultProps = {
    copyright: '',
    primary_links: [],
    secondary_footer_links: [],
    facebook_link: '',
    google_plus_link: '',
    instagram_link: '',
    rss_link: '',
    twitter_link: '',
    linkedin_link: '',
    youtube_link: '',
    signup: {},
};

export default Footer;
