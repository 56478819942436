import React from 'react';
import PropTypes from 'prop-types';
import Asset from './Asset/index.jsx';
import styles from './jumbotron.scss';

function Jumbotron({
    asset,
    text,
}) {
    return (
        <div className={styles.container}>
            <Asset asset={asset} data-object-fit="cover" className={styles.asset} />
            <h2 className={`${styles.text}  white`}>{text}</h2>
        </div>
    );
}

Jumbotron.propTypes = {
    asset: Asset.propTypes.asset,
    text: PropTypes.string,
};

Jumbotron.defaultProps = {
    asset: Asset.defaultProps.asset,
    text: '',
};

export default Jumbotron;
