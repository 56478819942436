import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Video from './Video.jsx';
import Audio from './Audio.jsx';
import PlyrIframe from './PlyrIframe.jsx';
import GenericAssetSpecification from './GenericAssetSpecification';
import {
    VIDEO,
    AUDIO,
    TYPE_VIMEO,
    TYPE_YOUTUBE,
} from './constants';
import './plyr-styles.scss';

function getMediaComponentForTypeAndTypeCategory(type, typeCategory) {
    if (type === TYPE_VIMEO || type === TYPE_YOUTUBE) {
        return PlyrIframe;
    }
    if (typeCategory === VIDEO) {
        return Video;
    }
    if (typeCategory === AUDIO) {
        return Audio;
    }
    return null;
}

class PlyrAsset extends React.Component {
    constructor(props) {
        super(props);
        this.player = null;
        this.mediaRef = React.createRef();
        this.state = {
            startedPlaying: false,
        };
    }

    initPlyrMediaWithPlyrConstructor(Plyr) { // eslint-disable-line react/sort-comp
        const {
            primaryAsset,
            plyrOptions,
            plyrEvents,
        } = this.props;
        const node = ReactDOM.findDOMNode(this.mediaRef.current); // eslint-disable-line react/no-find-dom-node

        this.player = new Plyr(node, plyrOptions);

        if (this.player) {
            const video_url = _.get(primaryAsset, 'url');

            this.player.on('ready', () => {
                const onReady = _.get(plyrEvents, 'onReady', _.noop);
                onReady();
            });

            // First play and subsequent plays (e.g. after pause)
            this.player.on('playing', () => {
                const interaction = this.state.startedPlaying ? 'Resume' : 'Play';
                if (this.canCollectAnalytics()) {
                    window.dataLayer.push({
                        event: 'fe_video',
                        interaction,
                        video_url,
                    });
                }

                const onPlaying = _.get(plyrEvents, 'onPlaying', _.noop);

                onPlaying();

                this.setState({
                    startedPlaying: true,
                });
            });

            this.player.on('play', () => {
                const onPlay = _.get(plyrEvents, 'onPlay', _.noop);
                onPlay();
            });

            this.player.on('pause', () => {
                if (this.canCollectAnalytics()) {
                    window.dataLayer.push({
                        event: 'fe_video',
                        interaction: 'Pause',
                        video_url,
                    });
                }

                const onPause = _.get(plyrEvents, 'onPause', _.noop);
                onPause();
            });

            this.player.on('ended', () => {
                if (this.canCollectAnalytics()) {
                    window.dataLayer.push({
                        event: 'fe_video',
                        interaction: 'Complete',
                        video_url,
                    });
                }

                const onEnd = _.get(plyrEvents, 'onEnd', _.noop);
                onEnd();

                this.setState({
                    startedPlaying: false,
                });
            });

            this.player.on('loadeddata', () => {
                const onLoadedData = _.get(plyrEvents, 'onLoadedData', _.noop);
                onLoadedData();
            });

            this.player.on('seeked', (ev) => {
                const time = this.player.currentTime;
                const onSeeked = _.get(plyrEvents, 'onSeeked', _.noop);
                onSeeked(time);
            });

            this.player.on('enterfullscreen', () => {
                const onEnterFullscreen = _.get(plyrEvents, 'onEnterFullscreen', _.noop);
                onEnterFullscreen();
            });

            this.player.on('exitfullscreen', () => {
                const onExitFullscreen = _.get(plyrEvents, 'onExitFullscreen', _.noop);
                onExitFullscreen();
            });

            this.player.on('volumechange', (ev) => {
                const { muted: isMuted, volume } = this.player;
                const onVolumeChange = _.get(plyrEvents, 'onVolumeChange', _.noop);
                onVolumeChange({ isMuted, volume });
            });
        }
    }

    componentDidMount() {
        import('plyr/dist/plyr.polyfilled').then((plyrModule) => {
            const Plyr = plyrModule.default;
            this.initPlyrMediaWithPlyrConstructor(Plyr);
        });
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.destroy();
        }
    }

    getCurrentTime() {
        return _.get(this.player, ['currentTime']);
    }

    getDuration() {
        return _.get(this.player, ['duration']);
    }

    getType() {
        return _.get(this.player, ['source', 'type']);
    }

    getVolume() {
        return _.get(this.player, ['volume']);
    }

    canCollectAnalytics() {
        return this.props.collectAnalytics && typeof window.dataLayer !== 'undefined';
    }

    isMuted() {
        return _.get(this.player, ['muted']);
    }

    isPaused() {
        return _.get(this.player, ['paused']);
    }

    play() {
        return this.player && this.player.play();
    }

    pause() {
        return this.player && this.player.pause();
    }

    restart() {
        return this.player && this.player.restart();
    }

    stop() {
        return this.player && this.player.stop();
    }

    togglePlay() {
        return this.player && this.player.togglePlay();
    }

    toggleMute() {
        return this.player && this.player.toggleControls(this.player.muted);
    }

    render() {
        const {
            primaryAsset,
            secondaryAssets,
        } = this.props;

        if (!primaryAsset) {
            return null;
        }

        const {
            type,
            typeCategory,
        } = primaryAsset;

        const MediaComponent = getMediaComponentForTypeAndTypeCategory(type, typeCategory);

        return (
            <MediaComponent
                ref={this.mediaRef}
                primaryAsset={primaryAsset}
                secondaryAssets={secondaryAssets} />
        );
    }
}

PlyrAsset.propTypes = {
    primaryAsset: PropTypes.shape(GenericAssetSpecification),
    secondaryAssets: PropTypes.arrayOf(PropTypes.shape(GenericAssetSpecification)),
    plyrOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    plyrEvents: PropTypes.shape({
        onReady: PropTypes.func,
        onPlaying: PropTypes.func,
        onPlay: PropTypes.func,
        onPause: PropTypes.func,
        onEnd: PropTypes.func,
        onLoadedData: PropTypes.func,
        onSeeked: PropTypes.func,
        onEnterFullscreen: PropTypes.func,
        onExitFullscreen: PropTypes.func,
        onVolumeChange: PropTypes.func,
    }),
    collectAnalytics: PropTypes.bool,
};

PlyrAsset.defaultProps = {
    primaryAsset: null,
    secondaryAssets: [],
    plyrOptions: {},
    plyrEvents: {},
    collectAnalytics: true,
};

export default PlyrAsset;
