import React from 'react';
import PropTypes from 'prop-types';
import styles from './listings.scss';
import MaybeLink from '../MaybeLink.jsx';
import RawMarkup from '../RawMarkup.jsx';
import {
    colorMap,
    PDF_RENDER_MODE,
} from '../../../constants';

function getContainerClass(layoutClass, idx) {
    const listingColor = colorMap[idx % 6];

    const classes = [
        styles.listing,
        styles[listingColor],
    ];

    if (layoutClass) {
        classes.push(layoutClass);
    }

    return classes.join('  ');
}

function Listing({
    title_html,
    url,
    eyebrow_primary,
    eyebrow_secondary,
    layoutClass,
    idx,
    renderMode,
}) {
    const eyebrowColor = renderMode === PDF_RENDER_MODE ? 'light-blue' : 'dark-gray';
    const containerPaddingClass = renderMode === PDF_RENDER_MODE ? 'u-margin-top-xsmall' : 'u-padding-med';

    return (
        <MaybeLink href={url} className={getContainerClass(layoutClass, idx)} fallbackTag="div">
            <div className={`${containerPaddingClass} u-remove-print-horizontal-spacing u-page-break-inside-avoid`}>
                <div className={styles.eyebrowArea}>
                    <p className={`${styles.eyebrowPrimary} type-category-header  ${eyebrowColor}`}>{eyebrow_primary}</p>
                    <p className={`${styles.eyebrowSecondary} type-small-paragraph  ${eyebrowColor}`}>{eyebrow_secondary}</p>
                </div>

                <div className={styles.titleArea}>
                    <p className={`type-module-title ${renderMode === PDF_RENDER_MODE ? 'light-blue' : 'dark-blue'}`}>
                        <RawMarkup>{title_html}</RawMarkup>
                    </p>
                </div>
            </div>
        </MaybeLink>
    );
}

Listing.propTypes = {
    title_html: PropTypes.string,
    url: PropTypes.string,
    eyebrow_primary: PropTypes.string,
    eyebrow_secondary: PropTypes.string,
    layoutClass: PropTypes.string,
    idx: PropTypes.number,
    renderMode: PropTypes.string,
};

Listing.defaultProps = {
    title_html: '',
    url: '',
    eyebrow_primary: '',
    eyebrow_secondary: '',
    layoutClass: '',
    idx: '',
    renderMode: '',
};

export default Listing;
