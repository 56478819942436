import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import styles from "./styles.scss";

function CloseButton(props) {
  //prettier-ignore
  const {
    onClick,
    label = "Close",
    className
  } = props;
  return (
    <button
      aria-label={label}
      onClick={onClick}
      className={classNames(styles.button, className)}
    >
      <span className={classNames("m-type__body-bold", styles.label)}>
        {label}
      </span>
      <span className={classNames("icon", "icon-close", styles.closeIcon)} />
    </button>
  );
}

function Modal(props) {
  //prettier-ignore
  const {
    children,
    className,
    closeTimeoutMS = 500,
    handleCloseModal = _.noop,
    handleOnAfterOpen = _.noop,
    isModalOpen,
    styleProps = {},
    closeButtonProps = {},
  } = props;

  return (
    <ReactModal
      isOpen={isModalOpen}
      overlayClassName={styles.overlay}
      className={classNames(styles.container, className)}
      onAfterOpen={handleOnAfterOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      closeTimeoutMS={closeTimeoutMS}
      style={styleProps}
    >
      <CloseButton onClick={handleCloseModal} {...closeButtonProps} />
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeTimeoutMS: PropTypes.number,
  handleCloseModal: PropTypes.func,
  handleOnAfterOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  styleProps: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default Modal;
