import React from 'react';
import PropTypes from 'prop-types';
import { withUrlsQualified } from '../../hocs';
import { GENERIC } from './constants';

function VideoCaptions({
    typeCategory,
    url,
    alt,
    extraProps,
}) {
    if (typeCategory !== GENERIC) {
        return null;
    }

    return (
        <track
            srcLang="en"
            default
            {...extraProps}
            kind="captions"
            src={url}
            label={alt || 'Video captions'} />
    );
}

VideoCaptions.propTypes = {
    typeCategory: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

VideoCaptions.defaultProps = {
    typeCategory: '',
    url: '',
    alt: 'Closed captions',
    extraProps: {},
};

export default withUrlsQualified(VideoCaptions, ['url']);
