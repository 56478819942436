import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";


import CallToAction, { BUTTON_STYLE_THIN, COLOR_NAME_WHITE } from "../elements/CallToAction";
import { withRenderModeProp } from "../../../hocs";
import { PDF_RENDER_MODE } from "../../../../constants";
import styles from "./styles.scss";

export const MAX_WIDTH_1440 = "maxWidth1440";
export const FULL_BLEED = "fullbleed";
function Banner(props) {
  const {
    bannerStyle,
    className,
    headingPartOne,
    headingPartTwo,
    content,
    cta,
    renderMode,
  } = props;

  return (
    <div
      className={classNames(
        styles.container,
        renderMode === PDF_RENDER_MODE && styles.isPdf,
        bannerStyle === MAX_WIDTH_1440 &&
          "l-standard-width l-standard-width--no-padding",
        className,
      )}
    >
      <div className={styles.textContainer}>
        <p className={styles.heading}>
          <span className="m-type__h3">{headingPartOne}</span>
          <span className="m-type__h3-bold">{headingPartTwo}</span>
        </p>
        <p className={classNames("m-type__body-bold", styles.content)}>
          {content}
        </p>
      </div>
      <CallToAction
        icon="arrow-right"
        label={cta.text}
        ariaLabel={cta.text}
        url={cta.url}
        textColor={COLOR_NAME_WHITE}
        iconBackgroundColor={COLOR_NAME_WHITE}
        buttonStyle={BUTTON_STYLE_THIN}
      />
    </div>
  );
}

Banner.propTypes = {
  bannerStyle: PropTypes.oneOf([MAX_WIDTH_1440, FULL_BLEED]),
  className: PropTypes.string,
  content: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  headingPartOne: PropTypes.string,
  headingPartTwo: PropTypes.string,
  renderMode: PropTypes.string,
};

export default withRenderModeProp(Banner);
