import React from 'react';
import PropTypes from 'prop-types';
import { VelocityComponent } from 'velocity-react';
import styles from './nav-takeover.scss';

const DURATION = 500;

function TakeoverWrapper({ isOpen, children }) {
    const forwardTranslateAnimation = {
        translateX: (isOpen ? [0, '-100%'] : ['-100%', 0]),
    };

    const reverseTranslateAnimation = {
        translateX: (isOpen ? ['-100%', 0] : [0, '-100%']),
    };

    return (
        <VelocityComponent
            animation={isOpen ? 'fadeIn' : 'fadeOut'}
            duration={DURATION}>
            <div className={styles.takeover}>
                <div className={styles.forwardSkew}>
                    <VelocityComponent
                        animation={forwardTranslateAnimation}
                        duration={DURATION}>
                        <div className={styles.forwardTranslate}>
                            <div className={styles.reverseSkew}>
                                <VelocityComponent
                                    animation={reverseTranslateAnimation}
                                    duration={DURATION}>
                                    <div className={styles.reverseTranslate}>
                                        <div className={styles.downsize}>
                                            {children}
                                        </div>
                                    </div>
                                </VelocityComponent>
                            </div>
                        </div>
                    </VelocityComponent>
                </div>
            </div>
        </VelocityComponent>
    );
}

TakeoverWrapper.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node,
};

TakeoverWrapper.defaultProps = {
    isOpen: false,
    children: null,
};

export default TakeoverWrapper;
