import React from 'react';
import PropTypes from 'prop-types';
import PlainSignupButton from './PlainSignupButton.jsx';

function SignupButtonWithText(props) {
    const nativeProps = {
        target: '_blank',
    };

    const { content, url } = props;

    if (url.slice(0, 4) !== 'http') {
        nativeProps.target = '_self';
    }

    return (
        <div>
            {content &&
                <div className="dark-blue u-padding-top-med u-text-center">
                    {content}
                </div>
            }
            <PlainSignupButton
                containerClasses={[
                    'l-standard-width',
                    'bg-off-white',
                    'u-padding-med',
                    props.extraContainerClass,
                ]}
                nativeProps={nativeProps}
                {...props}
            />
        </div>
    );
}

SignupButtonWithText.propTypes = {
    content: PropTypes.string,
    url: PropTypes.string,
    extraContainerClass: PropTypes.string,
};

SignupButtonWithText.defaultProps = {
    extraContainerClass: '',
};

export default SignupButtonWithText;
