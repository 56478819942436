import {
    LAYOUT_MOBILE,
    LAYOUT_TABLET,
    LAYOUT_SMALL_DESKTOP,
    LAYOUT_MEDIUM_DESKTOP,
    LAYOUT_LARGE_DESKTOP,
    BREAKPOINT_TABLET,
    BREAKPOINT_SMALL_DESKTOP,
    BREAKPOINT_MEDIUM_DESKTOP,
    BREAKPOINT_LARGE_DESKTOP,
} from '../constants';

export function getLayoutForWindowWidth(windowWidth) {
    if (windowWidth >= BREAKPOINT_LARGE_DESKTOP) {
        return LAYOUT_LARGE_DESKTOP;
    }
    if (windowWidth >= BREAKPOINT_MEDIUM_DESKTOP) {
        return LAYOUT_MEDIUM_DESKTOP;
    }
    if (windowWidth >= BREAKPOINT_SMALL_DESKTOP) {
        return LAYOUT_SMALL_DESKTOP;
    }
    if (windowWidth >= BREAKPOINT_TABLET) {
        return LAYOUT_TABLET;
    }
    return LAYOUT_MOBILE;
}

export function isMobileOrTablet(layout) {
    return layout === LAYOUT_MOBILE || layout === LAYOUT_TABLET;
} // isMobileOrTablet

export function isiOS() {
    // relies on global Modernizr
    return window.Modernizr.ios;
}
