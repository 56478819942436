import {
    LAYOUT_MOBILE,
    LAYOUT_TABLET,
    LAYOUT_SMALL_DESKTOP,
    LAYOUT_MEDIUM_DESKTOP,
    LAYOUT_LARGE_DESKTOP,
    ACTION_NOTHING,
} from './constants';

const ACTION_HAS_ENTERED_LAYOUT_MOBILE = 'GLOBAL/HAS_ENTERED_LAYOUT_MOBILE';
const ACTION_HAS_ENTERED_LAYOUT_TABLET = 'GLOBAL/HAS_ENTERED_LAYOUT_TABLET';
const ACTION_HAS_ENTERED_LAYOUT_SMALL_DESKTOP = 'GLOBAL/HAS_ENTERED_LAYOUT_SMALL_DESKTOP';
const ACTION_HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP = 'GLOBAL/HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP';
const ACTION_HAS_ENTERED_LAYOUT_LARGE_DESKTOP = 'GLOBAL/HAS_ENTERED_LAYOUT_LARGE_DESKTOP';

function getLayoutActionTypeForLayout(layout) {
    switch (layout) {
        case LAYOUT_MOBILE:
            return ACTION_HAS_ENTERED_LAYOUT_MOBILE;
        case LAYOUT_TABLET:
            return ACTION_HAS_ENTERED_LAYOUT_TABLET;
        case LAYOUT_SMALL_DESKTOP:
            return ACTION_HAS_ENTERED_LAYOUT_SMALL_DESKTOP;
        case LAYOUT_MEDIUM_DESKTOP:
            return ACTION_HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP;
        case LAYOUT_LARGE_DESKTOP:
            return ACTION_HAS_ENTERED_LAYOUT_LARGE_DESKTOP;
        default:
            return ACTION_NOTHING;
    }
}

function enteredLayout(layout) {
    const type = getLayoutActionTypeForLayout(layout);
    return { type };
}

export {
    enteredLayout,
    ACTION_HAS_ENTERED_LAYOUT_MOBILE,
    ACTION_HAS_ENTERED_LAYOUT_TABLET,
    ACTION_HAS_ENTERED_LAYOUT_SMALL_DESKTOP,
    ACTION_HAS_ENTERED_LAYOUT_MEDIUM_DESKTOP,
    ACTION_HAS_ENTERED_LAYOUT_LARGE_DESKTOP,
};
