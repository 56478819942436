import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import _ from 'lodash';
import ReactModal from 'react-modal';
import smartquotes from 'smartquotes';
import CookieHandler from '@rubyapps-mvc/cookie-handler';
import createReduxStoreWithOptions from '../../store';
import WindowContext from '../context/WindowContext';
import RenderModeContext from '../context/RenderModeContext';
import CookieHandlerContext from '../context/CookieHandlerContext';
import DefaultLayout from './default.jsx';
import { enteredLayout } from '../../globalActions';
import { getLayoutForWindowWidth } from '../../utils/document';

function bindGlobalResizeHandlers(store, window) {
    const refreshLayoutState = () => {
        const windowWidth = window.innerWidth;
        const { layout: prevLayout } = store.getState();
        const currLayout = getLayoutForWindowWidth(windowWidth);
        if (currLayout !== prevLayout) {
            store.dispatch(enteredLayout(currLayout));
        }
    };

    window.addEventListener('resize', (ev) => {
        refreshLayoutState();
    });

    refreshLayoutState();
}

class TemplateBase extends React.Component {
    constructor(props) {
        super(props);
        const { window } = props;
        this.cookieHandler = new CookieHandler();

        this.store = createReduxStoreWithOptions({ props, window, cookieHandler: this.cookieHandler });
    }

    componentDidMount() {
        const { props, store } = this;
        const { window } = props;
        bindGlobalResizeHandlers(store, window);
        store.dispatch(enteredLayout(getLayoutForWindowWidth(window.innerWidth)));
        smartquotes().listen();
        ReactModal.setAppElement('body');
    }

    render() {
        const { props, store, cookieHandler } = this;
        const defaultLayoutProps = _.pick(props, Object.keys(DefaultLayout.propTypes));

        return (
            <WindowContext.Provider value={props.window}>
                <CookieHandlerContext.Provider value={cookieHandler}>
                    <RenderModeContext.Provider value={props.renderMode}>
                        <Provider store={store}>
                            <DefaultLayout {...defaultLayoutProps}>
                                {props.children}
                            </DefaultLayout>
                        </Provider>
                    </RenderModeContext.Provider>
                </CookieHandlerContext.Provider>
            </WindowContext.Provider>
        );
    }
}

TemplateBase.propTypes = {
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    renderMode: PropTypes.string,
    children: PropTypes.node,
};

TemplateBase.defaultProps = {
    renderMode: '',
    children: null,
};

export default TemplateBase;

export function withDefaultLayoutAndGlobals(TemplateComponent) {
    return function TemplateWithDefaultLayoutAndGlobals(props) {
        return (
            <TemplateBase {...props}>
                <TemplateComponent {...props} />
            </TemplateBase>
        );
    };
}
