import React from 'react';
import CookieHandlerContext from '../../context/CookieHandlerContext';

import CookieHandlerOverlay from './layout';

function CookieHandler(props) {
    return (
        <CookieHandlerContext.Consumer>
            {cookieHandler => <CookieHandlerOverlay cookieHandler={cookieHandler} {...props} />}
        </CookieHandlerContext.Consumer>
    );
}

export default CookieHandler;
