import React from 'react';
import PropTypes from 'prop-types';
import RawMarkup from '../RawMarkup.jsx';
import ViewMore from '../ViewMore.jsx';
import ConditionalRender from '../ConditionalRender.jsx';

function Content({ content, read_more, extraClasses }) {
    return (
      <React.Fragment>
          <div className={`rte ${extraClasses.join(' ')}`}>
              <RawMarkup>
                  {content}
              </RawMarkup>
          </div>
          <ConditionalRender condition={content && read_more}>
              <ViewMore
                  openText="View Full Description"
                  closeText="View Less"
                  additionalButtonClassNames={['u-margin-top-small']}
              >
                  <div className="rte">
                      <RawMarkup>{read_more}</RawMarkup>
                  </div>
              </ViewMore>
          </ConditionalRender>
        </React.Fragment>
    );
}

Content.propTypes = {
    content: PropTypes.string,
    read_more: PropTypes.string,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
};

Content.defaultProps = {
    content: '',
    read_more: '',
    extraClasses: [],
};

export default Content;
