import _ from 'lodash';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import htmlReactParser from 'html-react-parser';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import Asset from './Asset/index.jsx';
import { VIDEO, AUDIO } from './Asset/constants';

function getParsableMarkup(rawMarkup) {
    if (typeof rawMarkup === 'string') {
        return rawMarkup;
    }
    if (typeof rawMarkup === 'number') {
        return `${rawMarkup}`;
    }
    return '';
}

// Remove <p> tag around media since Plyr generates <div> tags, which
// are invalid inside a <p> and will produce warnings. Only checks the
// simple case of media as the lone child node of the <p>, which is
// reasonable given how assets are inserted into RTEs and other arbitary
// cases aren't worth the effort.
function unwrappedMediaPlaceholderFromDomNode(domNode, options) {
    const children = _.get(domNode, 'children', []);
    if (children.length === 1) {
        const typeCategory = _.get(children[0], ['attribs', 'data-type-category']);
        if (typeCategory === VIDEO || typeCategory === AUDIO) {
            return (
                <React.Fragment>
                    {domToReact(domNode.children, options)}
                </React.Fragment>
            );
        }
    }

    // Leave the tag alone otherwise
    return null;
}

function assetFromDomNode(domNode) {
    const {
        'data-asset': assetJSON,
        'data-width': width,
        'data-height': height,
        class: className,
        style,
    } = domNode.attribs;

    try {
        const asset = JSON.parse(assetJSON);

        const manualProps = {};
        if (width) {
            manualProps.width = width;
        }
        if (height) {
            manualProps.height = height;
        }

        const rawManualExtraProps = {};
        if (className) {
            rawManualExtraProps.className = className;
        }
        if (style) {
            rawManualExtraProps.style = style;
        }

        const manualExtraProps = attributesToProps(rawManualExtraProps);
        manualProps.extraProps = {
            ..._.get(asset, 'primaryAsset.extraProps', {}),
            ...manualExtraProps,
        };

        const customizedAsset = {
            primaryAsset: {
                ..._.get(asset, 'primaryAsset', {}),
                ...manualProps,
            },
            secondaryAssets: _.get(asset, 'secondaryAssets', []),
        };

        return <Asset asset={customizedAsset} />;
    } catch (err) {
        console.warn(err);
    }

    return null;
}

function responsiveIframeFromDomNode(domNode) {
    return (
        <span className="rte--responsive-iframe-container">
            {domToReact([domNode])}
        </span>
    );
}

function RawMarkup({ children }) {
    /* Set up regular expression to find class 'ceros-experience' so we don't wrap
    iframe in restrictive 16:9 aspect container. All other iframes we should assume
    16:9 aspect is desired */
    const cerosRegex = /(?:^|\s)(ceros-experience)(?=\s|$)/;

    const options = {
        replace(domNode) {
            const domNodeClass = _.get(domNode, 'attribs.class');

            if (domNode.name === 'p') {
                return unwrappedMediaPlaceholderFromDomNode(domNode, options);
            }
            if (domNode.name === 'span' && domNode.attribs['data-asset']) {
                return assetFromDomNode(domNode);
            }
            if (domNode.name === 'iframe' && domNodeClass) {
                if (!domNodeClass.match(cerosRegex)) {
                    return responsiveIframeFromDomNode(domNode);
                }
            }
            return null;
        },
    };

    const rawStringToRender = getParsableMarkup(children);
    try {
        return htmlReactParser(rawStringToRender, options);
    } catch (rawHtmlToReactParseErr) {
        console.warn('rawHtmlToReactParseErr: ', rawHtmlToReactParseErr);

        const sanitizedStringToRender = sanitizeHtml(rawStringToRender, {
            allowedTags: false,
            allowedAttributes: false,
        });
        try {
            return htmlReactParser(sanitizedStringToRender, options);
        } catch (sanitizedHtmlToReactParseErr) {
            console.error('sanitizedHtmlToReactParseErr: ', sanitizedHtmlToReactParseErr);
            return <div dangerouslySetInnerHTML={{ __html: sanitizedStringToRender }} />;
        }
    }
}

export default RawMarkup;
